import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

// Components
import {
  FiCheckCircle,
  FiClock,
  FiEye,
  FiEyeOff,
  FiBookmark,
} from 'react-icons/fi';
import QuestDetails from './content-details/QuestDetails';
import CheckboxCompare from '../misc-components/CheckboxCompare';

// Redux
import {
  selectAllQuests,
  setQuests,
  setOneQuest,
} from '../../../redux/state-slices/questSlice';
import { useDeleteQuestMutation } from '../../../redux/api-slices/questAPI';

// styles
import './selected.css';
import ViewButton, {
  CreateAndRefetch,
  FlowModal,
  GMButtons,
  NoAssets,
} from '../misc-components/MiscListComps';
import AssetEditorModal from '../../asset-editor-modal/AssetEditorModal';
import SubmitLoader from '../../loading-ani/SubmitLoader';
import {
  displayAlert,
  setAlertInfo,
} from '../../../redux/state-slices/alertSlice';
import { DeleteFail } from '../../alerts/ResponseAlerts';

export default function QuestContent({ role, refetcher }) {
  const quests = useSelector(selectAllQuests);

  const [questDetails, setQuestDetails] = useState(null);

  return questDetails ? (
    <QuestDetails setQuestDetails={setQuestDetails} role={role} />
  ) : (
    <div className='list-content'>
      <CreateAndRefetch role={role} refetcher={refetcher} asset='Quest' />

      {quests?.length === 0 ? (
        <NoAssets asset='quests' />
      ) : (
        <div className='content-body mt-3 flex columns-3 flex-col overflow-y-auto overflow-x-hidden p-4'>
          <QuestList
            quests={quests}
            role={role}
            setQuestDetails={setQuestDetails}
          />
        </div>
      )}
    </div>
  );
}

const QuestList = ({ quests, role, setQuestDetails }) => {
  const dispatch = useDispatch();
  const [openModal, setOpenModal] = useState(undefined);
  const [item, setItem] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [questDelete] = useDeleteQuestMutation();

  const selectOne = (quest) => {
    dispatch(setOneQuest(quest));
    setQuestDetails(true);
  };

  const failedDeleteAlert = () => {
    dispatch(setAlertInfo(DeleteFail));
    dispatch(displayAlert());
  };

  const deleteQuest = async () => {
    setOpenModal(undefined);
    setIsSubmitting(true);
    const isLastDeleted = quests.length === 1 ? true : null;

    try {
      const questDelRes = await questDelete(item.id);
      if ('data' in questDelRes && questDelRes.data) {
        dispatch(
          setAlertInfo({
            alertType: 'success',
            message: `${item.title} deleted`,
          })
        );
        dispatch(displayAlert());
      } else if (
        ('data' in questDelRes && questDelRes.data === false) ||
        'detail' in questDelRes ||
        'error' in questDelRes
      ) {
        failedDeleteAlert();
      }
    } catch (e) {
      failedDeleteAlert();
    }
    if (isLastDeleted) {
      dispatch(setQuests([]));
    }

    setItem(null);
    setIsSubmitting(false);
  };

  return (
    <>
      {isSubmitting && <SubmitLoader message='Deleting' />}
      {quests?.map((quest) => (
        <div
          className='content-item flex flex-row justify-between'
          key={quest.id}
        >
          <span className='m-2 py-2'>
            <CheckboxCompare
              obj={quest}
              category='quest'
              elId={`checkbox-quest-${quest.id}`}
            />
            {quest.title}
          </span>
          <div className='list-button-container flex'>
            <span className='m-2 flex items-center px-4 py-2'>
              {quest.is_priority && (
                <FiBookmark className='mr-2 fill-[#ff7a0e] text-[#ff7a0e]' />
              )}

              {role && role === 'GM' ? (
                quest.is_hidden ? (
                  <FiEyeOff className='mr-2 inline text-violet-600 opacity-100' />
                ) : (
                  <FiEye className='mr-2 inline text-green-600 opacity-100' />
                )
              ) : null}

              {quest.is_complete ? (
                <span className='flex items-center text-green-600'>
                  <FiCheckCircle className='mx-1 inline text-green-600' />
                  Completed
                </span>
              ) : (
                <span className='flex items-center text-violet-600'>
                  <FiClock className='mx-1 inline text-violet-600' />
                  Incomplete
                </span>
              )}
            </span>
            <ViewButton obj={quest} selectOne={selectOne} />

            {role && role === 'GM' ? (
              <>
                <GMButtons
                  obj={quest}
                  setSelected={setItem}
                  setOpenModal={setOpenModal}
                  setOne={setOneQuest}
                />

                <FlowModal
                  openModal={openModal}
                  setOpenModal={setOpenModal}
                  obj={item}
                  deleteFunc={deleteQuest}
                />
              </>
            ) : null}
          </div>
        </div>
      ))}
      {item && (
        <AssetEditorModal
          openModal={openModal}
          setOpenModal={setOpenModal}
          oldFormData={item}
          assetType='Quest'
        />
      )}
    </>
  );
};
