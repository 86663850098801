import React, { useState } from 'react';
import { useSessionContext } from 'supertokens-auth-react/recipe/session';
import { useLocation } from 'react-router-dom';
import CharacterEditForm from './CharacterEditForm';
import CharacterAbilities from './CharacterAbilities';
import Journal from './Journal';

const CharacterDetail = () => {
  const location = useLocation();
  const { pageTypeFromNavButton } = location.state;
  const sesh = useSessionContext();
  const charID = sesh.accessTokenPayload?.In_Game.Character;
  const gameID = sesh.accessTokenPayload?.In_Game.Game;
  const [activeOption, setActiveOption] = useState(
    pageTypeFromNavButton || 'Character Sheet'
  );

  const handleOptionClick = (e) => {
    setActiveOption(e.target.id);
  };

  const display = {
    'Character Sheet': <CharacterEditForm />,
    Journal: <Journal charID={charID} gameID={gameID} />,
    Abilities: <CharacterAbilities />,
  };

  return (
    <div className='min-h-screen w-full'>
      <div
        id='type-selector'
        className='mx-auto mb-8 flex max-w-[95rem] justify-around'
      >
        <div className='border-b border-gray-200 text-center text-sm font-medium text-gray-500 '>
          <ul className='-mb-px flex flex-wrap'>
            {['Character Sheet', 'Abilities', 'Journal'].map((asset) => (
              <li className='mr-2' key={asset}>
                <button
                  id={asset}
                  type='button'
                  className={`inline-block rounded-t-lg border-b-2 border-transparent p-4 text-lg hover:border-gray-300 hover:text-gray-600 ${
                    activeOption === asset && 'active'
                  }`}
                  onClick={handleOptionClick}
                >
                  {asset}
                </button>
              </li>
            ))}
          </ul>
        </div>
      </div>
      {display[activeOption]}
    </div>
  );
};

export default CharacterDetail;
