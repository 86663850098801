import dgnApi from './dgnAPI';

const itemApi = dgnApi.injectEndpoints({
  endpoints: (build) => ({
    getItems: build.query({
      query: (gid) => ({
        url: `/api/items/${gid}`,
      }),
      providesTags: ['Items'],
    }),

    createItem: build.mutation({
      query: (data) => ({
        method: 'POST',
        url: '/api/item',
        body: data,
      }),
      invalidatesTags: ['Items'],
    }),

    getOneItem: build.query({
      query: (id) => ({
        url: `/api/item/${id}`,
      }),
    }),

    updateItem: build.mutation({
      query: (data) => {
        const { id, ...body } = data;
        return {
          method: 'PUT',
          url: `/api/item/${id}`,
          body,
        };
      },
      invalidatesTags: ['Items'],
    }),

    deleteItem: build.mutation({
      query: (id) => ({
        method: 'DELETE',
        url: `/api/item/${id}`,
      }),
      invalidatesTags: ['Items'],
    }),
  }),
});

export const {
  useCreateItemMutation,
  useDeleteItemMutation,
  useGetItemsQuery,
  useGetOneItemQuery,
  useUpdateItemMutation,
} = itemApi;
