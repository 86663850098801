import { createSlice } from '@reduxjs/toolkit';
import { resetAll } from './compareSlice';

const initialState = {
  selectedCharacter: null,
};

const characterSlice = createSlice({
  name: 'character',
  initialState,
  extraReducers: (obj) => obj.addCase(resetAll, () => initialState),
  reducers: {
    setSelectedCharacter: (state, action) => {
      state.selectedCharacter = action.payload;
    },
  },
});

export const { setSelectedCharacter } = characterSlice.actions;

export default characterSlice.reducer;

export const selectCharacter = (state) => state.character.selectedCharacter;
