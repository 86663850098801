/* eslint-disable no-alert */
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { Tooltip } from 'flowbite-react';

import { useSessionContext } from 'supertokens-auth-react/recipe/session';
import { selectCurrentUser } from '../../redux/auth/authSlice';
import { spacesDelete, spacesUpload } from '../../digitalocean/spaces';
import '../game/createGame.css';
import FloatingLabelInput from '../archive/misc-components/FloatingLabelInput';
import DgnFileInput from '../archive/misc-components/DgnFileInput';
import SizeChecker from '../archive/misc-components/SizeChecker';
import LoadingSpinner from '../loading-ani/LoadingSpinner';

import { setSelectedCharacter } from '../../redux/state-slices/characterSlice';
import {
  useGetCharacterQuery,
  useUpdateCharacterMutation,
  useUseInspoMutation,
} from '../../redux/api-slices/characterAPI';
import {
  displayAlert,
  setAlertInfo,
} from '../../redux/state-slices/alertSlice';
import SubmitLoader from '../loading-ani/SubmitLoader';
import { EditorMD } from '../archive/misc-components/MiscDetailComps';
import { UpdateFail } from '../alerts/ResponseAlerts';
import { ReactComponent as DgnLogo } from '../landing-page/assets/dgnLogoInspo.svg';
import { ReactComponent as EmptyDgnLogo } from '../landing-page/assets/dgnLogoEmpty.svg';

export default function CharacterEditForm() {
  const sesh = useSessionContext();
  const partyID = sesh.accessTokenPayload.In_Game.Party;
  const characterID = sesh.accessTokenPayload.In_Game.Character;
  const gameID = sesh.accessTokenPayload.In_Game.Game;
  const [useInspo] = useUseInspoMutation();
  const [updateCharacter] = useUpdateCharacterMutation();
  const [skip, setSkip] = useState(true);
  const { data: characterDataFromDB, isLoading } = useGetCharacterQuery(
    characterID,
    {
      skip,
    }
  );

  const dispatch = useDispatch();
  const user = useSelector(selectCurrentUser);

  const [classValue, setClassValue] = useState('');
  // const [factionValue, setFactionValue] = useState(null);
  const [charNameValue, setCharNameValue] = useState('');
  const [hpValue, setHpValue] = useState(0);
  const [levelValue, setLevelValue] = useState(0);
  const [wealthValue, setWealthValue] = useState(0);
  const [backstory, setBackstory] = useState('');

  const [tmpImgURL, setTmpImgURL] = useState(''); // used to preview img
  const [imgObject, setImgObject] = useState(null); // actual img sent to back end
  const [localImgSize, setLocalImgSize] = useState(null);
  const [willEditImg, setWillEditImg] = useState(false);
  const [imgFileInputKey, setImgFileInputKey] = useState(new Date());
  const [isUpdating, setIsUpdating] = useState(false);

  useEffect(() => {
    dispatch(setSelectedCharacter(characterDataFromDB));
    setSkip(false);
  }, []);

  useEffect(() => {
    if (characterDataFromDB) {
      setClassValue(characterDataFromDB.classes);
      setCharNameValue(characterDataFromDB.character_name);
      setHpValue(characterDataFromDB.hp);
      setLevelValue(characterDataFromDB.level);
      setWealthValue(characterDataFromDB.wealth);
      setTmpImgURL(characterDataFromDB.img_url);
      setBackstory(characterDataFromDB?.backstory);
    }
  }, [characterDataFromDB]);

  useEffect(() => {
    if (imgObject === null) {
      setLocalImgSize(null);
    } else {
      const mb = imgObject.size / 1024 / 1024;
      setLocalImgSize(mb.toFixed(2));
    }
  }, [imgObject]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsUpdating(true);
    const data = {
      id: characterID,
      party_id: partyID,
      classes: classValue,
      // faction_id: factionValue,
      faction_id: null,
      username: user.username,
      character_name: charNameValue,
      hp: hpValue,
      level: levelValue,
      inspiration: false,
      wealth: wealthValue,
      img_url: null,
      backstory,
    };

    if (tmpImgURL !== null) {
      if (tmpImgURL === characterDataFromDB.img_url) {
        data.img_url = characterDataFromDB.img_url;
      } else if (tmpImgURL !== characterDataFromDB.img_url)
        try {
          const imgResponse = await spacesUpload(
            imgObject,
            'CharacterImages',
            user.username
          );
          data.img_url = imgResponse;

          if (imgResponse && characterDataFromDB.img_url) {
            await spacesDelete(characterDataFromDB.img_url, user.username);
          }
        } catch (err) {
          alert(
            `There was an issue uploading this image, however the rest of your character has been saved. You can try uploading the image in the character settings page. \n${err}`
          );
        }
    }

    const res = await updateCharacter(data);
    if (res.data) {
      dispatch(
        setAlertInfo({
          alertType: 'success',
          message: 'Character successfully updated.',
        })
      );
      setIsUpdating(false);
      dispatch(displayAlert());
    } else {
      alert(`Something went wrong...\nStatus Code: ${res.status}`);
      dispatch(setAlertInfo(UpdateFail));
      setIsUpdating(false);
      dispatch(displayAlert());
    }
  };

  const onCharImageChange = (e) => {
    const { files } = e.target;
    if (files && files.length) {
      const imgFile = files[0];
      setTmpImgURL(URL.createObjectURL(imgFile));
      setImgObject(imgFile);
    }
  };

  const failedInspoAlert = () => {
    dispatch(
      setAlertInfo({
        alertType: 'failure',
        message: 'An error occured while attempting to use inspiration',
      })
    );
    dispatch(displayAlert());
  };

  const handleUseInspo = async () => {
    const data = {
      player_id: characterID,
      inspo_action: false,
      plID: characterID,
      gID: gameID,
    };

    try {
      const inspoRes = await useInspo(data);
      if ('data' in inspoRes && inspoRes.data) {
        dispatch(
          setAlertInfo({
            alertType: 'success',
            message: 'Inspiration used',
          })
        );
        dispatch(displayAlert());
      } else if (
        ('data' in inspoRes && inspoRes.data === false) ||
        'detail' in inspoRes ||
        'error' in inspoRes
      ) {
        failedInspoAlert();
      }
    } catch (err) {
      failedInspoAlert();
    }
  };

  return (
    <div>
      {isLoading ? (
        <div className='m-auto flex justify-center'>
          <LoadingSpinner classStyle='loading-ani-red' />
        </div>
      ) : (
        <div className='game-wrapper'>
          {isUpdating && <SubmitLoader />}
          <div className='game-content min-h-screen w-full'>
            <form className='create-form selected-content-details mx-auto h-full max-w-[95rem] p-3'>
              <div className='border-image-container relative flex flex-col items-center px-2 pb-4 pt-8'>
                <div className='absolute left-0 top-0 flex flex-col items-center gap-y-3 p-4'>
                  {characterDataFromDB && characterDataFromDB?.inspiration ? (
                    <>
                      <DgnLogo className='inspo-icons animate-pulse' />
                      <button
                        type='button'
                        className='rounded-md bg-[#074f57] px-2 py-1 text-white'
                        onClick={handleUseInspo}
                      >
                        Use inspiration
                      </button>
                    </>
                  ) : (
                    <EmptyDgnLogo className='inspo-icons' />
                  )}
                </div>
                <FloatingLabelInput
                  onChange={(e) => setCharNameValue(e.target.value)}
                  value={charNameValue}
                  labelText='Name'
                  nameAndID='name'
                  isRequired
                  maxLength={70}
                  additionalInputClassNames='asset-title'
                />
                <div className='img-select border-image-container mt-8 w-96 p-[.9rem]'>
                  <div>
                    <img
                      height={640}
                      width={540}
                      src={
                        tmpImgURL ||
                        'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png'
                      }
                      alt='character upload portrait'
                    />
                  </div>
                </div>
                <div className='mt-4 flex flex-col items-center'>
                  <div className='flex'>
                    {willEditImg ? (
                      <DgnFileInput
                        key={imgFileInputKey}
                        imageChangeHandler={onCharImageChange}
                      />
                    ) : (
                      <button
                        onClick={() => setWillEditImg(true)}
                        type='button'
                        className='row-button edit-content rounded-lg bg-yellow-100 text-xs'
                      >
                        Update Image
                      </button>
                    )}
                    {characterDataFromDB?.img_url && (
                      <button
                        type='button'
                        className='row-button delete-content rounded-lg bg-red-100 text-xs'
                        onClick={() => {
                          setImgFileInputKey(new Date());
                          setImgObject(null);
                          setLocalImgSize(null);
                          setTmpImgURL(null);
                        }}
                      >
                        Clear Image
                      </button>
                    )}
                  </div>
                  {characterDataFromDB?.img_url && (
                    <div>
                      {willEditImg && localImgSize && (
                        <SizeChecker imgSize={localImgSize} />
                      )}
                    </div>
                  )}
                </div>
                <div className='grid grid-cols-2 gap-4' id='info'>
                  <FloatingLabelInput
                    onChange={(e) => setClassValue(e.target.value)}
                    value={classValue}
                    labelText='Class'
                    nameAndID='class'
                    maxLength={100}
                    additionalInputClassNames='asset-title'
                  />
                  <FloatingLabelInput
                    labelText='HP'
                    onChange={(e) => setHpValue(e.target.value)}
                    value={hpValue}
                    nameAndID='hp'
                    type='number'
                    additionalInputClassNames='asset-title'
                  />
                  <FloatingLabelInput
                    labelText='Level'
                    onChange={(e) => setLevelValue(e.target.value)}
                    value={levelValue}
                    nameAndID='level'
                    type='number'
                    additionalInputClassNames='asset-title'
                  />
                  <FloatingLabelInput
                    labelText='Wealth'
                    onChange={(e) => setWealthValue(e.target.value)}
                    value={wealthValue}
                    nameAndID='wealth'
                    type='number'
                    additionalInputClassNames='asset-title'
                  />
                </div>
                <div className='w-full py-4'>
                  <EditorMD
                    mdText={backstory}
                    setMdText={setBackstory}
                    height='40vh'
                  />
                </div>

                <div className=' mt-4 flex justify-center'>
                  {localImgSize === null || localImgSize <= 2 ? (
                    <button
                      type='button'
                      className='submit-button mr-2 rounded-lg bg-green-700 px-4 py-2 text-white'
                      onClick={handleSubmit}
                    >
                      Update!
                    </button>
                  ) : (
                    <Tooltip
                      // eslint-disable-next-line react/style-prop-object
                      style='light'
                      content='Your image is too large, consider compressing it'
                    >
                      <button
                        type='submit'
                        disabled
                        className='submit-button mb-2 rounded-lg bg-green-700 px-4 py-2 text-white'
                      >
                        Update
                      </button>
                    </Tooltip>
                  )}
                </div>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
}
