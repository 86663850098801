import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { signOut } from 'supertokens-auth-react/recipe/thirdpartyemailpassword';
import { redirectToAuth } from 'supertokens-auth-react';

import { GiSpellBook } from 'react-icons/gi';

import { useDispatch, useSelector } from 'react-redux';
import { useGetAllPartiesByGameQuery } from '../../../../redux/api-slices/partyAPI';
import {
  selectToken,
  selectCurrentUser,
  setJWT,
  setLogout,
} from '../../../../redux/auth/authSlice';

import EmptyGMCard from './EmptyGMCard';

import '../carousel.css';
import { DetailMD } from '../../../archive/misc-components/MiscDetailComps';
import LoadingSpinner from '../../../loading-ani/LoadingSpinner';

function GmView({ gmData }) {
  const [openSlots, setOpenSlots] = useState(2);

  const url =
    'https://i.pinimg.com/564x/80/cb/1e/80cb1edfcb51c0eb557f4176d8c10514.jpg';

  useEffect(() => {
    if (gmData && gmData.length) {
      setOpenSlots(2 - gmData.length);
    }
  }, [gmData]);

  return (
    <>
      {gmData?.map((game) => (
        <div
          className='embla__slide max-w-md overflow-hidden rounded shadow-lg'
          key={game.id}
          elevation='5'
        >
          {/* Card image */}

          {game.img_url === 'No image provided' ? (
            <NoGameImg />
          ) : (
            <GameImg game={game} url={url} />
          )}

          {/* Game details */}
          <div className='game-info-cont px-6'>
            <Title game={game} />

            <hr className='mb-1 h-0.5 bg-[#420000]' />

            <Description game={game} />

            <EnterButton game={game} />

            {/* <Passcode game={game} /> */}
          </div>
        </div>
      ))}
      <EmptyCards openSlots={openSlots} />
    </>
  );
}

export const Title = ({ game }) => {
  return (
    <div className='flex justify-center'>
      <div className='text-lg text-[#420000]'>{game.title}</div>
    </div>
  );
};

export const Description = ({ game }) => {
  return (
    <div className='game-description h-auto'>
      <DetailMD details={game.description} />
    </div>
  );
};

export const GameImg = ({ game }) => {
  return (
    <div className='gm-img-cont overflow-hidden'>
      <img
        src={game.img_url}
        className='gm-img h-full w-full object-cover object-center'
        alt=''
      />
    </div>
  );
};

export const NoGameImg = () => {
  return (
    <div
      className='flex h-[20rem] w-full items-center justify-center overflow-hidden  bg-[#672a2aa1]'
      style={{ borderBottom: '0.15rem solid #420000' }}
    >
      <svg
        className='h-12 w-12 text-[#420000]'
        xmlns='http://www.w3.org/2000/svg'
        aria-hidden='true'
        fill='currentColor'
        viewBox='0 0 640 512'
      >
        <path d='M480 80C480 35.82 515.8 0 560 0C604.2 0 640 35.82 640 80C640 124.2 604.2 160 560 160C515.8 160 480 124.2 480 80zM0 456.1C0 445.6 2.964 435.3 8.551 426.4L225.3 81.01C231.9 70.42 243.5 64 256 64C268.5 64 280.1 70.42 286.8 81.01L412.7 281.7L460.9 202.7C464.1 196.1 472.2 192 480 192C487.8 192 495 196.1 499.1 202.7L631.1 419.1C636.9 428.6 640 439.7 640 450.9C640 484.6 612.6 512 578.9 512H55.91C25.03 512 .0006 486.1 .0006 456.1L0 456.1z' />
      </svg>
    </div>
  );
};

const EnterButton = ({ game }) => {
  const ref = useRef();
  const { data: parties, isLoading } = useGetAllPartiesByGameQuery(game?.id);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const t = useSelector(selectToken);
  const u = useSelector(selectCurrentUser);

  const joinGame = async (gID, pID) => {
    if (ref.current) {
      ref.current.setAttribute('disabled', 'disabled');
    }

    const reqConfig = {
      method: 'POST',
      credentials: 'include',
      headers: {
        Authorization: `Bearer ${t}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ game: gID, user: u.username, party_id: pID }),
    };

    try {
      const res = await fetch(
        `${process.env.REACT_APP_USER_SERVICE}/enter/${gID}`,
        reqConfig
      );
      if (res.ok && res.status === 200) {
        const newToken = await res.json();
        dispatch(setJWT(newToken));
        navigate(`/archive/${gID}`);
      }
    } catch (err) {
      if (err instanceof TypeError) {
        await signOut();
        dispatch(setLogout());
        redirectToAuth({ show: 'signin' });
      }
    }
  };

  return (
    <div className='flex gap-4'>
      {isLoading ? (
        <LoadingSpinner classStyle='loading-ani-red mx-auto' />
      ) : (
        parties &&
        parties.map((p) => (
          <button
            ref={ref}
            key={p.id}
            type='button'
            className='mx-auto mt-8 flex pb-2 text-center'
            onClick={() => joinGame(game.id, p.id)}
          >
            <span className='enter-button px-4 py-1'>Enter the DGN</span>
          </button>
        ))
      )}
    </div>
  );
};

const Passcode = () => {
  return (
    <div className='flex justify-end'>
      {/* <span className='passcode text-base'>{game.passcode}</span> */}
      <span className='passcode text-base'>passcode not returned from DB</span>
    </div>
  );
};

const EmptyCards = ({ openSlots }) => {
  const keys = [1, 2, 3, 4];

  return [...Array(openSlots)].map((e, i) => (
    <div
      className='embla__slide empty-slide relative w-full max-w-md overflow-hidden rounded shadow-lg'
      key={keys[i]}
      elevation='5'
    >
      <span className='blur-[4px]'>
        <EmptyGMCard />
      </span>

      <GiSpellBook
        className='slot-icon absolute right-0 top-0'
        stroke='#420000'
      />
    </div>
  ));
};

export default GmView;
