import React, { useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';

// supertokens
import SuperTokens, { SuperTokensWrapper } from 'supertokens-auth-react';
import ThirdPartyEmailPassword from 'supertokens-auth-react/recipe/thirdpartyemailpassword';
import Session from 'supertokens-auth-react/recipe/session';

// redux
import { useSelector } from 'react-redux';
import { selectCurrentUser } from '../redux/auth/authSlice';

// components
import Navbar from '../features/navbar/Navbar';
import Footer from '../features/footer/Footer';

// styles
import './App.css';
import '../features/footer/footer.css';
import useGetUserData from '../dgn-hooks/Hooks';
import { ShowAlert } from '../features/alerts/ResponseAlerts';
import { selectAlertState } from '../redux/state-slices/alertSlice';

const supertokensStyler = () =>
  `
    [data-supertokens~=superTokensBranding] {
        display: none;
    }

    [data-supertokens~=container] {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
    }

    [data-supertokens~=button] {
        background-color: #420000;
        border: 1px solid #420000;
    }

    [data-supertokens~=button]:hover {
      transform: scale(1.02);
    }

    [data-supertokens~=button]:active {
      transform: scale(.98);
    }

    [data-supertokens~=inputWrapper]:focus-within {
      border: 1.5px solid #420000;
      box-shadow: 0 0 0 0.2rem rgba(66, 0, 0, .25)
    }

    `;

SuperTokens.init({
  appInfo: {
    appName: 'dgnkeeper',
    apiDomain: `${process.env.REACT_APP_USER_SERVICE}`,
    websiteDomain: `${process.env.REACT_APP_PUBLIC_URL}`,
    apiBasePath: '/auth',
    websiteBasePath: '/auth',
  },
  recipeList: [
    ThirdPartyEmailPassword.init({
      signInAndUpFeature: {
        style: `${supertokensStyler()}
        [data-supertokens~=link] {
          display: none;
        }
        [data-supertokens~=forgotPasswordLink] {
          display: revert;
          color: #3b82f6;
        }`,

        signUpForm: {
          formFields: [
            {
              id: 'username',
              label: 'Username',
              placeholder: 'Username',

              validate: async (value) => {
                const response = await fetch(
                  `${process.env.REACT_APP_USER_SERVICE}/api/userauth/check/${value}`
                );

                if (response.status === 409) {
                  return 'Username already taken';
                }

                return undefined;
              },
            },
          ],
        },
      },

      resetPasswordUsingTokenFeature: {
        enterEmailForm: {
          style: supertokensStyler(),
        },
        submitNewPasswordForm: {
          style: supertokensStyler(),
        },
      },
    }),
    Session.init({
      tokenTransferMethod: 'cookie',
    }),
  ],
});

function App() {
  useGetUserData();
  const currentLocation = useLocation();
  const user = useSelector(selectCurrentUser);
  const { message, showAlert, alertType } = useSelector(selectAlertState);

  useEffect(() => {
    window.addEventListener('error', (e) => {
      if (
        e.message === 'ResizeObserver loop limit exceeded' ||
        e.message ===
          'ResizeObserver loop completed with undelivered notifications.'
      ) {
        const resizeObserverErrDiv = document.getElementById(
          'webpack-dev-server-client-overlay-div'
        );
        const resizeObserverErr = document.getElementById(
          'webpack-dev-server-client-overlay'
        );
        if (resizeObserverErr) {
          resizeObserverErr.setAttribute('style', 'display: none');
        }
        if (resizeObserverErrDiv) {
          resizeObserverErrDiv.setAttribute('style', 'display: none');
        }
      }
    });
  }, []);

  // Flowbite Sidebar could be our initiative order tracker

  return (
    <SuperTokensWrapper>
      <Navbar currentLocation={currentLocation} />
      <Outlet />
      {showAlert && <ShowAlert message={message} alertType={alertType} />}
      <Footer user={user} />
    </SuperTokensWrapper>
  );
}

export default App;
