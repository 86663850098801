import React, { useState, useEffect } from 'react';
import { useSessionContext } from 'supertokens-auth-react/recipe/session';
import { useDispatch, useSelector } from 'react-redux';

// components
import {
  GiPlanetConquest,
  GiTeamIdea,
  GiChecklist,
  GiSpikedHalo,
  GiAncientSword,
  GiSmokingVolcano,
} from 'react-icons/gi';
import { Tabs } from 'flowbite-react';
import LoadingSpinner from '../loading-ani/LoadingSpinner';
import LocationContent from './selected-content/LocationContent';
import FactionContent from './selected-content/FactionContent';
import NpcContent from './selected-content/NpcContent';
import ItemContent from './selected-content/ItemContent';
import QuestContent from './selected-content/QuestContent';
import SceneContent from './selected-content/SceneContent';
import ComparisonHandler from './misc-components/ComparisonHandler';
import CompareDisplay from './misc-components/CompareDisplay';
import SessionTimeline from './SessionTimeline';

// Redux
import { useGetLocationsQuery } from '../../redux/api-slices/locationAPI';
import { useGetFactionsQuery } from '../../redux/api-slices/factionAPI';
import { useGetNpcsQuery } from '../../redux/api-slices/npcAPI';
import { useGetItemsQuery } from '../../redux/api-slices/itemAPI';
import { useGetQuestsQuery } from '../../redux/api-slices/questAPI';
import { useGetScenesQuery } from '../../redux/api-slices/sceneAPI';
import {
  useGetAllPartiesByGameQuery,
  useGetPartyCharsQuery,
} from '../../redux/api-slices/partyAPI';
import { setLocations } from '../../redux/state-slices/locationSlice';
import { setFactions } from '../../redux/state-slices/factionSlice';
import { setNpcs } from '../../redux/state-slices/npcSlice';
import { setItems } from '../../redux/state-slices/itemSlice';
import { setQuests } from '../../redux/state-slices/questSlice';
import { setScenes } from '../../redux/state-slices/sceneSlice';
import {
  setParties,
  selectAllParties,
} from '../../redux/state-slices/partySlice';
import { selectIsComparing } from '../../redux/state-slices/compareSlice';
// styles
import './archive.css';
import StartSession from './StartSession';

export default function Archive() {
  const sesh = useSessionContext();
  const dispatch = useDispatch();
  const { username } = sesh.accessTokenPayload;
  const game = sesh.accessTokenPayload?.In_Game.Game;
  const role = sesh.accessTokenPayload?.In_Game.Role;
  const party = sesh.accessTokenPayload?.In_Game.Party;
  const [activeTab, setActiveTab] = useState();
  const [skip, setSkip] = useState({
    Locations: true,
    Groups: true,
    NPCs: true,
    Items: true,
    Quests: true,
    Scenes: true,
    Sessions: true,
    Party: true,
  });
  const tabs = ['Locations', 'Quests', 'NPCs', 'Items', 'Groups', 'Scenes'];
  const {
    data: locationData,
    isLoading: locationLoading,
    refetch: locRefetcher,
  } = useGetLocationsQuery(game, {
    skip: skip.Locations,
  });

  const {
    data: factionData,
    isLoading: factionLoading,
    refetch: facRefetcher,
  } = useGetFactionsQuery(game, {
    skip: skip.Groups,
  });

  const {
    data: npcData,
    isLoading: npcLoading,
    refetch: npcRefetcher,
  } = useGetNpcsQuery(game, {
    skip: skip.NPCs,
  });

  const {
    data: itemData,
    isLoading: itemLoading,
    refetch: itemRefetcher,
  } = useGetItemsQuery(game, {
    skip: skip.Items,
  });

  const {
    data: questData,
    isLoading: questLoading,
    refetch: questRefetcher,
  } = useGetQuestsQuery(game, {
    skip: skip.Quests,
  });

  const {
    data: sceneData,
    isLoading: sceneLoading,
    refetch: sceneRefetcher,
  } = useGetScenesQuery(game, {
    skip: skip.Scenes,
  });

  const { data: partyData } = useGetAllPartiesByGameQuery(game, {
    skip: skip.Party,
  });

  const { data: partyChars, isLoading: partyChLoading } =
    useGetPartyCharsQuery(party);

  const assetRefetchers = {
    loc: locRefetcher,
    fac: facRefetcher,
    npc: npcRefetcher,
    item: itemRefetcher,
    quest: questRefetcher,
    scene: sceneRefetcher,
  };

  const isLoading = [
    locationLoading,
    factionLoading,
    npcLoading,
    itemLoading,
    questLoading,
    sceneLoading,
  ].includes(true)
    ? true
    : null;

  const dataActions = [
    { data: locationData, actionCreator: setLocations },
    { data: factionData, actionCreator: setFactions },
    { data: npcData, actionCreator: setNpcs },
    { data: itemData, actionCreator: setItems },
    { data: questData, actionCreator: setQuests },
    { data: sceneData, actionCreator: setScenes },
  ];

  useEffect(() => {
    dataActions.forEach(({ data, actionCreator }) => {
      if (data && data.length > 0) {
        dispatch(actionCreator(data));
      }
    });

    const updatedSkip = { ...skip };
    updatedSkip.Party = false;
    setSkip(updatedSkip);
    if (partyData) {
      dispatch(setParties(partyData));
    }
  }, [
    locationData,
    factionData,
    npcData,
    itemData,
    questData,
    sceneData,
    partyData,
  ]);

  return (
    <div className='archive-wrap relative min-h-screen w-full'>
      <div className='archive-content relative mx-auto my-2 flex h-full max-w-[95rem] flex-col rounded-lg'>
        {role === 'GM' && (
          <PartySelect
            parties={partyData}
            isLoading={partyChLoading}
            chars={partyChars}
          />
        )}

        {role === 'Player' && (
          <DisplayPartyForPlayers
            parties={partyData}
            isLoading={partyChLoading}
            chars={partyChars}
          />
        )}

        {role === 'GM' && <StartSession gID={game} />}

        <SessionTimeline gID={game} />

        <SelectedTabs
          setActiveTab={setActiveTab}
          tabs={tabs}
          skip={skip}
          setSkip={setSkip}
        />
        <ComparisonHandler />
        {/* h-[70rem]  */}
        <div className='asset-display relative mx-4 my-3 h-[80rem] w-auto rounded-lg'>
          <ArchiveDisplay
            activeTab={activeTab}
            tabs={tabs}
            isLoading={isLoading}
            role={role}
            username={username}
            assetRefetchers={assetRefetchers}
          />
        </div>
      </div>
    </div>
  );
}

const ArchiveDisplay = ({
  activeTab,
  tabs,
  isLoading,
  role,
  username,
  assetRefetchers,
}) => {
  const isComparingAll = useSelector(selectIsComparing);
  const contentLegend = {
    Locations: (
      <LocationContent
        role={role}
        username={username}
        refetcher={assetRefetchers.loc}
      />
    ),
    Groups: (
      <FactionContent
        role={role}
        username={username}
        refetcher={assetRefetchers.fac}
      />
    ),
    NPCs: (
      <NpcContent
        role={role}
        username={username}
        refetcher={assetRefetchers.npc}
      />
    ),
    Items: (
      <ItemContent
        role={role}
        username={username}
        refetcher={assetRefetchers.item}
      />
    ),
    Quests: (
      <QuestContent
        role={role}
        username={username}
        refetcher={assetRefetchers.quest}
      />
    ),
    Scenes: (
      <SceneContent
        role={role}
        username={username}
        refetcher={assetRefetchers.scene}
      />
    ),
    CompareDisplay: <CompareDisplay role={role} />,
  };

  const compareOrAsset = isComparingAll ? 'CompareDisplay' : tabs[activeTab];

  const contentDisplayed = isLoading ? (
    <div className='flex h-full flex-col justify-center'>
      <LoadingSpinner classStyle='loading-ani-red' />
    </div>
  ) : (
    contentLegend[compareOrAsset]
  );

  return (
    <section className='flex h-full w-full justify-center rounded-lg bg-white/80'>
      {contentDisplayed}
    </section>
  );
};

const PartySelect = ({ isLoading, chars }) => {
  const parties = useSelector(selectAllParties);
  let partyName;
  if (parties.length) {
    partyName = parties[0].party_name;
  }

  return (
    <div
      className={
        isLoading
          ? `relative mb-2 mt-4 flex justify-center border-y border-slate-600 py-2`
          : `relative mb-2 mt-4 border-y border-slate-600 py-2`
      }
    >
      {isLoading ? (
        <LoadingSpinner classStyle='loading-ani-red self-center' />
      ) : (
        <>
          <span className='party-name absolute left-1/2 top-0 mb-4 -translate-x-1/2 -translate-y-1/2 rounded-lg bg-[#074f57] px-4 py-2 text-white'>
            {partyName}
          </span>
          <div className='mb-2 mt-8 flex flex-wrap justify-evenly gap-y-2'>
            {chars && chars.length === 0 ? (
              <span className='rounded-md border border-[#074f57] bg-gray-300 px-4 py-2 text-[#074f57]'>
                Your players have not joined yet
              </span>
            ) : (
              chars &&
              chars.map((char) => (
                <span
                  key={char.id}
                  className='char-item rounded-md border border-[#074f57] bg-gray-300 px-4 py-2 text-[#074f57]'
                >
                  {char.character_name}
                </span>
              ))
            )}
          </div>
        </>
      )}
    </div>
  );
};

const DisplayPartyForPlayers = ({ isLoading, chars }) => {
  // Will need to change this logic when allowing 2+ parties
  const parties = useSelector(selectAllParties);
  let partyName;
  if (parties.length) {
    partyName = parties[0].party_name;
  }

  return (
    <div
      className={
        isLoading
          ? `relative m-8 flex justify-center border-y border-slate-600 py-2`
          : `relative m-8 border-y border-slate-600 py-2`
      }
    >
      {isLoading ? (
        <LoadingSpinner classStyle='loading-ani-red self-center' />
      ) : (
        <>
          <span className='absolute left-1/2 top-0 mb-4 -translate-x-1/2 -translate-y-1/2 rounded-lg bg-[#074f57] px-4 py-2 text-white'>
            {partyName}
          </span>
          <div className='mb-2 mt-8 flex flex-wrap justify-evenly gap-y-2'>
            {chars &&
              chars.map((char) => (
                <span
                  key={char.id}
                  className='rounded-md border border-[#074f57] bg-gray-300 px-4 py-2 text-[#074f57]'
                >
                  {char.character_name}
                </span>
              ))}
          </div>
        </>
      )}
    </div>
  );
};

const SelectedTabs = ({ setActiveTab, tabs, skip, setSkip }) => {
  const tabTheme = {
    tablist: {
      styles: {
        default: 'flex-wrap justify-center gap-y-2 border-b border-[#420000]',
      },

      tabitem: {
        base: 'flex items-center justify-center p-3 rounded-t-lg text-sm font-medium first:ml-0 disabled:cursor-not-allowed disabled:text-gray-400 focus:ring-2 focus:ring-[#a8524d] focus:outline-none',
        styles: {
          default: {
            base: 'rounded-t-lg',
            active: {
              on: 'bg-[#420000] text-white z-10',
              off: 'text-gray-500 bg-gray-300 hover:transition-all hover:bg-gray-150 hover:scale-[1.02] hover:text-[#420000]',
            },
          },
        },
      },
    },
    tabpanel: 'py-0',
  };

  const icons = {
    Locations: GiPlanetConquest,
    Groups: GiTeamIdea,
    NPCs: GiSpikedHalo,
    Items: GiAncientSword,
    Quests: GiChecklist,
    Scenes: GiSmokingVolcano,
  };

  const changeTab = (tab) => {
    const tabName = tabs[tab];
    const newSkip = { ...skip };
    newSkip[tabName] = false;

    setSkip(newSkip);
    setActiveTab(tab);
  };

  return (
    <Tabs.Group
      theme={tabTheme}
      className='mx-2 mt-1'
      aria-label='Default tabs'
      // eslint-disable-next-line react/style-prop-object
      style='default'
      onActiveTabChange={(tab) => changeTab(tab)}
    >
      {tabs.map((tab) => {
        return <Tabs.Item title={tab} key={tab} icon={icons[tab]}></Tabs.Item>;
      })}
    </Tabs.Group>
  );
};
