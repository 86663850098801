import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSessionContext } from 'supertokens-auth-react/recipe/session';
import './gameSettings.css';
import GameSettings from './GameSettings';
import PartySettings from './PartySettings';

export default function Settings() {
  const sesh = useSessionContext();
  const gameID = sesh.accessTokenPayload?.In_Game.Game;
  const role = sesh.accessTokenPayload?.In_Game.Role;
  const partyID = sesh.accessTokenPayload?.In_Game.Party;
  const username = sesh.accessTokenPayload?.username;
  const navigate = useNavigate();
  const [activeOption, setActiveOption] = useState('Game');

  const handleOptionClick = (e) => {
    setActiveOption(e.target.id);
  };

  const selectedSetting = {
    Game: (
      <GameSettings
        gameID={gameID}
        username={username}
        navigate={navigate}
        partyID={partyID}
      />
    ),
    'Party | Players': (
      <PartySettings
        partyID={partyID}
        gameID={gameID}
        username={username}
        navigate={navigate}
      />
    ),
  };

  return role === 'GM' ? (
    <section className='game-settings-main-wrapper relative min-h-screen'>
      <div
        id='type-selector'
        className='selector-container mx-auto mt-8 flex max-w-[95rem] justify-around'
      >
        <div className='border-b border-gray-200 text-center text-sm font-medium text-gray-500 '>
          <ul className='-mb-px flex flex-wrap'>
            {['Game', 'Party | Players'].map((asset) => (
              <li className='mr-2' key={asset}>
                <button
                  id={asset}
                  type='button'
                  className={`inline-block rounded-t-lg border-b-2 border-transparent p-4 text-lg hover:border-gray-300 hover:text-gray-600 ${
                    activeOption === asset && 'active'
                  }`}
                  onClick={handleOptionClick}
                >
                  {asset}
                </button>
              </li>
            ))}
          </ul>
        </div>
      </div>

      {selectedSetting[activeOption]}
    </section>
  ) : (
    <div className='relative min-h-screen bg-white/95'>
      <div className='absolute left-1/2 top-1/2 -translate-x-1/2 rounded-lg bg-[#420000] px-16 py-8 text-lg text-white'>
        Only the GM may change the game settings
      </div>
    </div>
  );
}
