import React from 'react';
import { FiCheck, FiAlertCircle } from 'react-icons/fi';

export default function SizeChecker({ imgSize }) {
  return imgSize <= 2 ? (
    <div className='mx-2 flex items-center p-3 text-green-600'>
      <FiCheck className='mr-2 inline' />
      <span className='mr-2'>(max: 2mb)</span> | Current: {imgSize}mb
    </div>
  ) : (
    <div className='mx-2 flex items-center p-3 font-bold text-red-600'>
      <FiAlertCircle className='mr-2 inline' />
      <span className='mr-2'>(max: 2mb)</span> | Current: {imgSize}mb
    </div>
  );
}
