import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import Session from 'supertokens-auth-react/recipe/session';
import { signOut } from 'supertokens-auth-react/recipe/thirdpartyemailpassword';
import { Mutex } from 'async-mutex';

import { setJWT, setLogout } from '../auth/authSlice';

const baseQuery = fetchBaseQuery({
  baseUrl: `${process.env.REACT_APP_GAME_SERVICE}`,
  prepareHeaders: async (headers, { getState }) => {
    headers['content-type'] = 'application/json';

    const { token } = getState().auth;

    headers.set('authorization', `Bearer ${token}`);

    return headers;
  },

  credentials: 'include',
});

const mutex = new Mutex();
const baseQueryWithReauth = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions);

  if (result.error && result.error.status === 401) {
    if (!mutex.isLocked()) {
      const release = await mutex.acquire();
      try {
        const jwt = await Session.getAccessToken();
        if (jwt) {
          api.dispatch(setJWT(jwt));
          result = await baseQuery(args, api, extraOptions);
        } else {
          // eslint-disable-next-line no-alert
          alert(
            'There has been an authentication error. You have been logged out.'
          );
          await signOut();
          api.dispatch(setLogout());
        }
      } finally {
        release();
      }
    } else {
      await mutex.waitForUnlock();
      result = await baseQuery(args, api, extraOptions);
    }
  }
  return result;
};

const dgnApi = createApi({
  reducerPath: 'dgnApi',
  baseQuery: baseQueryWithReauth,

  tagTypes: [
    'Game',
    'Characters',
    'CurrentCharacter',
    'Locations',
    'Factions',
    'Items',
    'Journals',
    'NPCs',
    'Quests',
    'Scenes',
    'Sessions',
    'Abilities',
    'Parties',
    'Party',
  ],

  endpoints: () => ({}),
});

export default dgnApi;
