import dgnApi from './dgnAPI';

const journalApi = dgnApi.injectEndpoints({
  endpoints: (build) => ({
    getJournalEntries: build.query({
      query: (plID) => ({
        url: `/api/journals/${plID}`,
      }),
      providesTags: ['Journals'],
    }),

    createJournalEntry: build.mutation({
      query: (data) => {
        const { plID, ...body } = data;
        return {
          method: 'POST',
          url: `/api/journal/${plID}`,
          body,
        };
      },
      invalidatesTags: ['Journals'],
    }),

    getOneJournalEntry: build.query({
      query: (id) => ({
        url: `/api/journal/${id}`,
      }),
    }),

    updateJournalEntry: build.mutation({
      query: (data) => {
        const { id, ...body } = data;
        return {
          method: 'PUT',
          url: `/api/journal/${id}`,
          body,
        };
      },
      invalidatesTags: ['Journals'],
    }),

    deleteJournalEntry: build.mutation({
      query: (id) => ({
        method: 'DELETE',
        url: `/api/journal/${id}`,
      }),
      invalidatesTags: ['Journals'],
    }),
  }),
});

export const {
  useCreateJournalEntryMutation,
  useDeleteJournalEntryMutation,
  useGetJournalEntriesQuery,
  useGetOneJournalEntryQuery,
  useUpdateJournalEntryMutation,
} = journalApi;
