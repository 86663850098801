import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  showAlert: false,
  alertType: null,
  message: '',
};

const alertSlice = createSlice({
  name: 'alert',
  initialState,
  reducers: {
    setAlertInfo: (state, action) => {
      const { alertType, message } = action.payload;
      state.alertType = alertType;
      state.message = message;
    },

    displayAlert: (state) => {
      state.showAlert = true;
    },

    closeAlert: (state) => {
      state.showAlert = false;
    },
  },
});

export const { displayAlert, closeAlert, setAlertInfo } = alertSlice.actions;

export default alertSlice.reducer;

export const selectAlertState = (state) => state.alerts;
