import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  user: {
    email: '',
    username: '',
    signedIn: null,
  },

  token: '',
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setUser: (state, action) => {
      const { email, username, signedIn, jwt } = action.payload;

      state.user.email = email;
      state.user.username = username;
      state.user.signedIn = signedIn;
      state.token = jwt;
    },
    setJWT: (state, action) => {
      const jwt = action.payload;
      state.token = jwt;
    },
    // might need to add action to params below

    setLogout: (state) => {
      state.user = { email: '', username: '', signedIn: false };
      state.token = '';
    },

    setToken: (state, action) => {
      const jwt = action.payload;

      state.token = jwt;
    },
  },
});

export const { setUser, setJWT, setLogout } = authSlice.actions;

export default authSlice.reducer;

export const selectCurrentUser = (state) => state.auth.user;
export const selectToken = (state) => state.auth.token;
