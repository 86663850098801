import React, { useEffect, useState } from 'react';
import { useSessionContext } from 'supertokens-auth-react/recipe/session';

import { FiTrash, FiEdit } from 'react-icons/fi';

import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import {
  useUpdateSessionMutation,
  useDeleteSessionMutation,
  useGetSessionsQuery,
} from '../../redux/api-slices/sessionAPI';
import { FlowModal } from '../archive/misc-components/MiscListComps';
import {
  DetailMD,
  GMDetailHeader,
} from '../archive/misc-components/MiscDetailComps';
import './sessions.css';
import { setSessions } from '../../redux/state-slices/sessionSlice';
import SessionEditForm from './SessionEditForm';
import {
  displayAlert,
  setAlertInfo,
} from '../../redux/state-slices/alertSlice';
import { sessionObjTemplate } from '../MDtemplates/Templates';
import { DeleteFail } from '../alerts/ResponseAlerts';

export default function Sessions() {
  const sesh = useSessionContext();
  const game = sesh.accessTokenPayload.In_Game.Game;
  const role = sesh.accessTokenPayload.In_Game.Role;
  const dispatch = useDispatch();
  const location = useLocation();
  let { sessionSelected } = location.state;
  const { data: sessions } = useGetSessionsQuery(game);
  const [deleteSesh] = useDeleteSessionMutation();
  const [editSesh] = useUpdateSessionMutation();
  const [session, setSession] = useState();
  const [isEditing, setIsEditing] = useState(false);
  const [openModal, setOpenModal] = useState(undefined);

  const clearDOMCache = () => {
    location.state.sessionSelected = undefined;
    sessionSelected = location.state.sessionSelected;
  };

  useEffect(() => {
    if (sessions && sessions.length) {
      dispatch(setSessions(sessions));
      setSession(sessions[0]);
    } else if (sessions && sessions.length === 0) {
      clearDOMCache();
      setSession(sessionObjTemplate);
    }

    if (sessionSelected) {
      setSession(sessionSelected);
    }
  }, [sessions]);

  const delSession = async () => {
    try {
      const delRes = await deleteSesh(session.id);
      if ('data' in delRes && delRes.data) {
        dispatch(
          setAlertInfo({
            alertType: 'success',
            message: `${session?.title} deleted`,
          })
        );
        dispatch(displayAlert());
      }
    } catch (err) {
      dispatch(setAlertInfo(DeleteFail));
      dispatch(displayAlert());
    }

    clearDOMCache();
    setOpenModal(undefined);
  };

  return (
    <section className='sessions-wrap relative mx-auto min-h-screen w-full max-w-[95rem]'>
      <div className='sessions-main relative mx-auto flex h-full min-h-screen w-full max-w-[95rem] justify-center py-4'>
        {isEditing ? (
          <SessionEditForm
            session={session}
            setIsEditing={setIsEditing}
            editFunc={editSesh}
            setSession={setSession}
            clearDOMCache={clearDOMCache}
          />
        ) : (
          <>
            <SessionList sessions={sessions} setSession={setSession} />

            <SessionToDisplay
              role={role}
              session={session}
              openModal={openModal}
              setOpenModal={setOpenModal}
              deleteFunc={delSession}
              isEditing={isEditing}
              setIsEditing={setIsEditing}
            />
          </>
        )}
      </div>
    </section>
  );
}

const SessionToDisplay = ({
  session,
  role,
  openModal,
  setOpenModal,
  deleteFunc,
  setIsEditing,
}) => {
  const [isActive, setIsActive] = useState(true);

  const DateAndTitle = (
    <>
      <div className='info-top-row mt-2 text-center'>
        <span className='session-logs-title'>{session?.title}</span>
        <span className='ml-3 text-lg text-[#420000]'>{session?.date}</span>
      </div>
      <hr className='details-seperating-bar mb-0 mt-1' />
    </>
  );

  return (
    <>
      <div className='flex flex-col'>
        <div className='session-display mr-2 min-h-full w-full max-w-[75rem]'>
          {role === 'GM' && session?.id && (
            <ActionButtons
              setOpenModal={setOpenModal}
              setIsEditing={setIsEditing}
            />
          )}
          {DateAndTitle}

          <div className='summary-parent'>
            <hr className='details-seperating-bar mx-4 mt-2 h-0' />

            <div className='details-chunk'>
              <DetailMD details={session?.summary || 'No summary added yet'} />
            </div>

            {role === 'GM' ? (
              <>
                <GMDetailHeader isActive={isActive} setIsActive={setIsActive} />

                {session?.gm_notes ? (
                  <div
                    className={`${
                      isActive ? 'max-h-full' : 'invisible max-h-0'
                    } details-chunk overflow-hidden transition-all duration-500 ease-in-out`}
                  >
                    <DetailMD details={session?.gm_notes} />
                  </div>
                ) : (
                  <p
                    className={`${
                      isActive ? 'max-h-full' : 'invisible max-h-0'
                    } details-chunk overflow-hidden transition-all duration-500 ease-in-out`}
                  >
                    No hidden notes added yet
                  </p>
                )}
              </>
            ) : null}
          </div>
        </div>
      </div>

      <FlowModal
        openModal={openModal}
        setOpenModal={setOpenModal}
        obj={session}
        deleteFunc={deleteFunc}
      />
    </>
  );
};

const SessionList = ({ sessions, setSession }) => {
  return (
    <div className='session-list-display flex w-[15rem] min-w-[10rem] max-w-[15rem] flex-col overflow-y-auto py-2'>
      {sessions && sessions.length
        ? sessions.map((sesh) => (
            <button
              type='button'
              key={sesh.id}
              className='session-list-item  mx-1 overflow-x-hidden whitespace-nowrap rounded-lg bg-[#fcf5e5] px-4 py-2'
              onClick={() => setSession(sesh)}
            >
              {sesh.title}{' '}
              <span className='text-sm text-gray-400'>{sesh.date}</span>
            </button>
          ))
        : null}
    </div>
  );
};

const ActionButtons = ({ setOpenModal, setIsEditing }) => {
  return (
    <div className='mt-0 flex justify-between'>
      <button
        type='button'
        className='session-button edit-session ml-2 bg-gray-200'
        onClick={() => setIsEditing(true)}
      >
        <FiEdit className='list-icon mr-1 inline' /> Edit
      </button>
      <button
        type='button'
        className='session-button delete-session mr-2 bg-gray-200'
        onClick={() => setOpenModal('pop-up')}
      >
        <FiTrash className='list-icon mr-1 inline' /> Delete
      </button>
    </div>
  );
};
