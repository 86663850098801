import React, { useState } from 'react';
import { FiX } from 'react-icons/fi';
import { useDispatch } from 'react-redux';
import FloatingLabelInput from '../archive/misc-components/FloatingLabelInput';
import { EditorMD } from '../archive/misc-components/MiscDetailComps';
import {
  displayAlert,
  setAlertInfo,
} from '../../redux/state-slices/alertSlice';
import './sessions.css';
import SubmitLoader from '../loading-ani/SubmitLoader';
import { UpdateFail } from '../alerts/ResponseAlerts';

export default function SessionEditForm({
  session,
  setIsEditing,
  editFunc,
  setSession,
  clearDOMCache,
}) {
  const dispatch = useDispatch();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [summary, setSummary] = useState(session?.summary);
  const [gmNotes, setGmNotes] = useState(session?.gm_notes);
  const [formData, setFormData] = useState({
    id: session?.id,
    title: session?.title,
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    const data = { ...formData, summary, gm_notes: gmNotes };

    try {
      const updateSessionRes = await editFunc(data);

      if (updateSessionRes && 'data' in updateSessionRes) {
        clearDOMCache();
        setSession(updateSessionRes?.data);
        dispatch(
          setAlertInfo({
            alertType: 'success',
            message: `${formData?.title} successfully updated`,
          })
        );
        dispatch(displayAlert());
        setIsEditing(false);
      }
    } catch (err) {
      dispatch(setAlertInfo(UpdateFail));
      dispatch(displayAlert());
    }
    setIsSubmitting(false);
  };

  return (
    <>
      {isSubmitting && <SubmitLoader />}
      <section className='relative flex min-h-full w-full max-w-[95rem] flex-col items-center gap-4'>
        <div className='flex justify-between'>
          <button
            type='button'
            onClick={() => setIsEditing(false)}
            className='session-button delete-session mr-2 rounded-lg bg-gray-200 px-4 py-2 shadow-[0_3px_8px_rgba(0,0,0,.24)]'
          >
            <FiX className='list-icon mr-1 inline' /> Cancel edit
          </button>
          <h3 className='ml-2 flex items-center'>
            This session was played on {session?.date}
          </h3>
        </div>

        <form
          onSubmit={(e) => handleSubmit(e)}
          className='session-display w-full max-w-[75rem]  items-center p-3'
        >
          <div className='border-image-container flex w-full flex-col items-center p-4'>
            <FloatingLabelInput
              onChange={handleChange}
              value={formData?.title}
              labelText='Title'
              nameAndID='title'
              additionalInputClassNames='asset-title mb-2'
            />
            <div className='w-full px-4'>
              <EditorMD mdText={summary} setMdText={setSummary} height='40vh' />
              <hr className='details-seperating-bar my-4' />

              <EditorMD mdText={gmNotes} setMdText={setGmNotes} height='40vh' />
            </div>

            <button
              type='submit'
              className='update-session-button my-2 rounded-lg bg-green-700 px-8 py-2 text-white'
            >
              Update
            </button>
          </div>
        </form>
      </section>
    </>
  );
}
