import React, { useState, useEffect } from 'react';
import { FaArrowRight, FaArrowLeft } from 'react-icons/fa6';

import CharacterCreateForm from './CharacterCreateForm';
import Stepper from './Stepper';
import LoadingSpinner from '../../loading-ani/LoadingSpinner';
import {
  GameImg,
  NoGameImg,
  Title,
  Description,
} from '../carousel/views/GmView';

import '../carousel/carousel.css';
import { useSearchForPartiesToJoinQuery } from '../../../redux/api-slices/partyAPI';

const SearchResults = ({
  queryString,
  gamesData,
  isFetching,
  handleJoinGame,
  setGamePasscode,
  willShowSelectedParties,
  setWillShowSelectedParties,
  selectedGameID,
  activeStep,
  setActiveStep,
}) => {
  const [skip, setSkip] = useState(true);
  const { data: parties } = useSearchForPartiesToJoinQuery(selectedGameID, {
    skip,
  });
  const [selectedPartyID, setSelectedPartyID] = useState(null);

  useEffect(() => {
    // EZ optimization if statement
    if (willShowSelectedParties && selectedGameID !== null) {
      setSkip(false);
    }
  }, [willShowSelectedParties]);

  const handleShowCharModal = (partyID) => {
    setActiveStep(4);
    setSelectedPartyID(partyID);
  };

  const handleJoinOnClick = async (e, gameID) => {
    if (await handleJoinGame(e, gameID)) {
      setActiveStep(3);
    }
  };

  return (
    <div>
      <div className='relative flex justify-around'>
        {
          // Start Search Results
          (activeStep === 1 || activeStep === 2) &&
            (queryString === null ? (
              <p>Search results will appear here</p>
            ) : gamesData?.length > 0 ? (
              gamesData?.map((game) => (
                // Start Games Cards
                <div
                  className='embla__slide max-w-md overflow-hidden rounded shadow-lg'
                  key={game.id}
                  elevation='5'
                >
                  {/* Card image */}

                  {game.img_url === 'No image provided' ? (
                    <NoGameImg />
                  ) : (
                    <GameImg game={game} />
                  )}

                  {/* Game details */}
                  <div className='game-info-cont px-6'>
                    <Title game={game} />

                    <hr className='mb-1 h-0.5 bg-[#420000]' />

                    <Description game={game} />

                    <div className='flex flex-col items-center pt-6'>
                      <p>
                        {' '}
                        <span className='font-semibold'>
                          Meeting Frequency:
                        </span>{' '}
                        {game.frequency}
                      </p>
                      <form
                        className='ml-16 pb-1 pt-2'
                        onSubmit={(e) => handleJoinOnClick(e, game.id)}
                      >
                        <input
                          className='border-2 border-black'
                          type='text'
                          placeholder='Password'
                          onChange={(e) => setGamePasscode(e.target.value)}
                        />
                        <button
                          className='inline-flex w-full rounded-md bg-green-700 px-3 py-2 text-sm font-semibold text-white shadow-sm sm:ml-3 sm:w-auto'
                          type='submit'
                        >
                          Join Game{' '}
                          <FaArrowRight className='self-center pl-1' />
                        </button>
                      </form>
                    </div>
                  </div>
                </div>
                // End Games Cards
              ))
            ) : isFetching ? (
              <div className='text-center'>
                <LoadingSpinner classStyle='loading-ani-red' />
              </div>
            ) : (
              <div className='flex flex-col items-center pt-10'>
                <p>No Games found for this GM</p>
                <small>Note: GM names are case-sensitive</small>
              </div>
            ))
        }

        {
          // Start Party Select
          activeStep === 3 &&
            (parties && parties.length > 0 ? (
              parties
                .filter((party) => party.is_open)
                .map((party) => (
                  <div
                    className='embla__slide max-w-md overflow-hidden rounded shadow-lg'
                    key={party.id}
                  >
                    <div className='flex flex-col'>
                      <div>
                        {party.img_url !== 'No image assigned' ? (
                          <GameImg game={party} />
                        ) : (
                          <NoGameImg />
                        )}
                        <div className='flex justify-center'>
                          <div className='text-lg text-[#420000]'>
                            {party.party_name}
                          </div>
                        </div>
                        <Title game={party} />
                        <hr className='mx-6 mb-1 h-0.5 bg-[#420000]' />
                        <div className='px-2'>
                          <Description game={party} />
                        </div>
                      </div>
                      <div className='flex items-center justify-between px-6 pt-6'>
                        <p>
                          {' '}
                          <span className='font-semibold'>
                            Party Wealth:
                          </span>{' '}
                          {party.wealth}
                        </p>
                        <div className='mb-2 flex gap-2'>
                          <button
                            type='button'
                            onClick={() => {
                              setWillShowSelectedParties(false);
                              setGamePasscode('');
                              setActiveStep(2);
                            }}
                            className='submit-button rounded-lg bg-[#420000] px-4 py-2'
                          >
                            <FaArrowLeft className='self-center pr-1' />
                            Back
                          </button>
                          <button
                            className='submit-button rounded-lg bg-green-700 px-4 py-2 text-white'
                            type='button'
                            onClick={() => handleShowCharModal(party.id)}
                          >
                            Join Party{' '}
                            <FaArrowRight className='self-center pl-1' />
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                ))
            ) : (
              // End Party Card
              <p>No parties exist for this game yet, check with your GM.</p>
            ))
          // End Party Select
        }

        {
          // Start Add Character
          activeStep === 4 && (
            <CharacterCreateForm
              partyID={selectedPartyID}
              setActiveStep={setActiveStep}
            />
          )
        }
      </div>
    </div>
  );
};

export default SearchResults;
