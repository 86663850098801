import React from 'react';
import { Modal } from 'flowbite-react';
import './loading.css';
import LoadingSpinner from './LoadingSpinner';

export default function SubmitLoader({ message = 'Updating' }) {
  const modalTheme = {
    root: {
      show: {
        on: 'flex bg-white bg-opacity-40',
      },
    },

    content: {
      inner:
        'flowbite-header-parent border-blue-600 border-2 rounded-lg bg-white',
    },
  };

  return (
    <Modal theme={modalTheme} show size='md'>
      <Modal.Header />
      <Modal.Body>
        <div className='flex h-full w-full items-center justify-center'>
          <span className='mr-3 scale-[1.2] text-blue-600'>{message}</span>
          <LoadingSpinner classStyle='loading-ani-blue' />
        </div>
      </Modal.Body>
    </Modal>
  );
}
