import dgnApi from './dgnAPI';

const partyApi = dgnApi.injectEndpoints({
  endpoints: (build) => ({
    getParty: build.query({
      query: (partyID) => ({
        url: `/api/party/${partyID}`,
      }),
      providesTags: ['Party'],
    }),

    getPartyChars: build.query({
      query: (partyID) => ({
        url: `/api/party/players/${partyID}`,
      }),
      providesTags: ['Party'],
    }),

    getAllPartiesByGame: build.query({
      query: (gameID) => ({
        url: `/api/parties/${gameID}`,
      }),
      providesTags: ['Party'],
    }),

    updateParty: build.mutation({
      query: (data) => {
        const { id, ...body } = data;
        return {
          method: 'PUT',
          url: `/api/party/${id}`,
          body,
        };
      },
      invalidatesTags: ['Party'],
    }),

    searchForPartiesToJoin: build.query({
      query: (gameID) => ({
        url: `/api/search/parties/${gameID}`,
      }),
      providesTags: ['Party'],
    }),

    deleteParty: build.mutation({
      query: (pID) => ({
        method: 'DELETE',
        url: `/api/party/${pID}`,
      }),
    }),
  }),
});

export const {
  useGetPartyQuery,
  useGetAllPartiesByGameQuery,
  useUpdatePartyMutation,
  useGetPartyCharsQuery,
  useSearchForPartiesToJoinQuery,
  useDeletePartyMutation,
} = partyApi;
