import { createSlice } from '@reduxjs/toolkit';
import { resetAll } from './compareSlice';

const initialState = {
  allSessions: [],
  selectedSession: null,
};

const sessionSlice = createSlice({
  name: 'session',
  initialState,
  extraReducers: (obj) => obj.addCase(resetAll, () => initialState),
  reducers: {
    setSessions: (state, action) => {
      state.allSessions = action.payload;
    },

    setOneSession: (state, action) => {
      state.selectedSession = action.payload;
    },
  },
});

export const { setSessions, setOneSession } = sessionSlice.actions;

export default sessionSlice.reducer;

export const selectAllSessions = (state) => state.sessions.allSessions;
export const selectOneSession = (state) => state.sessions.selectedSession;
