import React from 'react';

// Components
import { Checkbox } from 'flowbite-react';

// Redux
import { useDispatch, useSelector } from 'react-redux';
import {
  addToCompare,
  removeAnItem,
  selectCheckedElems,
  elemChecker,
} from '../../../redux/state-slices/compareSlice';

export default function CheckboxCompare({ obj, category, elId }) {
  const dispatch = useDispatch();

  const checkedLegend = useSelector(selectCheckedElems);

  const toggle = (e, compareObj) => {
    const isChecked = e.target.checked;
    dispatch(elemChecker({ elId, isChecked }));

    if (isChecked) {
      const checkedObj = { ...compareObj, category, elId };

      dispatch(addToCompare(checkedObj));
    } else if (isChecked === false) {
      dispatch(removeAnItem({ sender: 'content', content: compareObj }));
    }
  };

  const checkboxTheme = {
    root: {
      base: 'h-4 w-4 rounded border border-gray-300 bg-gray-100',
    },
  };

  return (
    <Checkbox
      theme={checkboxTheme}
      id={elId}
      checked={checkedLegend[`${elId}`] || false}
      className='mr-3'
      onChange={(e) => toggle(e, obj)}
    />
  );
}
