import React, { useState } from 'react';
import { useSelector } from 'react-redux';

// Components
import BackAndFullscreen from '../../misc-components/BackAndFullscreen';
import {
  GMDetailHeader,
  GMLocationHeader,
  LocationHeader,
  ObjDetailImg,
  RelatedInfo,
  DetailMD,
} from '../../misc-components/MiscDetailComps';

// Redux
import { selectOneScene } from '../../../../redux/state-slices/sceneSlice';

// Styles
import '../selected.css';

export default function SceneDetails({ setSceneDetails, role }) {
  const currentScene = useSelector(selectOneScene);

  return (
    <div className='selected-content-details-wrap'>
      <BackAndFullscreen setStateFunc={setSceneDetails} />

      <SceneInfo currentScene={currentScene} role={role} />
    </div>
  );
}

export const SceneInfo = ({ currentScene, role }) => {
  return (
    <div className='selected-content-details'>
      <div className='border-image-container'>
        <DetailsInfo currentScene={currentScene} role={role} />
      </div>
    </div>
  );
};

const DetailsInfo = ({ currentScene, role }) => {
  const [isActive, setIsActive] = useState(true);

  return (
    <div className='asset-info-main relative'>
      <TopRow currentScene={currentScene} />

      <div className='info-description'>
        <div className='mt-0 h-[40rem] w-full'>
          <ObjDetailImg currentObj={currentScene} />
        </div>
        <hr className='details-seperating-bar mx-4 mt-2 h-0' />

        <div className='details-chunk'>
          <DetailMD details={currentScene.details} />
        </div>

        {role === 'GM' ? (
          <>
            <GMDetailHeader isActive={isActive} setIsActive={setIsActive} />

            {currentScene.hidden_details ? (
              <div
                className={`${
                  isActive ? 'max-h-full' : 'invisible max-h-0'
                } details-chunk overflow-hidden transition-all duration-500 ease-in-out`}
              >
                <DetailMD details={currentScene.hidden_details} />
              </div>
            ) : (
              <p
                className={`${
                  isActive ? 'max-h-full' : 'invisible max-h-0'
                } details-chunk overflow-hidden transition-all duration-500 ease-in-out`}
              >
                No hidden notes to show.
              </p>
            )}
          </>
        ) : null}
        <MiscInfo currentScene={currentScene} role={role} />
      </div>
    </div>
  );
};

const TopRow = ({ currentScene }) => {
  const hasDataClass = currentScene.location
    ? 'ml-1 mr-3 text-violet-600'
    : 'ml-1 m4-3';

  return (
    <>
      <div className='info-top-row mt-2 text-center'>
        <span className='asset-title'>{currentScene.title}</span>
      </div>
      <div className='mt-3 flex justify-center'>
        Location:{' '}
        <span className={hasDataClass}>
          {currentScene.location || 'Unknown'}
        </span>
      </div>
      <hr className='details-seperating-bar mb-0 mt-1' />
    </>
  );
};

const MiscInfo = ({ currentScene, role }) => {
  const [isActiveLoc, setIsActiveLoc] = useState(true);
  const [isActiveLocGM, setIsActiveLocGM] = useState(false);

  const sceneName = (
    <span className='text-violet-600'>{currentScene.title}</span>
  );

  return (
    <>
      <RelatedInfo />

      <LocationHeader isActive={isActiveLoc} setIsActive={setIsActiveLoc} />

      <div
        className={`${
          isActiveLoc ? 'max-h-full' : 'invisible max-h-0'
        } mx-4 my-2 overflow-hidden transition-all duration-500 ease-in-out`}
      >
        {currentScene.location_details ? (
          <DetailMD details={currentScene.location_details} />
        ) : (
          <span>{sceneName}&apos;s location details are unknown.</span>
        )}
      </div>

      {role === 'GM' ? (
        <>
          <GMLocationHeader
            isActive={isActiveLocGM}
            setIsActive={setIsActiveLocGM}
          />

          <div
            className={`${
              isActiveLocGM ? 'max-h-full' : 'invisible max-h-0'
            } mx-4 overflow-hidden transition-all duration-500 ease-in-out`}
          >
            {currentScene.location_hidden_details ? (
              <DetailMD details={currentScene.location_hidden_details} />
            ) : (
              <>No hidden location notes to display.</>
            )}
          </div>
        </>
      ) : null}
    </>
  );
};
