import dgnApi from './dgnAPI';

const dgnaiAPI = dgnApi.injectEndpoints({
  endpoints: (build) => ({
    generateAsset: build.mutation({
      query: (data) => ({
        method: 'POST',
        url: `/api/ai/asset`,
        body: data,
      }),
    }),
  }),
});

// eslint-disable-next-line import/prefer-default-export
export const { useGenerateAssetMutation } = dgnaiAPI;
