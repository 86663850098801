import dgnApi from './dgnAPI';

const sceneApi = dgnApi.injectEndpoints({
  endpoints: (build) => ({
    getScenes: build.query({
      query: (gid) => ({
        url: `/api/scenes/${gid}`,
      }),
      providesTags: ['Scenes'],
    }),

    createScene: build.mutation({
      query: (data) => ({
        method: 'POST',
        url: '/api/scene',
        body: data,
      }),
      invalidatesTags: ['Scenes'],
    }),

    getOneScene: build.query({
      query: (id) => ({
        url: `/api/scene/${id}`,
      }),
    }),

    updateScene: build.mutation({
      query: (data) => {
        const { id, ...body } = data;
        return {
          method: 'PUT',
          url: `/api/scene/${id}`,
          body,
        };
      },
      invalidatesTags: ['Scenes'],
    }),

    deleteScene: build.mutation({
      query: (id) => ({
        method: 'DELETE',
        url: `/api/scene/${id}`,
      }),
      invalidatesTags: ['Scenes'],
    }),
  }),
});

export const {
  useCreateSceneMutation,
  useDeleteSceneMutation,
  useGetOneSceneQuery,
  useGetScenesQuery,
  useUpdateSceneMutation,
} = sceneApi;
