/* eslint-disable react/no-array-index-key */
import React, { useState } from 'react';
import { useSelector } from 'react-redux';

// Components
import { FiPlus, FiMinus } from 'react-icons/fi';
import {} from '../../../game/CreateGame';
import BackAndFullscreen from '../../misc-components/BackAndFullscreen';
import {
  GMDetailHeader,
  ObjDetailImg,
  RelatedInfo,
  DetailMD,
} from '../../misc-components/MiscDetailComps';

// Redux
import { selectOneLocation } from '../../../../redux/state-slices/locationSlice';

// Styles
import '../selected.css';

export default function LocationDetails({ setLocationDetails, role }) {
  const currentLocation = useSelector(selectOneLocation);

  return (
    <div className='selected-content-details-wrap'>
      <BackAndFullscreen setStateFunc={setLocationDetails} />

      <LocationInfo currentLocation={currentLocation} role={role} />
    </div>
  );
}

export const LocationInfo = ({ currentLocation, role }) => {
  return (
    <div className='selected-content-details'>
      <div className='border-image-container'>
        <DetailsInfo currentLocation={currentLocation} role={role} />
      </div>
    </div>
  );
};

const DetailsInfo = ({ currentLocation, role }) => {
  const [isActive, setIsActive] = useState(true);

  return (
    // h-[61.5rem]
    <div className='asset-info-main relative'>
      <TopRow currentLocation={currentLocation} />

      <div className='info-description'>
        <div className='mt-0 h-[40rem] w-full'>
          <ObjDetailImg currentObj={currentLocation} />
        </div>
        <hr className='details-seperating-bar mx-4 mt-2 h-0' />

        <div className='details-chunk'>
          <DetailMD details={currentLocation.details} />
        </div>

        {role === 'GM' ? (
          <>
            <GMDetailHeader isActive={isActive} setIsActive={setIsActive} />
            {currentLocation.hidden_details ? (
              <div
                className={`${
                  isActive ? 'max-h-full' : 'invisible max-h-0'
                } details-chunk overflow-hidden transition-all duration-500 ease-in-out`}
              >
                <DetailMD details={currentLocation.hidden_details} />
              </div>
            ) : (
              <p
                className={`${
                  isActive ? 'max-h-full' : 'invisible max-h-0'
                } details-chunk overflow-hidden transition-all duration-500 ease-in-out`}
              >
                No hidden notes to show.
              </p>
            )}
          </>
        ) : null}

        <MiscInfo currentLocation={currentLocation} role={role} />
      </div>
    </div>
  );
};

const TopRow = ({ currentLocation }) => {
  return (
    <>
      <div className='info-top-row mt-2 text-center'>
        <span className='asset-title'>{currentLocation.name}</span>
      </div>
      <hr className='details-seperating-bar mb-0 mt-1' />
    </>
  );
};

const MiscInfo = ({ currentLocation }) => {
  const [isActive, setIsActive] = useState(true);
  const icon = isActive ? (
    <FiMinus className='ml-4 inline' />
  ) : (
    <FiPlus className='ml-4 inline' />
  );

  const locationName = (
    <span className='text-violet-600'>{currentLocation.name}</span>
  );

  return (
    <>
      <RelatedInfo />

      <div className='mx-4 mt-4 text-xl'>
        <span
          role='presentation'
          onClick={() => setIsActive(!isActive)}
          onKeyDown={() => setIsActive(!isActive)}
          className='related-details-title px-3 py-1 hover:cursor-pointer'
        >
          Groups{icon}
        </span>
      </div>
      <hr className='details-seperating-bar mx-4 mb-2 h-0' />

      <div
        className={`${
          isActive ? 'max-h-full' : 'invisible max-h-0'
        } mx-4 overflow-hidden transition-all duration-500 ease-in-out`}
      >
        <ShowFactions loc={currentLocation} locName={locationName} />
      </div>
    </>
  );
};

const ShowFactions = ({ loc, locName }) => {
  const locFactions = loc.factions;

  return locFactions && locFactions[0] !== null ? (
    <ul className='related-list grid list-inside list-disc grid-flow-col whitespace-pre marker:mr-[08px]'>
      {locFactions.map((facName, i) => {
        return (
          <li
            key={i}
            className='w-[10rem] overflow-hidden text-ellipsis hover:scale-[1.02] hover:cursor-pointer hover:overflow-visible hover:text-violet-600'
          >
            {facName}
          </li>
        );
      })}
    </ul>
  ) : (
    <span>{locName} has no related groups.</span>
  );
};
