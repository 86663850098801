import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { Button, Modal, Tooltip } from 'flowbite-react';
import { FiAlertTriangle } from 'react-icons/fi';
import {
  useDeleteGameMutation,
  useGetGameInfoQuery,
  useUpdateGameMutation,
} from '../../redux/api-slices/gameAPI';
import {
  displayAlert,
  setAlertInfo,
} from '../../redux/state-slices/alertSlice';
import {
  gatherAssets,
  spacesDelete,
  spacesUpload,
} from '../../digitalocean/spaces';
import { DeleteFail, UpdateFail } from '../alerts/ResponseAlerts';
import FloatingLabelInput from '../archive/misc-components/FloatingLabelInput';
import SizeChecker from '../archive/misc-components/SizeChecker';
import DgnFileInput from '../archive/misc-components/DgnFileInput';
import { EditorMD } from '../archive/misc-components/MiscDetailComps';
import SubmitLoader from '../loading-ani/SubmitLoader';
import './gameSettings.css';
import { useGetPartyCharsQuery } from '../../redux/api-slices/partyAPI';

export default function GameSettings({ gameID, username, navigate, partyID }) {
  const dispatch = useDispatch();
  const [skip, setSkip] = useState(false);
  const { data: gameInfo } = useGetGameInfoQuery(gameID, { skip });
  const [updateGameFunc] = useUpdateGameMutation();
  const [deleteGame] = useDeleteGameMutation();
  const [imgFile, setImgFile] = useState(null);
  const [imgSize, setImgSize] = useState(null);
  const [oldImgURL, setOldImgURL] = useState('');
  const [gameDesc, setGameDesc] = useState('');
  const [gameUpdateForm, setGameUpdateForm] = useState({
    title: '',
    frequency: '',
    passcode: '',
    img_url: '',
  });
  const { data: partyChars } = useGetPartyCharsQuery(partyID);
  const [openModal, setOpenModal] = useState(undefined);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState('Updating');

  useEffect(() => {
    if (gameInfo) {
      setGameUpdateForm({
        title: gameInfo.title,
        frequency: gameInfo.frequency,
        passcode: gameInfo.passcode,
        img_url: gameInfo.img_url,
      });
      setOldImgURL(gameInfo.img_url);
      setGameDesc(gameInfo.description);
    }
  }, [gameInfo]);

  useEffect(() => {
    if (imgFile && imgFile.size) {
      const mb = imgFile.size / 1024 / 1024;
      setImgSize(mb.toFixed(2));
    }
  }, [imgFile]);

  const handleChange = async (e) => {
    setGameUpdateForm({ ...gameUpdateForm, [e.target.name]: e.target.value });
  };

  const handleImgChange = (e) => {
    const { files } = e.target;
    if (files && files.length) {
      const imgObj = files[0];
      setImgFile(imgObj);
      setGameUpdateForm({
        ...gameUpdateForm,
        img_url: URL.createObjectURL(imgObj),
      });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    const data = { ...gameUpdateForm, description: gameDesc, id: gameID };
    try {
      if (imgFile && oldImgURL) {
        if (oldImgURL.includes(process.env.REACT_APP_BUCKET_URL)) {
          await spacesDelete(oldImgURL, username);
        }
        const imgResponse = await spacesUpload(
          imgFile,
          'GameAssets/GameImage',
          username
        );

        if (imgResponse) {
          data.img_url = imgResponse;
        }
      }
    } catch (err) {
      dispatch(
        setAlertInfo({
          alertType: 'failure',
          message: 'There was an issue uploading this image',
        })
      );
      dispatch(displayAlert());
    }

    try {
      const gameUpdateRes = await updateGameFunc(data);
      if (gameUpdateRes && 'data' in gameUpdateRes) {
        dispatch(
          setAlertInfo({
            alertType: 'success',
            message: 'Game successfully updated',
          })
        );
        dispatch(displayAlert());
      } else if (gameUpdateRes && 'error' in gameUpdateRes) {
        dispatch(setAlertInfo(UpdateFail));
        dispatch(displayAlert());
      }
    } catch (err) {
      dispatch(setAlertInfo(UpdateFail));
      dispatch(displayAlert());
    }
    setIsSubmitting(false);
  };

  const imgDeleter = async (url, plUsername) => {
    await spacesDelete(url, plUsername);
  };

  const deleteCharImgs = async () => {
    const playerUsernamesAndIMGs =
      partyChars &&
      partyChars.map((char) => ({
        username: char.username,
        img_url: char.img_url,
      }));

    playerUsernamesAndIMGs.forEach(
      async (charData) =>
        charData.img_url && imgDeleter(charData.img_url, charData.username)
    );
  };

  const failedDeleteAlert = () => {
    dispatch(setAlertInfo(DeleteFail));
    dispatch(displayAlert());
  };

  const handleDeleteGame = async () => {
    setOpenModal(undefined);
    setSkip(true);
    setIsSubmitting(true);
    setLoadingMessage('Deleting game');
    try {
      await deleteCharImgs();
      const gameBucketKeys = await gatherAssets(username, 'GameAssets');
      if (gameBucketKeys && gameBucketKeys.length > 0) {
        gameBucketKeys.forEach(async (obj) =>
          imgDeleter(`${process.env.REACT_APP_BUCKET_URL}${obj?.Key}`, username)
        );
      }
    } catch (err) {
      dispatch(
        setAlertInfo({
          alertType: 'failure',
          message: 'There was an issue deleting images',
        })
      );
      dispatch(displayAlert());
    }

    try {
      const gameDelRes = await deleteGame(gameID);
      if ('data' in gameDelRes && gameDelRes.data) {
        dispatch(
          setAlertInfo({
            alertType: 'success',
            message: 'Game deleted',
          })
        );
        dispatch(displayAlert());
      } else if (
        ('data' in gameDelRes && gameDelRes.data === false) ||
        'detail' in gameDelRes ||
        'error' in gameDelRes
      ) {
        failedDeleteAlert();
      }
    } catch (err) {
      failedDeleteAlert();
    }

    navigate('/');
    setIsSubmitting(false);
  };

  return (
    <>
      {isSubmitting && <SubmitLoader message={loadingMessage} />}
      <div className='game-settings-content-wrapper mx-auto pt-8'>
        <DeleteGameModal
          openModal={openModal}
          setOpenModal={setOpenModal}
          obj={gameInfo}
          deleteFunc={handleDeleteGame}
        />

        <form
          action='submit'
          onSubmit={handleSubmit}
          className='game-settings-form relative mx-auto my-2 max-w-[95rem] p-3'
        >
          <div className='border-image-container p-4'>
            <div className='mt-8 flex h-full w-full flex-col items-center'>
              <FloatingLabelInput
                onChange={handleChange}
                value={gameUpdateForm?.title}
                labelText='Title'
                nameAndID='title'
                additionalInputClassNames='asset-title mx-auto'
                maxLength={60}
              />

              <div className='flex justify-evenly pt-16'>
                <FloatingLabelInput
                  onChange={handleChange}
                  value={gameUpdateForm?.frequency}
                  labelText='Frequency'
                  nameAndID='frequency'
                  additionalInputClassNames='asset-title px-4 py-1 mr-1'
                  maxLength={60}
                />

                <FloatingLabelInput
                  onChange={handleChange}
                  value={gameUpdateForm?.passcode}
                  labelText='Passcode'
                  nameAndID='passcode'
                  additionalInputClassNames='asset-title px-4 py-1 ml-1'
                  maxLength={25}
                />
              </div>

              <div className='mt-8'>
                <div className='img-container'>
                  <img
                    src={gameUpdateForm?.img_url}
                    alt=''
                    className='img-actual'
                  />
                </div>

                {imgSize && (
                  <div className='flex justify-center'>
                    <button
                      className='revert-img-button mt-2 rounded-lg bg-red-100 px-4 py-2'
                      type='button'
                      onClick={() => {
                        setGameUpdateForm({
                          ...gameUpdateForm,
                          img_url: oldImgURL,
                        });
                        setImgSize(null);
                        setImgFile(null);
                      }}
                    >
                      Clear image change
                    </button>
                  </div>
                )}

                <div className='m-2 flex justify-center p-2'>
                  {imgSize ? <SizeChecker imgSize={imgSize} /> : null}

                  <DgnFileInput imageChangeHandler={handleImgChange} />
                </div>
              </div>
            </div>

            <EditorMD mdText={gameDesc} setMdText={setGameDesc} height='60vh' />

            <div className='mt-3 flex justify-center gap-3'>
              {imgSize === null || imgSize <= 2 ? (
                <button
                  className='submit-button mb-5 rounded-lg bg-green-700 px-8 py-2 text-white'
                  type='submit'
                  disabled={isSubmitting}
                >
                  <span className=''>Update</span>
                </button>
              ) : (
                <Tooltip
                  // eslint-disable-next-line react/style-prop-object
                  style='light'
                  content='Your image is too large, consider compressing it'
                >
                  <button
                    className='submit-button mb-5 cursor-not-allowed rounded-lg bg-green-700 px-8 py-2 text-white'
                    type='submit'
                    disabled
                  >
                    <span className=''>Update</span>
                  </button>
                </Tooltip>
              )}
              <button
                type='button'
                className='delete-precious-resource-button mb-5 rounded-lg bg-red-700 px-8 py-2 text-white'
                onClick={() => setOpenModal('pop-up')}
              >
                Delete game
              </button>
            </div>
          </div>
        </form>
      </div>
    </>
  );
}

// --------modal stuff below-------
const ImplyDanger = ({ wordToEmphasize }) => {
  return <span className='font-semibold text-red-400'>{wordToEmphasize}</span>;
};

const DeleteGameModal = ({ openModal, setOpenModal, obj, deleteFunc }) => {
  const modalTheme = {
    root: {
      show: {
        on: 'flex bg-white bg-opacity-40',
      },
    },

    content: {
      inner: 'border-red-600 border-2 rounded-lg bg-white',
    },
  };

  return (
    <Modal
      theme={modalTheme}
      show={openModal === 'pop-up'}
      size='md'
      popup
      onClose={() => setOpenModal(undefined)}
    >
      <Modal.Header />
      <Modal.Body>
        <div className='text-center'>
          <FiAlertTriangle className='mx-auto mb-4 h-14 w-14 text-red-600' />
          <h3 className='mb-5 text-lg font-normal text-gray-500'>
            Are you sure you want to delete your game,{' '}
            <ImplyDanger wordToEmphasize={obj?.title} />? This will{' '}
            <ImplyDanger wordToEmphasize='permanently' /> and{' '}
            <ImplyDanger wordToEmphasize='irreversibly' /> delete{' '}
            <ImplyDanger wordToEmphasize='all' /> game documents & images.
          </h3>
          <div className='flex justify-center gap-4'>
            <Button color='failure' onClick={deleteFunc}>
              Yes, I am sure
            </Button>
            <Button color='warning' onClick={() => setOpenModal(undefined)}>
              No, cancel
            </Button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};
