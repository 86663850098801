import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { Carousel } from 'flowbite-react';
import { FiX } from 'react-icons/fi';
import { LocationInfo } from '../selected-content/content-details/LocationDetails';
import { FactionInfo } from '../selected-content/content-details/FactionDetails';
import { NpcInfo } from '../selected-content/content-details/NpcDetails';
import { ItemInfo } from '../selected-content/content-details/ItemDetails';
import { QuestInfo } from '../selected-content/content-details/QuestDetails';
import { SceneInfo } from '../selected-content/content-details/SceneDetails';

import {
  selectComparisonObjs,
  compareSelectedObjs,
} from '../../../redux/state-slices/compareSlice';

import '../selected-content/selected.css';

export default function CompareDisplay({ role }) {
  const toDisplay = useSelector(selectComparisonObjs);

  const carouselTheme = {
    root: {
      base: 'relative h-full w-full',
      leftControl:
        'absolute top-0 left-0 flex h-full items-center justify-center px-4 focus:outline-none',
      rightControl:
        'absolute top-0 right-0 flex h-full items-center justify-center px-4 focus:outline-none',
    },

    indicators: {
      active: {
        off: 'bg-[#A8524D] hover:bg-blue',
        on: 'bg-[#420000]',
      },
      wrapper: 'absolute bottom-5 left-1/2 flex -translate-x-1/2 space-x-3',
    },

    item: {
      base: 'absolute top-1/2 left-1/2 block w-full -translate-x-1/2 -translate-y-1/2',
      wrapper: 'w-full flex-shrink-0 transform cursor-grab snap-center',
    },

    control: {
      base: 'inline-flex h-8 w-8 items-center justify-center rounded-full group-hover:bg-[rgba(168, 82, 77, 0.5)] group-focus:outline-none group-focus:ring-4 group-focus:ring-white  sm:h-10 sm:w-10',
      icon: 'h-5 w-5 sm:h-6 sm:w-6 text-white',
    },

    scrollContainer: {
      base: 'flex h-full snap-mandatory overflow-y-hidden overflow-x-scroll scroll-smooth rounded-lg sm:rounded-2xl',
      snap: 'snap-x',
    },
  };

  return (
    <div className='relative h-full w-full'>
      <CloseButton />

      <Carousel theme={carouselTheme} slide={false} className='rounded-lg'>
        {toDisplay.map((obj) => {
          return (
            <div
              key={`${obj.id}-carousel`}
              className='selected-content-details-wrap'
            >
              <CarouselSlide obj={obj} role={role} />;
            </div>
          );
        })}
      </Carousel>
    </div>
  );
}

const CloseButton = () => {
  const dispatch = useDispatch();

  const closeDisplay = () => {
    dispatch(compareSelectedObjs(false));
  };

  return (
    <div className='absolute left-0 top-0 z-10 flex justify-between rounded-lg'>
      <button
        type='button'
        className='row-button rounded-lg bg-red-800 text-white sm:px-2 sm:py-1'
        onClick={closeDisplay}
      >
        <FiX className='list-icon mr-1 inline' /> Close
      </button>
    </div>
  );
};

const CarouselSlide = ({ obj, role }) => {
  const component = {
    location: <LocationInfo currentLocation={obj} role={role} />,
    faction: <FactionInfo currentFaction={obj} role={role} />,
    npc: <NpcInfo currentNpc={obj} role={role} />,
    item: <ItemInfo currentItem={obj} role={role} />,
    quest: <QuestInfo currentQuest={obj} role={role} />,
    scene: <SceneInfo currentScene={obj} role={role} />,
  };

  return component[obj.category];
};
