/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import FloatingLabelInput from '../archive/misc-components/FloatingLabelInput';
import { useGenerateAssetMutation } from '../../redux/api-slices/dgnaiAPI';
import {
  NPCoptions,
  groupOptions,
  itemOptions,
  locationOptions,
  questOptions,
  sceneOptions,
} from './AiTags';
import { GenerationFail } from '../alerts/ResponseAlerts';
import {
  displayAlert,
  setAlertInfo,
} from '../../redux/state-slices/alertSlice';

const AiForm = ({ setPublicDetails, activeOption }) => {
  const [gameAsset, setGameAsset] = useState('');
  const [tags, setTags] = useState([]);
  const [userFlavor, setUserFlavor] = useState('');
  const [level, setLevel] = useState('');
  const [generateAsset] = useGenerateAssetMutation();
  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useDispatch();

  const clearOptions = () => {
    setGameAsset(activeOption);
    setTags([]);
    setUserFlavor('');
    setLevel('');
  };

  useEffect(() => {
    clearOptions();
  }, [activeOption]);

  const handleSubmit = async () => {
    setIsLoading(true);
    const data = {
      game_asset: gameAsset,
      tags,
      user_flavor: userFlavor,
      level,
    };

    try {
      const aiGenerationRes = await generateAsset(data);
      if (aiGenerationRes.data) {
        setPublicDetails(aiGenerationRes?.data);
        dispatch(
          setAlertInfo({
            alertType: 'success',
            message: `${activeOption} details generated`,
          })
        );
        dispatch(displayAlert());
      } else if (
        ('data' in aiGenerationRes && aiGenerationRes.data === false) ||
        'detail' in aiGenerationRes ||
        'error' in aiGenerationRes
      ) {
        dispatch(setAlertInfo(GenerationFail));
        dispatch(displayAlert());
      }
    } catch (err) {
      dispatch(setAlertInfo(GenerationFail));
      dispatch(displayAlert());
    }
    setIsLoading(false);
  };

  const handleUserFlavor = (e) => {
    setUserFlavor(e.target.value);
  };

  const handleLevel = (e) => {
    setLevel(e.target.value);
  };

  const tagsToShow = {
    Group: <GroupTags tags={tags} setTags={setTags} />,
    Item: <ItemTags tags={tags} setTags={setTags} />,
    Location: <LocationTags tags={tags} setTags={setTags} />,
    NPC: <NPCTags tags={tags} setTags={setTags} />,
    Quest: <QuestTags tags={tags} setTags={setTags} />,
    Scene: <SceneTags tags={tags} setTags={setTags} />,
  };

  return (
    <div className='mt-4 flex w-full flex-col'>
      {['Item', 'NPC', 'Quest', 'Scene'].includes(activeOption) ? (
        <div className='my-4 flex justify-center'>
          <FloatingLabelInput
            onChange={(e) => handleLevel(e)}
            value={level}
            labelText='Level'
            nameAndID='Level'
            type='number'
            additionalInputClassNames='bg-[#074f57] text-white p-2'
            additionalLabelClassNames='text-white pl-2'
          />
        </div>
      ) : null}

      <div className='mb-4 flex w-full gap-x-4 overflow-x-scroll'>
        {tagsToShow[activeOption]}
      </div>

      <FloatingLabelInput
        onChange={(e) => handleUserFlavor(e)}
        value={userFlavor}
        labelText='Flavor Text'
        nameAndID='userFlavor'
        maxLength='100ch'
        additionalInputClassNames='bg-[#074f57] text-white w-full p-2'
        additionalLabelClassNames='text-white'
      />

      <button
        type='button'
        onClick={handleSubmit}
        disabled={tags.length === 0 || isLoading}
        className='submit-button mt-4 inline self-center rounded-lg bg-green-700 px-4 py-2 text-white disabled:cursor-not-allowed'
      >
        {isLoading ? (
          <span className='flex gap-x-2'>
            <Spinner />
            Generating
          </span>
        ) : (
          'Generate'
        )}
      </button>
    </div>
  );
};

export default AiForm;

const assetTagFactory = (assetOptions, tags, setTags) => {
  const handleCheck = (e) => {
    const isChecked = e.target.checked;
    const val = e.target.value;

    if (isChecked) {
      const updatedTags = [...tags];
      updatedTags.push(val);
      setTags(updatedTags);
    } else if (!isChecked && tags.length === 1) {
      setTags([]);
    } else {
      const copiedTags = [...tags];
      copiedTags.map((tag, i) => tag === val && copiedTags.splice(i, 1));
      setTags(copiedTags);
    }
  };

  return assetOptions.map((option) => (
    <div
      key={option.id}
      className='flex h-full min-w-fit flex-col items-start gap-y-2 p-2'
    >
      <p className='whitespace-nowrap rounded-lg bg-[#074f57] px-2 py-1 text-xl text-white'>
        {option.title}
      </p>

      <div className='grid grid-cols-1'>
        {option.tags.map((tag, i) => (
          <label
            key={i}
            className='whitespace-nowrap hover:scale-[1.05] hover:cursor-pointer'
          >
            <input
              type='checkbox'
              name={`${tag}`}
              className='mr-1'
              value={`${option.title}: ${tag}`}
              checked={tags.includes(`${option.title}: ${tag}`)}
              onChange={(e) => handleCheck(e)}
            />
            {tag}
          </label>
        ))}
      </div>
    </div>
  ));
};

const GroupTags = ({ tags, setTags }) => {
  return assetTagFactory(groupOptions, tags, setTags);
};

const ItemTags = ({ tags, setTags }) => {
  return assetTagFactory(itemOptions, tags, setTags);
};

const LocationTags = ({ tags, setTags }) => {
  return assetTagFactory(locationOptions, tags, setTags);
};

const NPCTags = ({ tags, setTags }) => {
  return assetTagFactory(NPCoptions, tags, setTags);
};

const QuestTags = ({ tags, setTags }) => {
  return assetTagFactory(questOptions, tags, setTags);
};

const SceneTags = ({ tags, setTags }) => {
  return assetTagFactory(sceneOptions, tags, setTags);
};

const Spinner = () => {
  return (
    <svg
      aria-hidden='true'
      className='inline h-6 w-6 animate-spin fill-gray-600 text-white'
      viewBox='0 0 100 101'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z'
        fill='currentColor'
      />
      <path
        d='M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z'
        fill='currentFill'
      />
    </svg>
  );
};
