import React, { useState } from 'react';

import { FaSearch } from 'react-icons/fa';
import { FaDeleteLeft } from 'react-icons/fa6';

import './SearchBar.css';

function SearchBar({
  queryString,
  setQueryString,
  setGamePasscode,
  setSkip,
  setWillShowSelectedParties,
  setActiveStep,
}) {
  const [searchInputValue, setSearchInputValue] = useState('');

  const handleFormSubmit = (e) => {
    e.preventDefault();
    setSkip(false);
    setQueryString(searchInputValue);
    setActiveStep(2);
  };

  const handleClearSearchInput = () => {
    setSearchInputValue('');
    setQueryString(null);
    setSkip(true);
    setWillShowSelectedParties(false);
    setGamePasscode('');
    setActiveStep(1);
  };

  return (
    <div className='flex w-full flex-col items-center justify-center'>
      <div>
        <div
          id='search-container'
          className='display-over-overlay flex justify-center rounded-md'
        >
          <form onSubmit={handleFormSubmit} className='flex'>
            <div className='relative mt-2 w-min'>
              <input
                className='border-b-2 border-[#420000]'
                onChange={(e) => setSearchInputValue(e.target.value)}
                id='search-bar'
                placeholder='Search by GM'
                value={searchInputValue || ''}
              />
              <button
                className='absolute right-3 top-[0.3rem]'
                onClick={() => handleClearSearchInput()}
                type='button'
              >
                <FaDeleteLeft />
              </button>
            </div>
            <button className='pl-2 pt-2' type='submit'>
              <FaSearch />
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default SearchBar;
