import React, { useEffect, useState } from 'react';
import { useSessionContext } from 'supertokens-auth-react/recipe/session';

import { FiMinus, FiPlus, FiPlusCircle } from 'react-icons/fi';
import { FaPencil } from 'react-icons/fa6';

import FloatingLabelInput from '../archive/misc-components/FloatingLabelInput';

import { useGetCharacterQuery } from '../../redux/api-slices/characterAPI';
import {
  useGetAbilitiesQuery,
  useCreateAbilityMutation,
  useUpdateAbilityMutation,
  useDeleteAbilityMutation,
} from '../../redux/api-slices/abilitiesAPI';

import '../archive/selected-content/selected.css';
import '../game/createGame.css';
import './characterAbilities.css';

const AbilityCounter = ({ ability }) => {
  const [totalUses, setTotalUses] = useState(ability.num_uses);
  const [title, setTitle] = useState(ability.title);
  const [description, setDescription] = useState(ability.description);
  const [numUsed, setNumUsed] = useState(ability.num_used);
  const [isEditMode, setIsEditMode] = useState(false);
  const [isEditingDescription, setIsEditingDescription] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const [updateAbility] = useUpdateAbilityMutation();
  const [deleteAbility] = useDeleteAbilityMutation();

  const increment = () => {
    if (numUsed < totalUses) {
      updateAbility({ ...ability, num_used: numUsed + 1 });
      setNumUsed(numUsed + 1);
    }
  };

  const decrement = () => {
    if (numUsed - 1 >= 0) {
      updateAbility({ ...ability, num_used: numUsed - 1 });
      setNumUsed(numUsed - 1);
    }
  };

  const increaseTotalUses = () => {
    // updateAbility({ ...ability, num_uses: totalUses + 1 });
    setTotalUses(totalUses + 1);
  };

  const decreaseTotalUses = () => {
    // Only allow decreasing down to one use
    if (totalUses - 1 > 0) {
      // updateAbility({ ...ability, num_uses: totalUses - 1 });
      setTotalUses(totalUses - 1);
    }
  };

  const toggleDescriptionEdit = () => {
    if (!isEditingDescription) {
      setIsEditingDescription(true);
    } else {
      if (description !== ability.description) {
        updateAbility({ ...ability, description });
      }
      setIsEditingDescription(false);
    }
  };

  const toggleEditMode = () => {
    if (isEditMode) {
      const updated = { ...ability };
      if (totalUses !== ability.num_uses) {
        updated.num_uses = totalUses;
        updateAbility(updated);
      }
    }

    setIsEditMode(!isEditMode);
    setIsEditingDescription(false);
  };

  const updateTitle = async () => {
    setIsUpdating(true);
    const updated = { ...ability, title };
    const res = await updateAbility(updated);
    if (res.data) {
      setIsUpdating(false);
    }
  };

  const handleDeleteAbility = async () => {
    setIsDeleting(true);
    const res = await deleteAbility(ability.id);

    if (res) {
      setIsDeleting(false);
    }
  };

  return (
    <div>
      <div id='counter-content' className='my-2 flex items-center'>
        <FloatingLabelInput
          value={title}
          readOnly={!isEditMode}
          onChange={(e) => setTitle(e.target.value)}
          nameAndID={title}
          additionalInputClassNames='asset-title text-base mr-4'
        />
        <div id='counter-dots' className='flex'>
          {[...Array(totalUses)].map((_, idx) => {
            return (
              // eslint-disable-next-line react/no-array-index-key
              <div key={idx} className={`dot ${idx < numUsed && 'filled'}`} />
            );
          })}
        </div>
        <div id='counter-controlls' className='ml-2 pt-1'>
          <button
            type='button'
            className='border-2 border-[#074f57] bg-[#074f57]'
            onClick={decrement}
          >
            <div className='m-0'>
              <FiMinus className='text-[#fcf5e5]' />
            </div>
          </button>
          <button
            type='button'
            className='mx-2 border-2 border-[#074f57] bg-[#074f57]'
            onClick={increment}
          >
            <FiPlus className='text-[#fcf5e5]' />
          </button>
          <button
            type='button'
            className='mx-2 border-2 border-[#074f57] bg-[#074f57]'
            onClick={toggleEditMode}
          >
            <FaPencil className='text-[#fcf5e5]' />
          </button>
        </div>
      </div>
      {isEditMode && (
        <div>
          <div>
            <div className='flex'>
              <button
                type='button'
                onClick={decreaseTotalUses}
                className='create-content-button row-button rounded-lg bg-gray-200 text-green-800'
              >
                - Uses
              </button>
              <button
                type='button'
                onClick={increaseTotalUses}
                className='create-content-button row-button rounded-lg bg-gray-200 text-green-800'
              >
                + Uses
              </button>
              <button
                type='button'
                onClick={handleDeleteAbility}
                className='row-button delete-content rounded-lg bg-gray-200'
              >
                {isDeleting ? 'Deleting ...' : 'Delete'}
              </button>
            </div>

            <div className='pb-1 pl-2'>
              <button
                className='mr-2 rounded-lg bg-green-700 px-4 py-2 text-white hover:bg-green-800 disabled:bg-gray-500'
                type='button'
                disabled={title === ability.title}
                onClick={updateTitle}
              >
                {isUpdating ? 'Updating ...' : 'Update Name'}
              </button>
              <button
                type='button'
                onClick={toggleDescriptionEdit}
                className='mr-2 rounded-lg bg-green-700 px-4 py-2 text-white hover:bg-green-800 disabled:bg-gray-500'
              >
                {isEditingDescription ? 'Save' : 'Edit'} Description
              </button>
            </div>
          </div>
        </div>
      )}
      {isEditingDescription ? (
        <div>
          <textarea
            cols={60}
            rows={5}
            className='min-h-4 mt-4 max-w-[65%] resize'
            placeholder='Ability description'
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
        </div>
      ) : (
        <div className='text-sm'>{description}</div>
      )}
    </div>
  );
};

const SpellSlotCounter = ({
  spellSlot,
  idx,
  spellSlots,
  setSpellSlots,
  characterName,
}) => {
  const [totalUses, setTotalUses] = useState(spellSlot.num_uses);
  const [title, setTitle] = useState(spellSlot.title);
  const [numUsed, setNumUsed] = useState(spellSlot.num_used);
  const [isEditMode, setIsEditMode] = useState(false);

  // TODO: Refactor out repeated logic
  const increment = () => {
    if (numUsed < totalUses) {
      setNumUsed(numUsed + 1);
      const updated = spellSlots.map((slot, i) => {
        if (i === idx) {
          return { ...slot, num_used: slot.num_used + 1 };
        }
        return slot;
      });
      setSpellSlots(updated);
      localStorage.setItem(
        characterName,
        JSON.stringify({
          isCaster: true,
          slots: updated,
        })
      );
    }
  };

  const decrement = () => {
    if (numUsed - 1 >= 0) {
      setNumUsed(numUsed - 1);
      const updated = spellSlots.map((slot, i) => {
        if (i === idx) {
          return { ...slot, num_used: slot.num_used - 1 };
        }
        return slot;
      });
      setSpellSlots(updated);
      localStorage.setItem(
        characterName,
        JSON.stringify({
          isCaster: true,
          slots: updated,
        })
      );
    }
  };

  const increaseTotalUses = () => {
    setTotalUses(totalUses + 1);
    const updated = spellSlots.map((slot, i) => {
      if (i === idx) {
        return { ...slot, num_uses: slot.num_uses + 1 };
      }
      return slot;
    });
    setSpellSlots(updated);
    localStorage.setItem(
      characterName,
      JSON.stringify({
        isCaster: true,
        slots: updated,
      })
    );
  };

  const decreaseTotalUses = () => {
    // Only allow decreasing down to one use
    if (totalUses - 1 > 0) {
      setTotalUses(totalUses - 1);
      const updated = spellSlots.map((slot, i) => {
        if (i === idx) {
          return { ...slot, num_uses: slot.num_uses - 1 };
        }
        return slot;
      });
      setSpellSlots(updated);
      localStorage.setItem(
        characterName,
        JSON.stringify({
          isCaster: true,
          slots: updated,
        })
      );
    }
  };

  const updateTitle = () => {
    const updated = spellSlots.map((slot, i) => {
      if (i === idx) {
        return { ...slot, title };
      }
      return slot;
    });
    setSpellSlots(updated);
    localStorage.setItem(
      characterName,
      JSON.stringify({
        isCaster: true,
        slots: updated,
      })
    );
  };

  const deleteSlot = () => {
    localStorage.setItem(
      characterName,
      JSON.stringify({
        isCaster: true,
        slots: spellSlots.filter((_, i) => i !== idx),
      })
    );
    setSpellSlots(spellSlots.filter((_, i) => i !== idx));
  };

  return (
    <div>
      <div id='counter-content' className='my-2 flex items-center'>
        <FloatingLabelInput
          value={title}
          readOnly={!isEditMode}
          onChange={(e) => setTitle(e.target.value)}
          // labelText='Ability'
          nameAndID={title}
          additionalInputClassNames='asset-title text-base mr-4'
        />
        <div id='counter-dots' className='flex'>
          {[...Array(totalUses)].map((_, i) => {
            return (
              // eslint-disable-next-line react/no-array-index-key
              <div key={i} className={`dot ${i < numUsed && 'filled'}`} />
            );
          })}
        </div>
        <div id='counter-controlls' className='ml-2 pt-1'>
          <button
            type='button'
            className='border-2 border-[#074f57] bg-[#074f57]'
            onClick={decrement}
          >
            <div className='m-0'>
              <FiMinus className='text-[#fcf5e5]' />
            </div>
          </button>
          <button
            type='button'
            className='mx-2 border-2 border-[#074f57] bg-[#074f57]'
            onClick={increment}
          >
            <FiPlus className='text-[#fcf5e5]' />
          </button>
          <button
            type='button'
            className='mx-2 border-2 border-[#074f57] bg-[#074f57]'
            onClick={() => setIsEditMode(!isEditMode)}
          >
            <FaPencil className='text-[#fcf5e5]' />
          </button>
        </div>
      </div>
      {isEditMode && (
        <div>
          <div>
            <div className='flex'>
              <button
                className='row-button mr-2 rounded-lg bg-green-700 px-4 py-2 text-white hover:bg-green-800 disabled:bg-gray-500'
                type='button'
                onClick={updateTitle}
              >
                Save Name
              </button>
              <button
                type='button'
                onClick={decreaseTotalUses}
                className='create-content-button row-button rounded-lg bg-gray-200 text-green-800'
              >
                - Uses
              </button>
              <button
                type='button'
                onClick={increaseTotalUses}
                className='create-content-button row-button rounded-lg bg-gray-200 text-green-800'
              >
                + Uses
              </button>
              <button
                type='button'
                onClick={deleteSlot}
                className='row-button delete-content rounded-lg bg-gray-200'
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

const SpellSlotParent = ({ characterName }) => {
  const [spellSlots, setSpellSlots] = useState();

  useEffect(
    () => () => {
      if (spellSlots) {
        localStorage.setItem(
          characterName,
          JSON.stringify({ isCaster: true, slots: spellSlots })
        );
      }
    },
    []
  );

  useEffect(() => {
    if (characterName) {
      const data = JSON.parse(localStorage.getItem(characterName));
      setSpellSlots(data?.slots);
    }
  }, []);

  const createNewSlot = () => {
    const newTitle = `Level ${spellSlots.length + 1}`;
    const newSlot = { title: newTitle, num_uses: 1, num_used: 0 };
    localStorage.setItem(
      characterName,
      JSON.stringify({
        isCaster: true,
        slots: [...spellSlots, newSlot],
      })
    );
    setSpellSlots([...spellSlots, newSlot]);
  };

  useEffect(() => {
    if (characterName) {
      if (localStorage.getItem(characterName)) {
        const data = JSON.parse(localStorage.getItem(characterName));
        setSpellSlots(data.slots);
      } else {
        localStorage.setItem(
          characterName,
          JSON.stringify({
            isCaster: true,
            slots: [{ title: 'Level 1', num_uses: 1, num_used: 0 }],
          })
        );
        setSpellSlots([{ title: 'Level 1', num_uses: 1, num_used: 0 }]);
      }
    }
  }, [characterName]);

  return (
    spellSlots && (
      <>
        {spellSlots.map((slot, i) => (
          <SpellSlotCounter
            key={slot.title}
            spellSlot={slot}
            idx={i}
            spellSlots={spellSlots}
            setSpellSlots={setSpellSlots}
            characterName={characterName}
          />
        ))}
        <button
          type='button'
          className='ml-auto mr-2 flex rounded-lg bg-green-700 px-4 py-2 text-white hover:bg-green-800 disabled:bg-gray-500'
          onClick={createNewSlot}
        >
          <FiPlusCircle className='mr-1 self-center' />
          Add New
        </button>
      </>
    )
  );
};

const CharacterAbilities = () => {
  const sesh = useSessionContext();
  const characterID = sesh.accessTokenPayload.In_Game.Character;
  const { data: characterDataFromDB } = useGetCharacterQuery(characterID, {});
  const characterName = characterDataFromDB?.character_name;
  const { data: abilities, isLoading: abilitiesLoading } =
    useGetAbilitiesQuery(characterID);
  const [isCreating, setIsCreating] = useState(false);

  const [createAbility] = useCreateAbilityMutation();

  const createNewAbility = async () => {
    setIsCreating(true);
    const newAbility = {
      title: 'New Ability',
      level: 0,
      description: '',
      num_uses: 1,
      num_used: 0,
    };
    const res = await createAbility({
      plID: characterID,
      player_id: characterID,
      ...newAbility,
    });
    if (res) {
      setIsCreating(false);
    }
  };

  useEffect(
    () => () => {
      console.log('abilities unmounted');
    },
    []
  );

  return (
    <div className='game-wrapper'>
      <div className='game-content min-h-screen w-full'>
        <div className='create-form selected-content-details mx-auto h-full max-w-[95rem] p-4'>
          <div className='border-image-container min-h-[80vh]'>
            <div className='flex p-4'>
              <div className='flex-1' id='left'>
                <h2 className='text-center'>Abilities</h2>
                {!abilitiesLoading &&
                  abilities.map((ability) => (
                    <AbilityCounter key={ability.id} ability={ability} />
                  ))}
                <button
                  type='button'
                  className='ml-auto mr-2 flex rounded-lg bg-green-700 px-4 py-2 text-white hover:bg-green-800 disabled:bg-gray-500'
                  onClick={createNewAbility}
                >
                  <FiPlusCircle className='mr-1 self-center' />
                  {isCreating ? 'Creating ...' : 'Add New'}
                </button>
              </div>
              <div className='flex-1 border-l-2 border-black pl-4' id='right'>
                <h2 className='text-center'>Spell Slots</h2>
                <SpellSlotParent characterName={characterName} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CharacterAbilities;
