import React, { useEffect, useState } from 'react';

import { FiAlertTriangle, FiCheckCircle, FiX } from 'react-icons/fi';
import { Modal, Button, Tooltip } from 'flowbite-react';
import { useDispatch } from 'react-redux';
import {
  useDeletePartyMutation,
  useGetPartyCharsQuery,
  useGetPartyQuery,
  useUpdatePartyMutation,
} from '../../redux/api-slices/partyAPI';
import {
  useGiveInspoMutation,
  useRemovePlayerMutation,
} from '../../redux/api-slices/characterAPI';
import {
  displayAlert,
  setAlertInfo,
} from '../../redux/state-slices/alertSlice';
import { DeleteFail, UpdateFail } from '../alerts/ResponseAlerts';
import { spacesDelete, spacesUpload } from '../../digitalocean/spaces';
import { DetailMD } from '../archive/misc-components/MiscDetailComps';
import LoadingSpinner from '../loading-ani/LoadingSpinner';
import DgnFileInput from '../archive/misc-components/DgnFileInput';
import SizeChecker from '../archive/misc-components/SizeChecker';
import FloatingLabelInput from '../archive/misc-components/FloatingLabelInput';
import SubmitLoader from '../loading-ani/SubmitLoader';
import './gameSettings.css';

export default function PartySettings({ partyID, gameID, username, navigate }) {
  const dispatch = useDispatch();
  const [updatePartyFunc] = useUpdatePartyMutation();
  const [deleteParty] = useDeletePartyMutation();
  const [skip, setSkip] = useState(false);
  const { data: partyData } = useGetPartyQuery(partyID, { skip });
  const { data: partyChars, isLoading: CharsLoading } =
    useGetPartyCharsQuery(partyID);
  const [partyUpdateForm, setPartyUpdateForm] = useState({
    party_name: '',
    wealth: 0,
    img_url: '',
    is_open: false,
  });

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState('Updating');
  const [openModal, setOpenModal] = useState(undefined);
  const [openPartyModal, setOpenPartyModal] = useState(undefined);

  const [imgFile, setImgFile] = useState(null);
  const [imgSize, setImgSize] = useState(null);
  const [oldImgURL, setOldImgURL] = useState('');

  useEffect(() => {
    if (partyData) {
      setPartyUpdateForm({
        party_name: partyData.party_name,
        wealth: partyData.wealth,
        img_url: partyData.img_url,
        is_open: partyData.is_open,
      });
      setOldImgURL(partyData.img_url);
    }
  }, [partyData]);

  useEffect(() => {
    if (imgFile && imgFile.size) {
      const mb = imgFile.size / 1024 / 1024;
      setImgSize(mb.toFixed(2));
    }
  }, [imgFile]);

  const handleChange = async (e) => {
    setPartyUpdateForm({
      ...partyUpdateForm,
      [e.target.name]: e.target.value,
    });
  };

  const handleCheckboxChange = async (e) => {
    setPartyUpdateForm({
      ...partyUpdateForm,
      [e.target.name]:
        e.target.type === 'checkbox' ? e.target.checked : e.target.value,
    });
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setLoadingMessage('Updating party');
    const data = { ...partyUpdateForm, id: partyID };

    try {
      if (imgFile) {
        if (oldImgURL !== 'https://fakeimg.pl/560x400?text=Party+Image') {
          await spacesDelete(oldImgURL, username);
        }

        const imgResponse = await spacesUpload(
          imgFile,
          'GameAssets/PartyImage',
          username
        );
        if (imgResponse) {
          data.img_url = imgResponse;
          setOldImgURL(imgResponse);
        }
      }
    } catch (err) {
      dispatch(
        setAlertInfo({
          alertType: 'failure',
          message: 'There was an issue uploading this image',
        })
      );
      dispatch(displayAlert());
    }

    try {
      const partyUpdateRes = await updatePartyFunc(data);
      if (partyUpdateRes && 'data' in partyUpdateRes) {
        dispatch(
          setAlertInfo({ alertType: 'success', message: 'Party updated' })
        );
        dispatch(displayAlert());
      } else if (
        partyUpdateRes &&
        ('error' in partyUpdateRes || 'detail' in partyUpdateRes)
      ) {
        dispatch(setAlertInfo(UpdateFail));
        dispatch(displayAlert());
      }
    } catch (err) {
      dispatch(setAlertInfo(UpdateFail));
      dispatch(displayAlert());
    }
    setIsSubmitting(false);
  };

  const imgDeleter = async (url, plUsername) => {
    await spacesDelete(url, plUsername);
  };

  const handleDeleteParty = async () => {
    setSkip(true);
    setOpenPartyModal(undefined);
    setIsSubmitting(true);
    setLoadingMessage('Deleting party');

    const playerUsernamesAndIMGs =
      partyChars &&
      partyChars.map((char) => ({
        username: char.username,
        img_url: char.img_url,
      }));

    try {
      playerUsernamesAndIMGs.forEach(
        async (charData) =>
          charData.img_url && imgDeleter(charData.img_url, charData.username)
      );

      const partyDelRes = await deleteParty(partyID);
      if ('data' in partyDelRes && partyDelRes.data) {
        dispatch(
          setAlertInfo({
            alertType: 'success',
            message: `${partyData?.party_name} deleted`,
          })
        );
        dispatch(displayAlert());
      } else if (
        ('data' in partyDelRes && partyDelRes.data === false) ||
        'detail' in partyDelRes ||
        'error' in partyDelRes
      ) {
        dispatch(setAlertInfo(DeleteFail));
        dispatch(displayAlert());
      }
    } catch (err) {
      dispatch(setAlertInfo(DeleteFail));
      dispatch(displayAlert());
    }

    setIsSubmitting(false);
    navigate('/');
  };

  return (
    <>
      {isSubmitting && <SubmitLoader message={loadingMessage} />}
      <div className='game-settings-content-wrapper mx-auto h-full min-h-screen pt-8'>
        <DeletePartyModal
          openModal={openPartyModal}
          setOpenModal={setOpenPartyModal}
          obj={partyData}
          deleteFunc={handleDeleteParty}
        />
        <form
          action='submit'
          onSubmit={handleUpdate}
          className='game-settings-form relative mx-auto my-2 max-w-[75rem] p-3'
        >
          <div className='border-image-container p-4'>
            <div className='mr-4 mt-8 flex h-full w-full flex-col items-center'>
              <FloatingLabelInput
                onChange={handleChange}
                value={partyUpdateForm?.party_name}
                labelText='Party name'
                nameAndID='party_name'
                additionalInputClassNames='asset-title mx-auto'
                maxLength={60}
              />

              <div className='my-4 flex justify-center'>
                <label
                  htmlFor='is_open'
                  className='m-2 flex hover:cursor-pointer'
                >
                  {partyUpdateForm.is_open ? (
                    <span className='flex items-center text-green-600'>
                      <FiCheckCircle className='mr-2 text-green-600' />
                      Party is open
                    </span>
                  ) : (
                    <span className='flex items-center text-violet-600'>
                      <FiX className='mr-2 inline text-violet-600 opacity-100' />
                      Party is closed
                    </span>
                  )}
                  <input
                    className='ml-3'
                    onChange={handleCheckboxChange}
                    checked={partyUpdateForm.is_open || false}
                    value={partyUpdateForm.is_open || false}
                    type='checkbox'
                    name='is_open'
                    id='is_open'
                  />
                </label>

                <FloatingLabelInput
                  onChange={handleChange}
                  value={partyUpdateForm?.wealth}
                  labelText='Wealth'
                  nameAndID='wealth'
                  type='number'
                  additionalInputClassNames='asset-title pt-2 px-4 max-w-[6rem]'
                />
              </div>

              <PartyIMG
                partyUpdateForm={partyUpdateForm}
                setPartyUpdateForm={setPartyUpdateForm}
                imgSize={imgSize}
                setImgSize={setImgSize}
                setImgFile={setImgFile}
                oldImgURL={oldImgURL}
              />

              <div className='flex gap-3'>
                {imgSize === null || imgSize <= 2 ? (
                  <button
                    type='submit'
                    className='submit-button mx-auto mb-5 rounded-lg bg-green-700 px-8 py-2 text-white'
                    disabled={isSubmitting}
                  >
                    Update party
                  </button>
                ) : (
                  <Tooltip
                    // eslint-disable-next-line react/style-prop-object
                    style='light'
                    content='Your image is too large, consider compressing it'
                  >
                    <button
                      className='submit-button mx-auto mb-5 cursor-not-allowed rounded-lg bg-green-700 px-8 py-2 text-white'
                      type='submit'
                      disabled
                    >
                      <span className=''>Update party</span>
                    </button>
                  </Tooltip>
                )}
                <button
                  type='button'
                  className='delete-precious-resource-button mx-auto mb-5 rounded-lg bg-red-700 px-8 py-2 text-white'
                  onClick={() => setOpenPartyModal('pop-up')}
                >
                  Delete party
                </button>
              </div>
            </div>
          </div>
        </form>

        <PartyPlayersSettings
          chars={partyChars}
          isLoading={CharsLoading}
          gameID={gameID}
          openModal={openModal}
          setOpenModal={setOpenModal}
          dispatch={dispatch}
          setIsSubmitting={setIsSubmitting}
          setLoadingMessage={setLoadingMessage}
        />
      </div>
    </>
  );
}

const PartyIMG = ({
  partyUpdateForm,
  setPartyUpdateForm,
  imgSize,
  setImgSize,
  setImgFile,
  oldImgURL,
}) => {
  const handleImgChange = (e) => {
    const { files } = e.target;
    if (files && files.length) {
      const imgObj = files[0];
      setImgFile(imgObj);
      setPartyUpdateForm({
        ...partyUpdateForm,
        img_url: URL.createObjectURL(imgObj),
      });
    }
  };

  return (
    <div className='mt-4'>
      <div className='img-container'>
        <img src={partyUpdateForm?.img_url} alt='' className='img-actual' />
      </div>
      {imgSize && (
        <div className='flex justify-center'>
          <button
            className='revert-img-button mt-2 rounded-lg bg-red-100 px-4 py-2'
            type='button'
            onClick={() => {
              setPartyUpdateForm({
                ...partyUpdateForm,
                img_url: oldImgURL,
              });
              setImgSize(null);
              setImgFile(null);
            }}
          >
            Clear image change
          </button>
        </div>
      )}

      <div className='m-4 flex justify-center p-2'>
        {imgSize ? <SizeChecker imgSize={imgSize} /> : null}

        <DgnFileInput imageChangeHandler={handleImgChange} />
      </div>
    </div>
  );
};

const PartyPlayersSettings = ({
  chars,
  isLoading,
  gameID,
  openModal,
  setOpenModal,
  dispatch,
  setIsSubmitting,
  setLoadingMessage,
}) => {
  const [deletePlayer] = useRemovePlayerMutation();
  const [isRemoving, setIsRemoving] = useState(false);
  const [selectedChar, setSelectedChar] = useState(null);

  const handleDeletePlayer = async () => {
    setOpenModal(undefined);
    setIsSubmitting(true);
    setLoadingMessage('Removing player');
    const playerUsername = selectedChar?.username;
    const playerIMG = selectedChar?.img_url;
    const charID = selectedChar?.id;
    const charName = selectedChar?.character_name;

    try {
      if (playerIMG) {
        await spacesDelete(playerIMG, playerUsername);
      }
      const playerDelRes = await deletePlayer({ gID: gameID, plID: charID });
      if ('data' in playerDelRes && playerDelRes.data) {
        dispatch(
          setAlertInfo({
            alertType: 'success',
            message: `${charName} deleted and removed from game`,
          })
        );
        dispatch(displayAlert());
      } else if (
        ('data' in playerDelRes && playerDelRes.data === false) ||
        'detail' in playerDelRes ||
        'error' in playerDelRes
      ) {
        dispatch(setAlertInfo(DeleteFail));
        dispatch(displayAlert());
      }
    } catch (err) {
      dispatch(setAlertInfo(DeleteFail));
      dispatch(displayAlert());
    }
    setSelectedChar(null);
    setIsSubmitting(false);
  };

  return (
    <div className='game-settings-form relative mx-auto my-2 max-w-[75rem] p-3'>
      <DeletePlayerModal
        obj={selectedChar}
        openModal={openModal}
        setOpenModal={setOpenModal}
        deleteFunc={handleDeletePlayer}
      />
      <div className='border-image-container p-4'>
        {isLoading ? (
          <div className='flex justify-center'>
            <LoadingSpinner classStyle='loading-ani-red' />
          </div>
        ) : (
          <>
            <div className='my-3 flex justify-center'>
              <button
                type='button'
                className='precious-resource-button rounded-lg bg-red-700 px-4 py-2 text-white'
                onClick={() => setIsRemoving(!isRemoving)}
              >
                {isRemoving ? 'Exit Removal' : 'Remove a player'}
              </button>
            </div>

            <ul className='flex h-full flex-wrap justify-evenly gap-4 p-2'>
              {chars && chars.length > 0 ? (
                chars.map((char) => (
                  <li
                    key={char?.id}
                    className='list-chars flex min-w-[10rem] flex-col items-center gap-y-3 rounded-md bg-gray-300'
                  >
                    <span className='my-2 font-semibold text-[#420000]'>
                      {char.character_name}
                    </span>

                    <PlayerActionButtons
                      isRemoving={isRemoving}
                      hasInspo={char && char.inspiration}
                      selectedChar={selectedChar}
                      setSelectedChar={setSelectedChar}
                      char={char}
                      gameID={gameID}
                      openModal={openModal}
                      setOpenModal={setOpenModal}
                    />
                  </li>
                ))
              ) : (
                <li className='my-3 rounded-lg bg-[#420000] px-4 py-2 text-[#fcf5e5]'>
                  No characters in this party
                </li>
              )}
            </ul>

            {selectedChar && (
              <>
                <hr className='details-seperating-bar mx-4 my-2 h-0' />
                <h3 className='flex justify-center font-semibold'>
                  Backstory: {selectedChar.character_name}
                </h3>
                <div className='m-4'>
                  <DetailMD details={selectedChar.backstory} />
                </div>
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
};

const PlayerActionButtons = ({
  isRemoving,
  hasInspo,
  char,
  selectedChar,
  setSelectedChar,
  gameID,
  setOpenModal,
}) => {
  const [giveInspo, { isLoading }] = useGiveInspoMutation();

  const handleInspo = async () => {
    const data = {
      gID: gameID,
      plID: char.id,
      player_id: char.id,
      inspo_action: true,
    };

    await giveInspo(data);
  };

  return (
    <>
      <button
        type='button'
        className='precious-resource-button rounded-md bg-[#074f57] px-3 py-1 text-white disabled:cursor-not-allowed disabled:bg-[#003138]'
        onClick={() => setSelectedChar(char)}
        disabled={selectedChar && selectedChar?.id === char.id}
      >
        {selectedChar && selectedChar?.id === char.id
          ? 'Viewing backstory'
          : 'View backstory'}
      </button>

      {isLoading ? (
        <LoadingSpinner classStyle='loading-ani-red' />
      ) : (
        <button
          type='button'
          className={
            hasInspo
              ? `mb-2 rounded-md bg-gray-500 px-3 py-1 text-white hover:cursor-not-allowed`
              : `precious-resource-button mb-2 rounded-md bg-[#074f57] px-3 py-1 text-white`
          }
          disabled={hasInspo}
          onClick={handleInspo}
        >
          {hasInspo ? 'Has inspiration' : 'Give inspiration'}
        </button>
      )}

      {isRemoving && (
        <button
          type='button'
          className='delete-precious-resource-button mb-1 mt-3 rounded-md bg-red-700 px-3 py-1 text-white'
          onClick={() => {
            setSelectedChar(char);
            setOpenModal('pop-up');
          }}
        >
          Remove from game
        </button>
      )}
    </>
  );
};

const modalTheme = {
  root: {
    show: {
      on: 'flex bg-white bg-opacity-40',
    },
  },

  content: {
    inner: 'border-red-600 border-2 rounded-lg bg-white',
  },
};

const DeletePlayerModal = ({ openModal, setOpenModal, obj, deleteFunc }) => {
  return (
    <Modal
      theme={modalTheme}
      show={openModal === 'pop-up'}
      size='md'
      popup
      onClose={() => setOpenModal(undefined)}
    >
      <Modal.Header />
      <Modal.Body>
        <div className='text-center'>
          <FiAlertTriangle className='mx-auto mb-4 h-14 w-14 text-red-600' />
          <h3 className='mb-5 text-lg font-normal text-gray-500'>
            Are you sure you want to remove{' '}
            <span className='font-semibold text-red-400'>
              {obj?.character_name}
            </span>{' '}
            from your game? This will{' '}
            <span className='font-semibold text-red-400'>permanently</span> and{' '}
            <span className='font-semibold text-red-400'>irreversibly</span>{' '}
            delete their character.
          </h3>
          <div className='flex justify-center gap-4'>
            <Button color='failure' onClick={deleteFunc}>
              Yes, I am sure
            </Button>
            <Button color='warning' onClick={() => setOpenModal(undefined)}>
              No, cancel
            </Button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

const DeletePartyModal = ({ openModal, setOpenModal, obj, deleteFunc }) => {
  return (
    <Modal
      theme={modalTheme}
      show={openModal === 'pop-up'}
      size='md'
      popup
      onClose={() => setOpenModal(undefined)}
    >
      <Modal.Header />
      <Modal.Body>
        <div className='text-center'>
          <FiAlertTriangle className='mx-auto mb-4 h-14 w-14 text-red-600' />
          <h3 className='mb-5 text-lg font-normal text-gray-500'>
            Are you sure you want to remove{' '}
            <span className='font-semibold text-red-400'>
              {obj?.party_name}
            </span>{' '}
            from your game? This will{' '}
            <span className='font-semibold text-red-400'>permanently</span> and{' '}
            <span className='font-semibold text-red-400'>irreversibly</span>{' '}
            delete all characters, sessions and associated data. <br /> A new,
            blank, open party will be created upon deletion.
          </h3>
          <div className='flex justify-center gap-4'>
            <Button color='failure' onClick={deleteFunc}>
              Yes, I am sure
            </Button>
            <Button color='warning' onClick={() => setOpenModal(undefined)}>
              No, cancel
            </Button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};
