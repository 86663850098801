/* eslint-disable import/prefer-default-export */
import { S3, Endpoint } from 'aws-sdk';

const awsEndpointObj = new Endpoint('nyc3.digitaloceanspaces.com');

const s3Client = new S3({
  endpoint: awsEndpointObj,
  accessKeyId: process.env.REACT_APP_SPACES_ACCESS,
  secretAccessKey: process.env.REACT_APP_SPACES_SECRET,
});

export { s3Client };
