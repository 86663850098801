import React, { useState } from 'react';

import { useSelector } from 'react-redux';
import { selectCurrentUser } from '../../redux/auth/authSlice';

import './landingPage.css';

import Carousel from './carousel/Carousel';
import LoadingSpinner from '../loading-ani/LoadingSpinner';
import LandingContent from './content/LandingContent';
import Stepper from './search-bar/Stepper';

function LandingPage() {
  const user = useSelector(selectCurrentUser);

  const wrapperClass = {
    bg:
      user.signedIn === null || user.signedIn === false
        ? 'bg-null h-full w-full relative'
        : 'landing-wrapper h-full w-full relative',

    content:
      user.signedIn === null || user.signedIn === false
        ? 'relative mx-auto flex min-h-screen flex-col'
        : 'relative mx-auto flex min-h-screen max-w-[95rem] flex-col py-2',
  };

  return (
    <div className={wrapperClass.bg}>
      <div className={wrapperClass.content}>
        {user.signedIn ? (
          <>
            <TopImg />
            <SignedInContent />
          </>
        ) : user.signedIn === null ? (
          <NullContent />
        ) : (
          <SignedOutContent />
        )}
      </div>
    </div>
  );
}

const TopImg = () => {
  return (
    <div className='landing-above relative w-full'>
      <img
        className='landing-img mx-auto h-auto w-full rounded-t-lg'
        src={require('./assets/dgnDrawing.jpg')}
        alt='test'
      />
    </div>
  );
};

const SignedOutContent = () => {
  return (
    <div className='landing-container flex flex-col'>
      <LandingContent />
    </div>
  );
};

const SignedInContent = () => {
  const [carouselState, setCarouselState] = useState('player');
  const [activeStep, setActiveStep] = useState(1);

  return (
    <div className='char-select relative rounded-b-lg pb-3'>
      <Stepper activeStep={activeStep} carouselState={carouselState} />
      <Carousel
        activeStep={activeStep}
        setActiveStep={setActiveStep}
        carouselState={carouselState}
        setCarouselState={setCarouselState}
      />
    </div>
  );
};

const NullContent = () => {
  return (
    <div className='flex min-h-screen items-center justify-center rounded-b-lg'>
      <LoadingSpinner classStyle='loading-ani-red' />
    </div>
  );
};

export default LandingPage;
