import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { FiEdit, FiPlusCircle, FiTrash } from 'react-icons/fi';
import { Label, Select } from 'flowbite-react';

import {
  useCreateJournalEntryMutation,
  useDeleteJournalEntryMutation,
  useGetJournalEntriesQuery,
  useUpdateJournalEntryMutation,
} from '../../redux/api-slices/journalAPI';
import { useGetSessionsQuery } from '../../redux/api-slices/sessionAPI';
import { DetailMD, EditorMD } from '../archive/misc-components/MiscDetailComps';
import FloatingLabelInput from '../archive/misc-components/FloatingLabelInput';
import LoadingSpinner from '../loading-ani/LoadingSpinner';
import '../game/gameSettings.css';
import {
  displayAlert,
  setAlertInfo,
} from '../../redux/state-slices/alertSlice';
import { CreateFail, DeleteFail, UpdateFail } from '../alerts/ResponseAlerts';
import SubmitLoader from '../loading-ani/SubmitLoader';

export default function Journal({ charID, gameID }) {
  const dispatch = useDispatch();
  const [createEntry] = useCreateJournalEntryMutation();
  const [updateEntry] = useUpdateJournalEntryMutation();
  const [deleteEntry] = useDeleteJournalEntryMutation();
  const { data: entries, isLoading: entriesLoading } =
    useGetJournalEntriesQuery(charID);
  const { data: sessions, isLoading: sessionsLoading } =
    useGetSessionsQuery(gameID);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState('Submitting');
  const [activeOption, setActiveOption] = useState('create');
  const [selectedEntry, setSelectedEntry] = useState(null);

  return (
    <div className='game-settings-main-wrapper mx-auto min-h-screen w-full max-w-[95rem]'>
      {isSubmitting && <SubmitLoader message={loadingMessage} />}
      <div className='game-settings-content-wrapper flex h-full w-full flex-col'>
        <ButtonSelectors
          selectedEntry={selectedEntry}
          setActiveOption={setActiveOption}
          deleteFunc={deleteEntry}
          setSelectedEntry={setSelectedEntry}
          dispatch={dispatch}
          setLoadingMessage={setLoadingMessage}
          setIsSubmitting={setIsSubmitting}
        />

        <JournalDisplay
          createFunc={createEntry}
          updateFunc={updateEntry}
          entries={entries}
          activeOption={activeOption}
          selectedEntry={selectedEntry}
          setSelectedEntry={setSelectedEntry}
          charID={charID}
          sessions={sessions}
          seshLoading={sessionsLoading}
          dispatch={dispatch}
          setIsSubmitting={setIsSubmitting}
          setLoadingMessage={setLoadingMessage}
          entriesLoading={entriesLoading}
          setActiveOption={setActiveOption}
        />
      </div>
    </div>
  );
}

const ButtonSelectors = ({
  selectedEntry,
  setActiveOption,
  deleteFunc,
  setSelectedEntry,
  dispatch,
  setLoadingMessage,
  setIsSubmitting,
}) => {
  const failedDeleteAlert = () => {
    dispatch(setAlertInfo(DeleteFail));
    dispatch(displayAlert());
  };

  const handleDelete = async () => {
    setLoadingMessage('Deleting');
    setIsSubmitting(true);

    try {
      const journalDelRes = await deleteFunc(selectedEntry.id);
      if ('data' in journalDelRes && journalDelRes.data) {
        setActiveOption('create');
        setSelectedEntry(null);
        dispatch(
          setAlertInfo({
            alertType: 'success',
            message: 'Entry deleted',
          })
        );
        dispatch(displayAlert());
      } else if (
        ('data' in journalDelRes && journalDelRes.data === false) ||
        'detail' in journalDelRes ||
        'error' in journalDelRes
      ) {
        failedDeleteAlert();
      }
    } catch (e) {
      failedDeleteAlert();
    }

    setIsSubmitting(false);
  };

  return (
    <div className='flex justify-center gap-4'>
      <button
        type='button'
        className='create-content-button row-button rounded-lg bg-gray-200 text-green-800'
        onClick={() => {
          setActiveOption('create');
          setSelectedEntry(null);
        }}
      >
        <FiPlusCircle className='list-icon mr-1 inline' />
        Create
      </button>
      <button
        type='button'
        className='row-button edit-content rounded-lg bg-gray-200 disabled:cursor-not-allowed disabled:bg-gray-500 disabled:text-yellow-100'
        disabled={!selectedEntry || selectedEntry === null}
        onClick={() => setActiveOption('edit')}
      >
        <FiEdit className='list-icon mr-1 inline' />
        Edit
      </button>
      <button
        type='button'
        className='row-button delete-content rounded-lg bg-gray-200 disabled:cursor-not-allowed disabled:bg-gray-500 disabled:text-red-100'
        disabled={!selectedEntry || selectedEntry === null}
        onClick={handleDelete}
      >
        <FiTrash className='list-icon mr-1 inline' />
        Delete
      </button>
    </div>
  );
};

const JournalDisplay = ({
  createFunc,
  updateFunc,
  entries,
  activeOption,
  selectedEntry,
  setSelectedEntry,
  charID,
  sessions,
  seshLoading,
  dispatch,
  setIsSubmitting,
  setLoadingMessage,
  entriesLoading,
  setActiveOption,
}) => {
  const toDisplay = {
    view: <ViewEntry entry={selectedEntry} />,
    create: (
      <CreateEntry
        createFunc={createFunc}
        charID={charID}
        sessions={sessions}
        seshLoading={seshLoading}
        dispatch={dispatch}
        setIsSubmitting={setIsSubmitting}
        setLoadingMessage={setLoadingMessage}
      />
    ),
    edit: (
      <EditEntry
        entry={selectedEntry}
        updateFunc={updateFunc}
        charID={charID}
        sessions={sessions}
        setIsSubmitting={setIsSubmitting}
        setLoadingMessage={setLoadingMessage}
        dispatch={dispatch}
        setSelectedEntry={setSelectedEntry}
      />
    ),
  };

  return (
    <div className='game-settings-form m-4 mx-auto grid min-h-screen w-full max-w-[95rem] p-3'>
      <div className='border-image-container h-full w-full p-4'>
        <TableOfContents
          entries={entries}
          setSelectedEntry={setSelectedEntry}
          isLoading={entriesLoading}
          setActiveOption={setActiveOption}
        />

        {toDisplay[activeOption]}
      </div>
    </div>
  );
};

const TableOfContents = ({
  entries,
  setSelectedEntry,
  isLoading,
  setActiveOption,
}) => {
  return (
    <div className='relative mb-4 h-[15rem] overflow-y-auto overflow-x-hidden border-b border-b-gray-400'>
      {isLoading ? (
        <LoadingSpinner classStyle='loading-ani-red' />
      ) : entries && entries.length > 0 ? (
        <ul className='flex h-full flex-wrap gap-3 p-3'>
          {entries.map((entry) => (
            <li key={entry?.id} className='flex flex-col items-center'>
              <button
                type='button'
                className='entry-button rounded-md bg-[#074f57] px-3 py-1 text-white hover:scale-[1.03] active:scale-[.98]'
                onClick={() => {
                  setActiveOption('view');
                  setSelectedEntry(entry);
                }}
              >
                {entry.title}
                <p>{entry.session_date}</p>
              </button>
            </li>
          ))}
        </ul>
      ) : (
        <NoEntriesToDisplay />
      )}
    </div>
  );
};

const NoEntriesToDisplay = () => (
  <div className='absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 rounded-md bg-[#420000] px-4 py-2 text-[#fcf5e5]'>
    you have no entries to display
  </div>
);

const ViewEntry = ({ entry }) => {
  const { session_title: sessionTitle, session_date: date } = entry;
  const entryWasLoggedOn = sessionTitle ? (
    <h3 className='mb-2'>
      This entry was logged during:{' '}
      <span className='text-violet-600'>{sessionTitle}</span> on{' '}
      <span className='text-violet-600'>{date}</span>
    </h3>
  ) : null;

  return (
    <div className='mb-8 flex flex-col items-center gap-y-4 p-3'>
      <h2 className='asset-title mb-2'>{entry.title}</h2>
      {entryWasLoggedOn}

      <DetailMD details={entry?.details} />
    </div>
  );
};

const CreateEntry = ({
  createFunc,
  charID,
  sessions,
  seshLoading,
  dispatch,
  setIsSubmitting,
  setLoadingMessage,
}) => {
  const [journalForm, setJournalForm] = useState({
    player_id: charID,
    session_id: '',
    title: '',
  });
  const [desc, setDesc] = useState('');

  const handleChange = async (e) => {
    setJournalForm({ ...journalForm, [e.target.name]: e.target.value });
  };

  const failedPostAlert = () => {
    dispatch(setAlertInfo(CreateFail));
    dispatch(displayAlert());
  };

  const handleCreate = async (e) => {
    e.preventDefault();
    setLoadingMessage('Logging entry');
    setIsSubmitting(true);
    const data = { ...journalForm, details: desc, plID: charID };
    if (
      data.session_id === '' ||
      data.session_id === 'false' ||
      data.session_id === '--- No Session ---'
    ) {
      data.session_id = null;
    }

    try {
      const journalPostRes = await createFunc(data);

      if ('data' in journalPostRes && journalPostRes.data) {
        dispatch(
          setAlertInfo({
            alertType: 'success',
            message: 'Entry logged',
          })
        );
        dispatch(displayAlert());
      } else if (
        ('data' in journalPostRes && journalPostRes.data === false) ||
        'detail' in journalPostRes ||
        'error' in journalPostRes
      ) {
        failedPostAlert();
      }
    } catch (err) {
      failedPostAlert();
    }

    setIsSubmitting(false);
  };

  return (
    <form action='submit' onSubmit={handleCreate} className=''>
      <div className='mb-8 flex flex-col items-center gap-y-4'>
        <FloatingLabelInput
          onChange={handleChange}
          labelText='Title'
          nameAndID='title'
          additionalInputClassNames='asset-title'
        />

        {seshLoading ? (
          <LoadingSpinner classStyle='loading-ani-red' />
        ) : (
          <Label color='black' className='mr-4' htmlFor='session_id'>
            Session
            <Select
              name='session_id'
              className='flowbite-select'
              id='session_id'
              onChange={handleChange}
              defaultValue={null}
            >
              <option value={false}>--- No Session ---</option>
              {sessions?.map((sesh) => (
                <option key={sesh.id} value={sesh.id}>
                  {sesh.title}
                </option>
              ))}
            </Select>
          </Label>
        )}
      </div>

      <EditorMD mdText={desc} setMdText={setDesc} height='50vh' />

      <div className='my-4 flex justify-center'>
        <button
          type='submit'
          className='submit-button my-4 rounded-lg bg-green-700 px-8 py-2 text-white'
        >
          Submit
        </button>
      </div>
    </form>
  );
};

const EditEntry = ({
  entry,
  updateFunc,
  charID,
  sessions,
  setIsSubmitting,
  setLoadingMessage,
  dispatch,
  setSelectedEntry,
}) => {
  const [desc, setDesc] = useState('');
  const [updateJournalForm, setUpdateJournalForm] = useState({
    player_id: charID,
    session_id: '',
    title: '',
  });

  const defaultSessionVal = entry?.session_id ? entry.session_id : null;

  useEffect(() => {
    setUpdateJournalForm({
      ...updateJournalForm,
      session_id: entry?.session_id,
      title: entry.title,
    });
    setDesc(entry.details);
  }, [entry]);

  const handleChange = async (e) => {
    setUpdateJournalForm({
      ...updateJournalForm,
      [e.target.name]: e.target.value,
    });
  };

  const failedUpdateAlert = () => {
    dispatch(setAlertInfo(UpdateFail));
    dispatch(displayAlert());
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setLoadingMessage('Updating');

    const data = { ...updateJournalForm, id: entry.id, details: desc };
    if (
      data.session_id === '' ||
      data.session_id === 'false' ||
      data.session_id === '--- No Session ---'
    ) {
      data.session_id = null;
    }

    try {
      const updateRes = await updateFunc(data);
      if ('data' in updateRes && updateRes.data) {
        setSelectedEntry(data);
        dispatch(
          setAlertInfo({
            alertType: 'success',
            message: 'Entry updated',
          })
        );
        dispatch(displayAlert());
      } else if (
        ('data' in updateRes && updateRes.data === false) ||
        'detail' in updateRes ||
        'error' in updateRes
      ) {
        failedUpdateAlert();
      }
    } catch (err) {
      failedUpdateAlert();
    }

    setIsSubmitting(false);
  };

  return (
    <form action='submit' onSubmit={handleUpdate} className=''>
      <div className='mb-8 flex flex-col items-center gap-y-4'>
        <FloatingLabelInput
          onChange={handleChange}
          labelText='Title'
          nameAndID='title'
          additionalInputClassNames='asset-title'
          value={updateJournalForm?.title}
        />

        <Label color='black' className='mr-4' htmlFor='session_id'>
          Session
          <Select
            name='session_id'
            className='flowbite-select'
            id='session_id'
            onChange={handleChange}
            defaultValue={defaultSessionVal}
          >
            <option value={false}>--- No Session ---</option>
            {sessions?.map((sesh) => (
              <option key={sesh.id} value={sesh.id}>
                {sesh.title}
              </option>
            ))}
          </Select>
        </Label>
      </div>

      <EditorMD mdText={desc} setMdText={setDesc} height='50vh' />

      <div className='my-4 flex justify-center'>
        <button
          type='submit'
          className='submit-button my-4 rounded-lg bg-green-700 px-8 py-2 text-white'
        >
          Update
        </button>
      </div>
    </form>
  );
};
