import React, { useState } from 'react';
import { useSelector } from 'react-redux';

// Components
import {
  FactionHeader,
  GMDetailHeader,
  GMFactionHeader,
  GMLocationHeader,
  LocationHeader,
  ObjDetailImg,
  RelatedInfo,
  DetailMD,
} from '../../misc-components/MiscDetailComps';
import BackAndFullscreen from '../../misc-components/BackAndFullscreen';

// Redux
import { selectOneNpc } from '../../../../redux/state-slices/npcSlice';

// Styles
import '../selected.css';

export default function NpcDetails({ setNpcDetails, role }) {
  const currentNpc = useSelector(selectOneNpc);

  return (
    <div className='selected-content-details-wrap'>
      <BackAndFullscreen setStateFunc={setNpcDetails} />

      <NpcInfo currentNpc={currentNpc} role={role} />
    </div>
  );
}

export const NpcInfo = ({ currentNpc, role }) => {
  return (
    <div className='selected-content-details'>
      <div className='border-image-container'>
        <DetailsInfo currentNpc={currentNpc} role={role} />
      </div>
    </div>
  );
};

const DetailsInfo = ({ currentNpc, role }) => {
  const [isActive, setIsActive] = useState(true);

  return (
    // h-[57.2rem]
    <div className='asset-info-main relative'>
      <TopRow currentNpc={currentNpc} />

      <div className='info-description'>
        <div className='mt-0 h-[40rem] w-full'>
          <ObjDetailImg currentObj={currentNpc} />
        </div>
        <hr className='details-seperating-bar mx-4 my-2 h-0' />

        <div className='details-chunk'>
          <DetailMD details={currentNpc.details} />
        </div>

        {role === 'GM' ? (
          <>
            <GMDetailHeader isActive={isActive} setIsActive={setIsActive} />

            {currentNpc.hidden_details ? (
              <div
                className={`${
                  isActive ? 'max-h-full' : 'invisible max-h-0'
                } details-chunk overflow-hidden transition-all duration-500 ease-in-out`}
              >
                <DetailMD details={currentNpc.hidden_details} />
              </div>
            ) : (
              <p
                className={`${
                  isActive ? 'max-h-full' : 'invisible max-h-0'
                } details-chunk overflow-hidden transition-all duration-500 ease-in-out`}
              >
                No hidden notes to show.
              </p>
            )}
          </>
        ) : null}

        <MiscInfo currentNpc={currentNpc} role={role} />
      </div>
    </div>
  );
};

const TopRow = ({ currentNpc }) => {
  const hasDataClass = {
    fac: currentNpc.faction ? 'ml-1 mr-3 text-violet-600' : 'ml-1 mr-3',
    loc: currentNpc.location ? 'ml-1 mr-3 text-violet-600' : 'ml-1 mr-3',
  };

  return (
    <>
      <div className='info-top-row mt-2 text-center'>
        <span className='asset-title'>{currentNpc.name}</span>
      </div>

      <div className='my-2 flex justify-center text-violet-600'>
        {currentNpc.classes || 'Unknown'}
      </div>

      <div className='mt-3 flex flex-wrap justify-center'>
        Group affiliation:{' '}
        <span className={hasDataClass.fac}>{currentNpc.faction || 'None'}</span>
        Location:{' '}
        <span className={hasDataClass.loc}>
          {currentNpc.location || 'Unknown'}
        </span>
      </div>
      <hr className='details-seperating-bar mb-0 mt-1' />
    </>
  );
};

const MiscInfo = ({ currentNpc, role }) => {
  const [isActiveFac, setIsActiveFac] = useState(true);
  const [isActiveLoc, setIsActiveLoc] = useState(true);
  const [isActiveFacGM, setIsActiveFacGM] = useState(false);
  const [isActiveLocGM, setIsActiveLocGM] = useState(false);
  const npcName = <span className='text-violet-600'>{currentNpc.name}</span>;

  return (
    <>
      <RelatedInfo />

      <FactionHeader isActive={isActiveFac} setIsActive={setIsActiveFac} />

      <div
        className={`${
          isActiveFac ? 'max-h-full' : 'invisible max-h-0'
        } mx-4 overflow-hidden transition-all duration-500 ease-in-out`}
      >
        {currentNpc.faction_details ? (
          <DetailMD details={currentNpc.faction_details} />
        ) : (
          <span>
            {npcName} does not appear to be affiliated with any groups.
          </span>
        )}
      </div>

      <LocationHeader isActive={isActiveLoc} setIsActive={setIsActiveLoc} />

      <div
        className={`${
          isActiveLoc ? 'max-h-full' : 'invisible max-h-0'
        } mx-4 overflow-hidden transition-all duration-500 ease-in-out`}
      >
        {currentNpc.location_details ? (
          <DetailMD details={currentNpc.location_details} />
        ) : (
          <span>The details of {npcName}&apos;s location are unknown.</span>
        )}
      </div>

      {role === 'GM' ? (
        <>
          <GMFactionHeader
            isActive={isActiveFacGM}
            setIsActive={setIsActiveFacGM}
          />

          <div
            className={`${
              isActiveFacGM ? 'max-h-full' : 'invisible max-h-0'
            } mx-4 overflow-hidden transition-all duration-500 ease-in-out`}
          >
            {currentNpc.faction_hidden_details ? (
              <DetailMD details={currentNpc.faction_hidden_details} />
            ) : (
              <>No hidden group notes to display.</>
            )}
          </div>

          <GMLocationHeader
            isActive={isActiveLocGM}
            setIsActive={setIsActiveLocGM}
          />

          <div
            className={`${
              isActiveLocGM ? 'max-h-full' : 'invisible max-h-0'
            } mx-4 overflow-hidden transition-all duration-500 ease-in-out`}
          >
            {currentNpc.location_hidden_details ? (
              <DetailMD details={currentNpc.location_hidden_details} />
            ) : (
              <>No hidden location notes to display.</>
            )}
          </div>
        </>
      ) : null}
    </>
  );
};
