import { createSlice } from '@reduxjs/toolkit';
import { resetAll } from './compareSlice';

const initialState = {
  allGames: [],
};

const gameSlice = createSlice({
  name: 'game',
  initialState,
  extraReducers: (obj) => obj.addCase(resetAll, () => initialState),
  reducers: {
    setGames: (state, action) => {
      const games = action.payload;
      state.allGames = games;
    },
  },
});

export const { setGames } = gameSlice.actions;

export default gameSlice.reducer;

export const selectUserGames = (state) => state.games.allGames;
