import dgnApi from './dgnAPI';

const questApi = dgnApi.injectEndpoints({
  endpoints: (build) => ({
    getQuests: build.query({
      query: (gid) => ({
        url: `/api/quests/${gid}`,
      }),
      providesTags: ['Quests'],
    }),

    createQuest: build.mutation({
      query: (data) => ({
        method: 'POST',
        url: '/api/quest',
        body: data,
      }),
      invalidatesTags: ['Quests'],
    }),

    getOneQuest: build.query({
      query: (id) => ({
        url: `/api/quest/${id}`,
      }),
    }),

    updateQuest: build.mutation({
      query: (data) => {
        const { id, ...body } = data;
        return {
          method: 'PUT',
          url: `/api/quest/${id}`,
          body,
        };
      },
      invalidatesTags: ['Quests'],
    }),

    deleteQuest: build.mutation({
      query: (id) => ({
        method: 'DELETE',
        url: `/api/quest/${id}`,
      }),
      invalidatesTags: ['Quests'],
    }),
  }),
});

export const {
  useCreateQuestMutation,
  useDeleteQuestMutation,
  useGetOneQuestQuery,
  useGetQuestsQuery,
  useUpdateQuestMutation,
} = questApi;
