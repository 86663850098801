import { createSlice } from '@reduxjs/toolkit';
import { resetAll } from './compareSlice';

const initialState = {
  allLocations: [],
  selectedLocation: null,
};

const locationSlice = createSlice({
  name: 'location',
  initialState,
  extraReducers: (obj) => obj.addCase(resetAll, () => initialState),
  reducers: {
    setLocations: (state, action) => {
      state.allLocations = action.payload;
    },

    setOneLocation: (state, action) => {
      state.selectedLocation = action.payload;
    },
  },
});

export const { setLocations, setOneLocation } = locationSlice.actions;

export default locationSlice.reducer;

export const selectAllLocations = (state) => state.locations.allLocations;
export const selectOneLocation = (state) => state.locations.selectedLocation;
