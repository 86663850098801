import dgnApi from './dgnAPI';

const characterApi = dgnApi.injectEndpoints({
  endpoints: (build) => ({
    createCharacter: build.mutation({
      query: (data) => ({
        method: 'POST',
        url: '/api/player',
        body: data,
      }),
      invalidatesTags: ['CurrentCharacter'],
    }),

    getCharacter: build.query({
      query: (playerID) => ({
        url: `/api/player/${playerID}`,
      }),
      providesTags: ['CurrentCharacter'],
    }),

    updateCharacter: build.mutation({
      query: (data) => {
        const { id, ...body } = data;
        return {
          method: 'PUT',
          url: `/api/player/${id}`,
          body,
        };
      },
      invalidatesTags: ['CurrentCharacter'],
    }),

    giveInspo: build.mutation({
      query: (data) => {
        const { gID, plID, ...body } = data;
        return {
          method: 'PUT',
          url: `/api/give/inspo/${gID}/${plID}`,
          body,
        };
      },
      invalidatesTags: ['Party'],
    }),

    useInspo: build.mutation({
      query: (data) => {
        const { gID, plID, ...body } = data;
        return {
          method: 'PUT',
          url: `/api/use/inspo/${gID}/${plID}`,
          body,
        };
      },
      invalidatesTags: ['CurrentCharacter', 'Party'],
    }),

    getUsersCharacters: build.query({
      query: (username) => ({
        url: `/api/characters/${username}`,
      }),
      providesTags: ['Characters'],
    }),

    removePlayer: build.mutation({
      query: (data) => {
        const { gID, plID } = data;
        return { method: 'DELETE', url: `/api/remove/player/${gID}/${plID}` };
      },
      invalidatesTags: ['Characters', 'Party'],
    }),

    deleteAllChars: build.mutation({
      query: (uuid) => ({
        url: `/api/players/${uuid}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Characters', 'CurrentCharacter'],
    }),
  }),
});

export const {
  useCreateCharacterMutation,
  useGetCharacterQuery,
  useUpdateCharacterMutation,
  useGetUsersCharactersQuery,
  useGiveInspoMutation,
  useRemovePlayerMutation,
  useUseInspoMutation,
  useDeleteAllCharsMutation,
} = characterApi;
