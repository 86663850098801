import dgnApi from './dgnAPI';

const gameApi = dgnApi.injectEndpoints({
  endpoints: (build) => ({
    createGame: build.mutation({
      query: (data) => ({
        method: 'POST',
        url: '/api/game',
        body: data,
      }),
      invalidatesTags: ['Game'],
    }),

    getGames: build.query({
      query: (gm) => ({
        url: `/api/games/${gm}`,
      }),
      providesTags: ['Game'],
    }),

    getGameInfo: build.query({
      query: (gID) => ({
        url: `/api/game/${gID}`,
      }),
      providesTags: ['Game'],
    }),

    updateGame: build.mutation({
      query: (data) => {
        const { id, ...body } = data;
        return {
          method: 'PUT',
          url: `/api/game/${id}`,
          body,
        };
      },
      invalidatesTags: ['Game'],
    }),

    deleteGame: build.mutation({
      query: (id) => ({
        url: `api/game/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Game'],
    }),

    deleteAllGames: build.mutation({
      query: (username) => ({
        url: `api/games/${username}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Game'],
    }),
  }),
});

export const {
  useGetGamesQuery,
  useCreateGameMutation,
  useGetAllGamesQuery,
  useUpdateGameMutation,
  useDeleteGameMutation,
  useGetGameInfoQuery,
  useDeleteAllGamesMutation,
} = gameApi;
