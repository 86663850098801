import dgnApi from './dgnAPI';

const factionApi = dgnApi.injectEndpoints({
  endpoints: (build) => ({
    getFactions: build.query({
      query: (gid) => ({
        url: `/api/factions/${gid}`,
      }),
      providesTags: ['Factions'],
    }),

    createFaction: build.mutation({
      query: (data) => ({
        method: 'POST',
        url: '/api/faction',
        body: data,
      }),
      invalidatesTags: ['Factions'],
    }),

    getOneFaction: build.query({
      query: (id) => ({
        url: `/api/faction/${id}`,
      }),
    }),

    updateFaction: build.mutation({
      query: (data) => {
        const { id, ...body } = data;
        return {
          method: 'PUT',
          url: `/api/faction/${id}`,
          body,
        };
      },
      invalidatesTags: ['Factions'],
    }),

    deleteFaction: build.mutation({
      query: (id) => ({
        method: 'DELETE',
        url: `/api/faction/${id}`,
      }),
      invalidatesTags: ['Factions'],
    }),
  }),
});

export const {
  useCreateFactionMutation,
  useDeleteFactionMutation,
  useGetFactionsQuery,
  useGetOneFactionQuery,
  useUpdateFactionMutation,
} = factionApi;
