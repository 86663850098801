import React from 'react';

const FloatingLabelInput = ({
  onChange,
  value,
  labelText,
  nameAndID,
  isRequired = false,
  maxLength,
  type = 'text',
  additionalInputClassNames = '',
  additionalLabelClassNames = '',
  readOnly = false,
}) => (
  <div className='relative'>
    <input
      className={`${additionalInputClassNames} border-1 peer rounded-lg pt-4 focus:border-white focus:ring-0`}
      onChange={onChange}
      placeholder=' '
      type={type}
      name={nameAndID}
      id={nameAndID}
      required={isRequired}
      maxLength={maxLength}
      value={value}
      readOnly={readOnly}
    />
    <label
      htmlFor={nameAndID}
      className={`${additionalLabelClassNames} absolute left-1 top-2 z-10 origin-[0] -translate-y-4 scale-75 px-2 text-center text-lg text-[#fcf5e5] duration-300 peer-placeholder-shown:top-1/2 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:scale-100 peer-focus:top-2 peer-focus:-translate-y-4 peer-focus:scale-75 peer-focus:px-2 peer-focus:pt-1`}
    >
      {labelText}
    </label>
  </div>
);

export default FloatingLabelInput;
