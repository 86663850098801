import React, { useState, useEffect } from 'react';

import { BiSolidHelpCircle } from 'react-icons/bi';
import '../landingPage.css';

const Stepper = ({ activeStep, carouselState }) => {
  return carouselState !== 'search' ? (
    <DgnTips />
  ) : (
    <ol className='stepper-wrap flex w-full items-center space-x-2 bg-[#074f57] p-3 text-center text-sm font-medium text-gray-400 shadow-sm sm:space-x-4 sm:p-4 sm:text-base'>
      <div className={`${activeStep === 1 && 'text-white'}`}>
        <li className='flex items-center'>
          <span className='mr-2 flex h-5 w-5 shrink-0 items-center justify-center rounded-full border border-current text-xs'>
            1
          </span>
          Search for Your GM&apos;s Games
          <svg
            aria-hidden='true'
            className='ml-2 h-4 w-4 sm:ml-4'
            fill='none'
            stroke='currentColor'
            viewBox='0 0 24 24'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              strokeLinecap='round'
              strokeLinejoin='round'
              strokeWidth='2'
              d='M13 5l7 7-7 7M5 5l7 7-7 7'
            ></path>
          </svg>
        </li>
      </div>
      <div className={`${activeStep === 2 && 'text-white'}`}>
        <li className='flex items-center'>
          <span className='mr-2 flex h-5 w-5 shrink-0 items-center justify-center rounded-full border border-current text-xs'>
            2
          </span>
          Join a Game
          <svg
            aria-hidden='true'
            className='ml-2 h-4 w-4 sm:ml-4'
            fill='none'
            stroke='currentColor'
            viewBox='0 0 24 24'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              strokeLinecap='round'
              strokeLinejoin='round'
              strokeWidth='2'
              d='M13 5l7 7-7 7M5 5l7 7-7 7'
            ></path>
          </svg>
        </li>
      </div>
      <div className={`${activeStep === 3 && 'text-white'}`}>
        <li className='flex items-center'>
          <span className='mr-2 flex h-5 w-5 shrink-0 items-center justify-center rounded-full border border-current text-xs'>
            3
          </span>
          Find Your Party
        </li>
      </div>
      <div className={`${activeStep === 4 && 'text-white'}`}>
        <li className='flex items-center'>
          <span className='mr-2 flex h-5 w-5 shrink-0 items-center justify-center rounded-full border border-current text-xs'>
            4
          </span>
          Add Your Character
        </li>
      </div>
    </ol>
  );
};

export default Stepper;

const DgnTips = () => {
  // We can use this to convey messages to users when we're gonna do maintainance
  const tips = [
    <>
      Tip: If you aren&apos;t familiar with markdown, click
      <BiSolidHelpCircle className='mx-1' />
      to view helpful syntax that expands customization!
    </>,
    <>
      Remember: Players need to know the passcode of the game they&apos;re
      joining
    </>,
    <>Remember: Add a character&apos;s backstory in their settings page</>,
    <>Please: Get sunlight</>,
    <>Recharge: Pet dog</>,
    <>Remember: Call grandma</>,
    <>Tip: Surround yourself with like-minded people</>,
    <>Please: Take a break every now and then</>,
  ];
  const [tip, setTip] = useState(
    <span className='bg-transparent text-transparent'>Placeholder</span>
  );
  useEffect(() => {
    const upperLimit = tips.length;
    const idx = Math.floor(Math.random() * upperLimit);
    setTip(tips[idx]);
  }, []);

  return (
    <div className='stepper-wrap flex w-full items-center space-x-2 bg-[#074f57] p-3 text-center text-sm font-medium text-white shadow-sm sm:space-x-4 sm:p-4 sm:text-base'>
      {tip}
    </div>
  );
};
