import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { Tooltip } from 'flowbite-react';

import { useDispatch, useSelector } from 'react-redux';
import { selectCurrentUser } from '../../redux/auth/authSlice';
import { useCreateGameMutation } from '../../redux/api-slices/gameAPI';
import { spacesUpload, spacesDelete } from '../../digitalocean/spaces';
import SizeChecker from '../archive/misc-components/SizeChecker';
import { EditorMD } from '../archive/misc-components/MiscDetailComps';

import { ReactComponent as DgnLogo } from '../../app/assets/dgnLogo.svg';

import './createGame.css';
import DgnFileInput from '../archive/misc-components/DgnFileInput';
import FloatingLabelInput from '../archive/misc-components/FloatingLabelInput';
import {
  displayAlert,
  setAlertInfo,
} from '../../redux/state-slices/alertSlice';
import { gameMDTemplate } from '../MDtemplates/Templates';
import SubmitLoader from '../loading-ani/SubmitLoader';

function CreateGame() {
  const user = useSelector(selectCurrentUser);
  const [gamePost] = useCreateGameMutation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [gameInfo, setGameInfo] = useState({
    gm: user.username,
    title: '',
    frequency: '',
    passcode: '',
  });
  const [mdDesc, setMdDesc] = useState(gameMDTemplate);
  const [image, setImage] = useState('');
  const [imgObject, setImgObject] = useState(null);
  const [imgSize, setImgSize] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    if (imgObject && imgObject.size) {
      const mb = imgObject.size / 1024 / 1024;
      setImgSize(mb.toFixed(2));
    }
  }, [imgObject]);

  const placeholder = 'https://fakeimg.pl/560x400?text=Game+Image';

  const imageChange = (e) => {
    const { files } = e.target;
    if (files && files.length) {
      const imgObj = files[0];
      setImgObject(imgObj);
      setImage(URL.createObjectURL(imgObj));
    }
  };

  const handleChange = async (e) => {
    setGameInfo({ ...gameInfo, [e.target.name]: e.target.value });
  };

  async function handleSubmit(e) {
    e.preventDefault();
    setIsSubmitting(true);

    let imgResponse = imgObject
      ? await spacesUpload(imgObject, 'GameAssets/GameImage', user.username)
      : 'https://fakeimg.pl/560x400?text=Game+Image';

    if (!imgResponse || imgResponse.Error) {
      imgResponse = 'https://fakeimg.pl/560x400?text=Game+Image';
    }
    if (imgResponse) {
      gameInfo.img_url = imgResponse;
      gameInfo.description = mdDesc;
      const gameCreateRes = await gamePost(gameInfo);

      if (gameCreateRes && 'data' in gameCreateRes) {
        dispatch(
          setAlertInfo({
            alertType: 'success',
            message: `${gameInfo?.title} successfully created`,
          })
        );
        dispatch(displayAlert());
        navigate('/');
      }

      if (!gameCreateRes || 'error' in gameCreateRes) {
        if (gameInfo.img_url !== 'https://fakeimg.pl/560x400?text=Game+Image') {
          await spacesDelete(imgResponse, user.username);
        }
        dispatch(
          setAlertInfo({
            alertType: 'storage',
            message:
              'We are only allowing 1 game per user right now. This limit will increase in the future!',
          })
        );
        dispatch(displayAlert());
        navigate('/');
      }
    }
    setIsSubmitting(false);
  }

  return (
    <>
      {isSubmitting && <SubmitLoader message='Submitting' />}
      <section className='game-wrapper'>
        <div className='game-content'>
          <form
            className='create-game-form relative mx-auto h-full min-h-screen w-full max-w-[95rem] p-3'
            method='post'
            onSubmit={handleSubmit}
          >
            <div className='border-image-container p-[.9rem]'>
              <div className='relative mx-auto mb-8 h-full'>
                <div className='left-content mr-4 mt-8 flex h-full w-full flex-col items-center'>
                  <FloatingLabelInput
                    onChange={handleChange}
                    value={gameInfo?.title}
                    labelText='Title'
                    nameAndID='title'
                    additionalInputClassNames='asset-title mx-auto'
                    isRequired
                  />

                  <div className='flex justify-evenly pt-16'>
                    <FloatingLabelInput
                      onChange={handleChange}
                      value={gameInfo?.frequency}
                      labelText='Frequency'
                      nameAndID='frequency'
                      additionalInputClassNames='asset-title px-4 py-1 mr-1'
                    />

                    <FloatingLabelInput
                      onChange={handleChange}
                      value={gameInfo?.passcode}
                      labelText='Passcode'
                      nameAndID='passcode'
                      additionalInputClassNames='asset-title px-4 py-1 ml-1'
                      isRequired
                    />
                  </div>

                  <div className='img-select mt-8 h-full'>
                    <div className='img-container mx-auto'>
                      <img
                        className='chosen-img'
                        src={!image ? placeholder : image}
                        alt=''
                      />
                    </div>
                    <div className='m-2 flex justify-center p-2'>
                      {imgSize ? <SizeChecker imgSize={imgSize} /> : null}

                      <DgnFileInput imageChangeHandler={imageChange} />
                    </div>
                  </div>

                  <div className='flex justify-center pt-20'>
                    <DgnLogo className='h-24 w-full' />
                  </div>
                </div>
              </div>

              <EditorMD mdText={mdDesc} setMdText={setMdDesc} height='60vh' />

              <div className=' mt-2 flex justify-center'>
                {imgSize === null || imgSize <= 2 ? (
                  <button
                    className='button-submit mb-5 rounded-lg bg-green-700 px-8 py-2 text-white hover:scale-[1.03] hover:transition-all active:scale-[.98]'
                    type='submit'
                  >
                    <span className='p-3'>Submit</span>
                  </button>
                ) : (
                  <Tooltip
                    // eslint-disable-next-line react/style-prop-object
                    style='light'
                    content='Your image is too large, consider compressing it'
                  >
                    <button
                      className='mb-5 cursor-not-allowed rounded-lg bg-green-700 px-8 py-2 text-[#fcf5e5] active:bg-[#420000] disabled:opacity-50'
                      type='submit'
                      disabled
                    >
                      <span className='p-3'>Submit</span>
                    </button>
                  </Tooltip>
                )}
              </div>
            </div>
          </form>
        </div>
      </section>
    </>
  );
}

// export const FooterAccent = () => {
//   return <div className='footer-accent' />;
// };

export default CreateGame;
