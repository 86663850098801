import { createSlice } from '@reduxjs/toolkit';
import { resetAll } from './compareSlice';

const initialState = {
  allNpcs: [],
  selectedNpc: null,
};

const npcSlice = createSlice({
  name: 'npc',
  initialState,
  extraReducers: (obj) => obj.addCase(resetAll, () => initialState),
  reducers: {
    setNpcs: (state, action) => {
      state.allNpcs = action.payload;
    },

    setOneNpc: (state, action) => {
      state.selectedNpc = action.payload;
    },
  },
});

export const { setNpcs, setOneNpc } = npcSlice.actions;

export default npcSlice.reducer;

export const selectAllNpcs = (state) => state.npcs.allNpcs;
export const selectOneNpc = (state) => state.npcs.selectedNpc;
