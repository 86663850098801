import { useEffect } from 'react';
import Session from 'supertokens-auth-react/recipe/session';
import { useDispatch } from 'react-redux';
import { setJWT, setUser } from '../redux/auth/authSlice';

export default function useGetUserData() {
  const dispatch = useDispatch();

  useEffect(() => {
    const rpPLZ = async () => {
      if (await Session.doesSessionExist()) {
        const { email: e, username: u } =
          await Session.getAccessTokenPayloadSecurely();
        const t = await Session.getAccessToken();
        dispatch(setUser({ email: e, username: u, signedIn: true }));
        dispatch(setJWT(t));
      } else {
        dispatch(setUser({ signedIn: false }));
      }
    };
    rpPLZ();
  }, []);
}
