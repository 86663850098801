import React, { useEffect, useCallback, useState } from 'react';
import { Link } from 'react-router-dom';
import { FaArrowRight, FaArrowLeft } from 'react-icons/fa';
import useEmblaCarousel from 'embla-carousel-react';

// Redux
import { useSelector, useDispatch } from 'react-redux';
import dgnApi from '../../../redux/api-slices/dgnAPI';
import { resetAll } from '../../../redux/state-slices/compareSlice';
import { selectCurrentUser, selectToken } from '../../../redux/auth/authSlice';
import { useGetUsersCharactersQuery } from '../../../redux/api-slices/characterAPI';
import { useGetGamesQuery } from '../../../redux/api-slices/gameAPI';

// Components
import SearchBar from '../search-bar/SearchBar';
import SearchResults from '../search-bar/SearchResults';
import PlayerView from './views/PlayerView';
import GmView from './views/GmView';
import LoadingSpinner from '../../loading-ani/LoadingSpinner';

import './carousel.css';
import {
  displayAlert,
  setAlertInfo,
} from '../../../redux/state-slices/alertSlice';

function Carousel({
  activeStep,
  setActiveStep,
  carouselState,
  setCarouselState,
}) {
  const user = useSelector(selectCurrentUser);
  const [skip, setSkip] = useState(true);
  const dispatch = useDispatch();

  // Start Search Bar/Results Lifted State & Functions
  const [skipGamesData, setSkipGamesData] = useState(true);
  const [queryString, setQueryString] = useState(null);
  const [selectedGameID, setSelectedGameID] = useState(null);
  const [gamePasscode, setGamePasscode] = useState('');
  const { data: gamesData, isFetching } = useGetGamesQuery(queryString, {
    skip: skipGamesData,
  });
  const [willShowSelectedParties, setWillShowSelectedParties] = useState(false);
  const token = useSelector(selectToken);

  const handleJoinGame = async (e, gameID) => {
    e.preventDefault();
    setSelectedGameID(gameID);
    const data = {
      id: gameID,
      passcode: gamePasscode,
    };

    try {
      const res = await fetch(
        `${process.env.REACT_APP_GAME_SERVICE}/api/game/join`,
        {
          method: 'POST',
          body: JSON.stringify(data),
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      );
      const json = await res.json();

      if (res.status === 401) {
        // TODO: Put this request in gameAPI l8r
        if (json.detail === 'Expired token') {
          alert('Needs a refresh, old token.');
        } else {
          alert('Wrong password');
          return false;
        }
      } else {
        setWillShowSelectedParties(true);
        return true;
      }
    } catch (error) {
      dispatch(
        setAlertInfo({
          alertType: 'failure',
          message:
            'An error occured when joining this game, try logging out then joining',
        })
      );
      dispatch(displayAlert());
    }
    return false;
  };

  // End

  const { data: playerData, isLoading } = useGetUsersCharactersQuery(
    user.username,
    {
      skip,
    }
  );

  const { data: gmData } = useGetGamesQuery(user.username, { skip });

  const [emblaRef, emblaApi] = useEmblaCarousel({
    loop: true,
    align: 'center',
  });

  const emblaContClass =
    carouselState === 'gm'
      ? 'embla__container relative'
      : 'embla__container relative';

  useEffect(() => {
    if (user.username) {
      setSkip(false);
    }
  }, [user]);

  useEffect(() => {
    dispatch(dgnApi.util.resetApiState());
    dispatch(resetAll());
  }, []);

  const scrollPrev = useCallback(() => {
    if (emblaApi) emblaApi.scrollPrev();
  }, [emblaApi]);

  const scrollNext = useCallback(() => {
    if (emblaApi) emblaApi.scrollNext();
  }, [emblaApi]);

  return isLoading ? (
    <Loading />
  ) : (
    <div className='relative mx-auto max-w-[84rem]'>
      {/* Player or GM select row */}
      <ViewSelector
        carouselState={carouselState}
        setCarouselState={setCarouselState}
        setSkipGamesData={setSkipGamesData}
        queryString={queryString}
        setQueryString={setQueryString}
        selectedGameID={selectedGameID}
        setGamePasscode={setGamePasscode}
        token={token}
        willShowSelectedParties={willShowSelectedParties}
        setWillShowSelectedParties={setWillShowSelectedParties}
        activeStep={activeStep}
        setActiveStep={setActiveStep}
      />
      {/* Carousel */}
      <div className='embla relative mt-4' ref={emblaRef}>
        <div className={emblaContClass}>
          {carouselState === 'player' ? (
            <PlayerView playerData={playerData} />
          ) : carouselState === 'gm' ? (
            <GmView gmData={gmData} />
          ) : // Why null? To display search results outside of carousel. Left as ternary rather than && to be easier to read in future
          null}
        </div>

        {/* Carousel controls */}
        {carouselState === 'player' && (
          <CarouselArrows scrollNext={scrollNext} scrollPrev={scrollPrev} />
        )}
      </div>

      {carouselState === 'search' && (
        <SearchResults
          queryString={queryString}
          gamesData={gamesData}
          isFetching={isFetching}
          handleJoinGame={handleJoinGame}
          setGamePasscode={setGamePasscode}
          willShowSelectedParties={willShowSelectedParties}
          setWillShowSelectedParties={setWillShowSelectedParties}
          token={token}
          selectedGameID={selectedGameID}
          activeStep={activeStep}
          setActiveStep={setActiveStep}
        />
      )}
    </div>
  );
}

const Loading = () => {
  return (
    <div className='relative my-10 flex h-40 translate-y-1/2 justify-center'>
      <LoadingSpinner classStyle='loading-ani-red' />
    </div>
  );
};

const ViewSelector = ({
  carouselState,
  setCarouselState,
  setSkipGamesData,
  queryString,
  setQueryString,
  setGamePasscode,
  setWillShowSelectedParties,
  activeStep,
  setActiveStep,
}) => {
  return (
    <>
      <div className='view-select flex justify-center rounded-lg py-3'>
        <button
          type='button'
          className={
            carouselState === 'player' ? 'view-button pr-3 font-bold' : 'pr-3'
          }
          onClick={() => setCarouselState('player')}
        >
          My characters
        </button>
        <button
          type='button'
          className={
            carouselState === 'gm' ? 'view-button pl-3 font-bold' : 'pl-3'
          }
          onClick={() => setCarouselState('gm')}
        >
          My games
        </button>

        <div className='mx-3 w-[1px] border-r-[1px] border-r-black' />

        <div>
          <button
            type='button'
            className={
              carouselState === 'search' ? 'view-button font-bold' : ''
            }
            onClick={() => setCarouselState('search')}
          >
            Search for a game
          </button>
          <Link to='create-game' className='ml-3'>
            Create a game
          </Link>
        </div>
      </div>
      {carouselState === 'search' && (
        <SearchBar
          queryString={queryString}
          setQueryString={setQueryString}
          setGamePasscode={setGamePasscode}
          setSkip={setSkipGamesData}
          setWillShowSelectedParties={setWillShowSelectedParties}
          activeStep={activeStep}
          setActiveStep={setActiveStep}
        />
      )}
    </>
  );
};

const CarouselArrows = ({ scrollNext, scrollPrev }) => {
  return (
    <div className='relative mt-12 flex justify-center'>
      <button
        type='button'
        className='embla__prev embla-arrows relative mr-2'
        onClick={scrollPrev}
      >
        <FaArrowLeft className='arrows' />
      </button>

      <button
        type='button'
        className='embla__next embla-arrows relative ml-2'
        onClick={scrollNext}
      >
        <FaArrowRight className='arrows' />
      </button>
    </div>
  );
};

export default Carousel;
