import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

// Components
import { FiEyeOff, FiEye } from 'react-icons/fi';

import NpcDetails from './content-details/NpcDetails';
import CheckboxCompare from '../misc-components/CheckboxCompare';
import { spacesDelete } from '../../../digitalocean/spaces';

// Redux
import {
  selectAllNpcs,
  setNpcs,
  setOneNpc,
} from '../../../redux/state-slices/npcSlice';
import { useDeleteNpcMutation } from '../../../redux/api-slices/npcAPI';

// styles
import './selected.css';
import ViewButton, {
  CreateAndRefetch,
  FlowModal,
  GMButtons,
  NoAssets,
} from '../misc-components/MiscListComps';
import AssetEditorModal from '../../asset-editor-modal/AssetEditorModal';
import SubmitLoader from '../../loading-ani/SubmitLoader';
import {
  displayAlert,
  setAlertInfo,
} from '../../../redux/state-slices/alertSlice';
import { DeleteFail } from '../../alerts/ResponseAlerts';

export default function NpcContent({ role, username, refetcher }) {
  const npcs = useSelector(selectAllNpcs);

  const [npcDetails, setNpcDetails] = useState(null);

  return npcDetails ? (
    <NpcDetails setNpcDetails={setNpcDetails} role={role} />
  ) : (
    <div className='list-content'>
      <CreateAndRefetch role={role} refetcher={refetcher} asset='NPC' />

      {npcs?.length === 0 ? (
        <NoAssets asset='NPCs' />
      ) : (
        <div className='content-body mt-3 flex columns-3 flex-col overflow-y-auto overflow-x-hidden p-4'>
          <NpcList
            npcs={npcs}
            role={role}
            setNpcDetails={setNpcDetails}
            username={username}
          />
        </div>
      )}
    </div>
  );
}

const NpcList = ({ npcs, role, setNpcDetails, username }) => {
  const dispatch = useDispatch();
  const [openModal, setOpenModal] = useState(undefined);
  const [item, setItem] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [npcDelete] = useDeleteNpcMutation();

  const selectOne = (npc) => {
    dispatch(setOneNpc(npc));
    setNpcDetails(true);
  };

  const failedDeleteAlert = () => {
    dispatch(setAlertInfo(DeleteFail));
    dispatch(displayAlert());
  };

  const deleteNpc = async () => {
    setOpenModal(undefined);
    setIsSubmitting(true);
    const isLastDeleted = npcs.length === 1 ? true : null;
    const img = item?.img_url;

    try {
      if (img) {
        await spacesDelete(img, username);
      }
      const npcDelRes = await npcDelete(item.id);
      if ('data' in npcDelRes && npcDelRes.data) {
        dispatch(
          setAlertInfo({
            alertType: 'success',
            message: `${item.name} deleted`,
          })
        );
        dispatch(displayAlert());
      } else if (
        ('data' in npcDelRes && npcDelRes.data === false) ||
        'detail' in npcDelRes ||
        'error' in npcDelRes
      ) {
        failedDeleteAlert();
      }
    } catch (e) {
      failedDeleteAlert();
    }

    if (isLastDeleted) {
      dispatch(setNpcs([]));
    }

    setItem(null);
    setIsSubmitting(false);
  };

  return (
    <>
      {isSubmitting && <SubmitLoader message='Deleting' />}
      {npcs?.map((npc) => {
        return (
          <div
            className='content-item flex flex-row justify-between'
            key={npc.id}
          >
            <span className='m-2 py-2'>
              <CheckboxCompare
                obj={npc}
                category='npc'
                elId={`checkbox-npc-${npc.id}`}
              />
              {npc.name}
            </span>
            <div className='list-button-container flex'>
              <span className='m-2 px-4 py-2'>
                {role && role === 'GM' ? (
                  npc.is_known ? (
                    <FiEye className='mr-1 inline text-green-600 opacity-100' />
                  ) : (
                    <FiEyeOff className='mr-1 inline text-violet-600 opacity-100' />
                  )
                ) : null}
              </span>
              <ViewButton obj={npc} selectOne={selectOne} />

              {role && role === 'GM' ? (
                <>
                  <GMButtons
                    obj={npc}
                    setSelected={setItem}
                    setOpenModal={setOpenModal}
                    setOne={setOneNpc}
                  />

                  <FlowModal
                    openModal={openModal}
                    setOpenModal={setOpenModal}
                    obj={item}
                    deleteFunc={deleteNpc}
                  />
                </>
              ) : null}
            </div>
          </div>
        );
      })}
      {item && (
        <AssetEditorModal
          openModal={openModal}
          setOpenModal={setOpenModal}
          oldFormData={item}
          assetType='NPC'
        />
      )}
    </>
  );
};
