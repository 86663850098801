import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

// Components
import { FiEyeOff, FiEye } from 'react-icons/fi';
import ViewButton, {
  CreateAndRefetch,
  FlowModal,
  GMButtons,
  NoAssets,
} from '../misc-components/MiscListComps';

import FactionDetails from './content-details/FactionDetails';
import CheckboxCompare from '../misc-components/CheckboxCompare';
import { spacesDelete } from '../../../digitalocean/spaces';

// Redux
import {
  selectAllFactions,
  setOneFaction,
  setFactions,
} from '../../../redux/state-slices/factionSlice';
import { useDeleteFactionMutation } from '../../../redux/api-slices/factionAPI';

// styles
import './selected.css';
import AssetEditorModal from '../../asset-editor-modal/AssetEditorModal';
import {
  displayAlert,
  setAlertInfo,
} from '../../../redux/state-slices/alertSlice';
import { DeleteFail } from '../../alerts/ResponseAlerts';
import SubmitLoader from '../../loading-ani/SubmitLoader';

export default function FactionContent({ role, username, refetcher }) {
  const factions = useSelector(selectAllFactions);

  const [factionDetails, setFactionDetails] = useState(null);

  return factionDetails ? (
    <FactionDetails setFactionDetails={setFactionDetails} role={role} />
  ) : (
    <div className='list-content'>
      <CreateAndRefetch role={role} refetcher={refetcher} asset='Group' />

      {factions?.length === 0 ? (
        <NoAssets asset='groups' />
      ) : (
        <div className='content-body flex columns-3 flex-col overflow-y-auto overflow-x-hidden p-4'>
          <FactionList
            factions={factions}
            role={role}
            setFactionDetails={setFactionDetails}
            username={username}
          />
        </div>
      )}
    </div>
  );
}

const FactionList = ({ factions, role, setFactionDetails, username }) => {
  const dispatch = useDispatch();
  const [openModal, setOpenModal] = useState(undefined);
  const [item, setItem] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [factionDelete] = useDeleteFactionMutation();

  const selectOne = (fac) => {
    dispatch(setOneFaction(fac));
    setFactionDetails(true);
  };

  const failedDeleteAlert = () => {
    dispatch(setAlertInfo(DeleteFail));
    dispatch(displayAlert());
  };

  const deleteFaction = async () => {
    setOpenModal(undefined);
    setIsSubmitting(true);
    const isLastDeleted = factions.length === 1 ? true : null;
    const img = item?.img_url;

    try {
      if (img) {
        await spacesDelete(img, username);
      }
      const factDelRes = await factionDelete(item.id);
      if ('data' in factDelRes && factDelRes.data) {
        dispatch(
          setAlertInfo({
            alertType: 'success',
            message: `${item.name} deleted`,
          })
        );
        dispatch(displayAlert());
      } else if (
        ('data' in factDelRes && factDelRes.data === false) ||
        'detail' in factDelRes ||
        'error' in factDelRes
      ) {
        failedDeleteAlert();
      }
    } catch (e) {
      failedDeleteAlert();
    }
    setItem(null);

    if (isLastDeleted) {
      dispatch(setFactions([]));
    }
    setIsSubmitting(false);
  };

  return (
    <>
      {isSubmitting && <SubmitLoader message='Deleting' />}
      {factions?.map((fac) => (
        <div
          className='content-item flex flex-row justify-between'
          key={fac.id}
        >
          <span className='m-2 py-2'>
            <CheckboxCompare
              obj={fac}
              category='faction'
              elId={`checkbox-faction-${fac.id}`}
            />
            {fac.name}
          </span>
          <div className='list-button-container flex'>
            <span className='m-2 px-4 py-2'>
              {role && role === 'GM' ? (
                fac.is_known ? (
                  <FiEye className='mr-1 inline text-green-600 opacity-100' />
                ) : (
                  <FiEyeOff className='mr-1 inline text-violet-600 opacity-100' />
                )
              ) : null}
            </span>
            <ViewButton obj={fac} selectOne={selectOne} />

            {role && role === 'GM' ? (
              <>
                <GMButtons
                  obj={fac}
                  setSelected={setItem}
                  setOpenModal={setOpenModal}
                  setOne={setOneFaction}
                />

                <FlowModal
                  openModal={openModal}
                  setOpenModal={setOpenModal}
                  obj={item}
                  deleteFunc={deleteFaction}
                />
              </>
            ) : null}
          </div>
        </div>
      ))}
      {item && (
        <AssetEditorModal
          openModal={openModal}
          setOpenModal={setOpenModal}
          oldFormData={item}
          assetType='Faction'
        />
      )}
    </>
  );
};
