export const groupOptions = [
  {
    id: 1,
    title: 'Alignment',
    tags: ['Lawful', 'Chaotic', 'Neutral', 'Good', 'Evil'],
  },
  {
    id: 2,
    title: 'Size',
    tags: ['Small', 'Medium', 'Large', 'Widespread'],
  },
  {
    id: 3,
    title: 'Influence',
    tags: [
      'Political',
      'Religious',
      'Arcane',
      'Militaristic',
      'Economic',
      'Underworld',
      'Nature',
    ],
  },
  {
    id: 4,
    title: 'Type',
    tags: [
      'Guild',
      'Cult',
      'Syndicate',
      'Order',
      'Clan',
      'Tribe',
      'Noble House',
      'Mercenary Band',
      'Druidic',
    ],
  },
  {
    id: 5,
    title: 'Traits',
    tags: [
      'Honorable',
      'Treacherous',
      'Ancient',
      'Emerging',
      'Revolutionary',
      'Traditional',
      'Mysterious',
    ],
  },
  {
    id: 6,
    title: 'Goal',
    tags: [
      'Power',
      'Wealth',
      'Knowledge',
      'Peace',
      'Chaos',
      'Protection',
      'Expansion',
    ],
  },
  {
    id: 7,
    title: 'Environment',
    tags: [
      'Urban',
      'Rural',
      'Wilderness',
      'Underworld',
      'Multinational',
      'Underground',
    ],
  },
  {
    id: 8,
    title: 'Culture',
    tags: [
      'Human',
      'Elven',
      'Dwarven',
      'Orcish',
      'Gnomish',
      'Undead',
      'Elemental',
      'Mixed',
    ],
  },
  {
    id: 9,
    title: 'Magic Level',
    tags: ['High Magic', 'Low Magic', 'Magic Forbidden', 'Technomagic'],
  },
  {
    id: 10,
    title: 'Specialization',
    tags: [
      'Magic',
      'Stealth',
      'Combat',
      'Diplomacy',
      'Espionage',
      'Trade',
      'Crafting',
    ],
  },
  {
    id: 11,
    title: 'Interactivity',
    tags: ['Open', 'Secretive', 'Aggressive', 'Passive', 'Manipulative'],
  },
  {
    id: 12,
    title: 'Age',
    tags: ['Ancient', 'Old', 'Recent', 'Timeless'],
  },
  {
    id: 13,
    title: 'Mystical Elements',
    tags: ['Cursed', 'Blessed', 'Arcane', 'Divine'],
  },
  {
    id: 14,
    title: 'Unique Features',
    tags: [
      'Dragon-Tied',
      'Elemental-Bound',
      'Spirit-Linked',
      'Artifact-Driven',
    ],
  },
];

export const locationOptions = [
  {
    id: 1,
    title: 'Size',
    tags: [
      'Small Village',
      'Town',
      'Large City',
      'Metropolis',
      'Ruins',
      'Hidden Enclave',
    ],
  },
  {
    id: 2,
    title: 'Terrain',
    tags: [
      'Forest',
      'Mountain',
      'Desert',
      'Swamp',
      'Urban',
      'Rural',
      'Underwater',
      'Arctic',
      'Coastal',
    ],
  },
  {
    id: 3,
    title: 'Climate',
    tags: ['Tropical', 'Temperate', 'Frigid', 'Arid', 'Rainy', 'Variable'],
  },
  {
    id: 4,
    title: 'Population Density',
    tags: [
      'Sparsely Populated',
      'Densely Populated',
      'Abandoned',
      'Overcrowded',
    ],
  },
  {
    id: 5,
    title: 'Culture',
    tags: [
      'Human',
      'Elven',
      'Dwarven',
      'Orcish',
      'Gnomish',
      'Ancient',
      'Exotic',
      'Multicultural',
    ],
  },
  {
    id: 6,
    title: 'Economic Status',
    tags: [
      'Wealthy',
      'Poor',
      'Trading Hub',
      'Self-Sustaining',
      'Resource-Scarce',
    ],
  },
  {
    id: 7,
    title: 'Governance',
    tags: [
      'Monarchy',
      'Democracy',
      'Anarchy',
      'Tyranny',
      'Theocracy',
      'Oligarchy',
    ],
  },
  {
    id: 8,
    title: 'Social Atmosphere',
    tags: [
      'Peaceful',
      'Tense',
      'Chaotic',
      'Welcoming',
      'Hostile',
      'Mysterious',
      'War-Torn',
    ],
  },
  {
    id: 9,
    title: 'Magic Level',
    tags: ['High Magic', 'Low Magic', 'Magic Forbidden', 'Technomagic'],
  },
  {
    id: 10,
    title: 'Accessibility',
    tags: ['Easily Accessible', 'Remote', 'Guarded', 'Isolated'],
  },
  {
    id: 11,
    title: 'Travel Methods',
    tags: ['On Foot', 'By Sea', 'Magical Transport', 'Airborne', 'Escort'],
  },
  {
    id: 12,
    title: 'Condition',
    tags: [
      'Pristine',
      'Well-Maintained',
      'Dilapidated',
      'Under Reconstruction',
    ],
  },
  {
    id: 13,
    title: 'Adventurous Elements',
    tags: ['Monster Infested', 'Labyrinthine', 'Uncharted', 'Forbidden'],
  },
  {
    id: 14,
    title: 'Unique Features',
    tags: [
      'Natural Wonder',
      'Historical Landmark',
      'Strategic Location',
      'Hidden Treasures',
    ],
  },
];

export const itemOptions = [
  {
    id: 1,
    title: 'Type',
    tags: [
      'Weapon',
      'Armor',
      'Consumable',
      'Scroll',
      'Artifact',
      'Jewelry',
      'Clothing',
      'Tool',
      'Book',
      'Wand',
      'Staff',
    ],
  },
  {
    id: 2,
    title: 'Rarity',
    tags: ['Common', 'Uncommon', 'Rare', 'Very Rare', 'Legendary', 'Unique'],
  },
  {
    id: 3,
    title: 'Magic Type',
    tags: [
      'Enchanted',
      'Cursed',
      'Blessed',
      'Elemental',
      'Necromantic',
      'Divine',
      'Arcane',
      'Druidic',
    ],
  },
  {
    id: 8,
    title: 'Alignment',
    tags: ['Lawful', 'Chaotic', 'Neutral', 'Good', 'Evil'],
  },
  {
    id: 4,
    title: 'Magical Effect',
    tags: [
      'Healing',
      'Offensive',
      'Defensive',
      'Utility',
      'Illusion',
      'Summoning',
    ],
  },
  {
    id: 5,
    title: 'Historical Significance',
    tags: [
      'Famous',
      'Obscure',
      'Heroic',
      'Villainous',
      'Mysterious',
      'Ancient',
    ],
  },
  {
    id: 6,
    title: 'Craftsmanship',
    tags: ['Exquisite', 'Crude', 'Ornate', 'Functional', 'Delicate', 'Robust'],
  },
  {
    id: 7,
    title: 'Use',
    tags: [
      'Combat',
      'Exploration',
      'Stealth',
      'Social',
      'Survival',
      'Ritual',
      'Crafting',
    ],
  },
  {
    id: 9,
    title: 'Sensory Effect',
    tags: ['Glowing', 'Humming', 'Warm', 'Cold', 'Whispering', 'Silent'],
  },
  {
    id: 10,
    title: 'Aesthetic',
    tags: [
      'Elegant',
      'Menacing',
      'Simple',
      'Flamboyant',
      'Mysterious',
      'Traditional',
    ],
  },
];

export const NPCoptions = [
  {
    id: 1,
    title: 'Race',
    tags: [
      'Human',
      'Elf',
      'Dwarf',
      'Orc',
      'Halfling',
      'Gnome',
      'Tiefling',
      'Dragonborn',
      'Genasi',
    ],
  },
  {
    id: 2,
    title: 'Gender',
    tags: ['Male', 'Female', 'Non-Binary', 'Genderfluid'],
  },
  {
    id: 3,
    title: 'Occupation',
    tags: [
      'Merchant',
      'Military',
      'Diplomat',
      'Priest',
      'Criminal',
      'Artist',
      'Noble',
      'Farmer',
      'Craftsman',
      'Scholar',
    ],
  },
  {
    id: 4,
    title: 'Alignment',
    tags: ['Lawful', 'Chaotic', 'Neutral', 'Good', 'Evil'],
  },
  {
    id: 5,
    title: 'Disposition',
    tags: [
      'Friendly',
      'Hostile',
      'Indifferent',
      'Mysterious',
      'Eccentric',
      'Noble',
      'Deceitful',
    ],
  },
  {
    id: 6,
    title: 'Motivation',
    tags: [
      'Wealth',
      'Power',
      'Revenge',
      'Knowledge',
      'Survival',
      'Duty',
      'Adventure',
      'Peaceful',
    ],
  },
  {
    id: 7,
    title: 'Skillset',
    tags: [
      'Magic User',
      'Skilled Fighter',
      'Stealthy',
      'Persuasive',
      'Knowledgeable',
      'Crafty',
    ],
  },
  {
    id: 8,
    title: 'Appearance',
    tags: [
      'Tall',
      'Short',
      'Slender',
      'Rotund',
      'Muscular',
      'Elegant',
      'Rugged',
      'Scarred',
      'Exotic',
    ],
  },
  {
    id: 9,
    title: 'Social Status',
    tags: [
      'Commoner',
      'Aristocrat',
      'Outcast',
      'Foreigner',
      'Legend',
      'Hero',
      'Villain',
    ],
  },
  {
    id: 10,
    title: 'Back Story',
    tags: [
      'Tragic Origin',
      'Mysterious Origin',
      'Heroic Origin',
      'Ordinary Origin',
      'Infamous Origin',
      'Divine Origin',
    ],
  },
  {
    id: 11,
    title: 'Life Events',
    tags: [
      'Lost Love',
      'Great Triumph',
      'Deep Betrayal',
      'Remarkable Discovery',
      'Escape from Danger',
    ],
  },
  {
    id: 12,
    title: 'Affiliation',
    tags: [
      'Part of a Guild',
      'Religious Order',
      'Secret Society',
      'Royal Court',
      'Criminal Gang',
      'Cult Member',
    ],
  },
  {
    id: 13,
    title: 'Magical Affinity',
    tags: [
      'High Magic',
      'Low Magic',
      'Elemental',
      'Dark Arts',
      'Divine Blessing',
      'Druidic',
    ],
  },
  {
    id: 14,
    title: 'Campaign Relevancy',
    tags: ['Irrelevant', 'Side Character', 'Major Character'],
  },
];

export const questOptions = [
  {
    id: 1,
    title: 'Type',
    tags: [
      'Rescue',
      'Exploration',
      'Investigation',
      'Escort',
      'Defense',
      'Heist',
      'Diplomacy',
      'Retrieval',
      'Assassination',
      'Tournament',
    ],
  },
  {
    id: 2,
    title: 'Environment',
    tags: [
      'Urban',
      'Wilderness',
      'Dungeon',
      'Underwater',
      'Sky',
      'Extraplanar',
      'Ruins',
      'Castle',
      'Forest',
      'Desert',
    ],
  },
  { id: 3, title: 'Difficulty', tags: ['Easy', 'Moderate', 'Hard', 'Deadly'] },
  {
    id: 4,
    title: 'Duration',
    tags: ['Short', 'Medium', 'Long', 'Epic-Length'],
  },
  {
    id: 5,
    title: 'Quest Giver',
    tags: [
      'Noble',
      'Merchant',
      'Mysterious Stranger',
      'Religious Leader',
      'Commoner',
      'Outsider',
      'Creature',
    ],
  },
  {
    id: 6,
    title: 'Giver Motivation',
    tags: [
      'Desperation',
      'Greed',
      'Revenge',
      'Duty',
      'Curiosity',
      'Benevolence',
      'Manipulation',
    ],
  },
  {
    id: 7,
    title: 'Primary Goal',
    tags: [
      'Discover',
      'Protect',
      'Destroy',
      'Steal',
      'Uncover',
      'Survive',
      'Deliver',
      'Negotiate',
    ],
  },
  {
    id: 8,
    title: 'Antagonists',
    tags: [
      'Bandits',
      'Monsters',
      'Rival Adventurers',
      'Political Factions',
      'Cultists',
      'Natural Disasters',
      'Curses',
    ],
  },
  {
    id: 9,
    title: 'Challenges',
    tags: [
      'Combat',
      'Puzzles',
      'Traps',
      'Negotiation',
      'Stealth',
      'Survival',
      'Moral Dilemmas',
    ],
  },
  {
    id: 10,
    title: 'Moral Complexity',
    tags: ['Clear Cut', 'Ambiguous', 'Ethical Dilemma', 'Personal Sacrifice'],
  },
  {
    id: 11,
    title: 'Time Sensitive',
    tags: ['Race Against Time', 'Long-Term Quest', 'Timed Events'],
  },
  {
    id: 12,
    title: 'Unique Conditions',
    tags: ['Undercover', 'Disguise', 'Infiltration', 'Secrecy Required'],
  },
];

export const sceneOptions = [
  {
    id: 1,
    title: 'Setting',
    tags: [
      'Forest',
      'Dungeon',
      'City',
      'Village',
      'Castle',
      'Mountains',
      'Desert',
      'Underwater',
      'Otherworldly',
      'Ruins',
    ],
  },
  {
    id: 2,
    title: 'Mood',
    tags: [
      'Mysterious',
      'Peaceful',
      'Tense',
      'Joyful',
      'Somber',
      'Chaotic',
      'Eerie',
      'Majestic',
      'Gloomy',
      'Festive',
    ],
  },
  {
    id: 3,
    title: 'Time',
    tags: [
      'Dawn',
      'Day',
      'Dusk',
      'Night',
      'Spring',
      'Summer',
      'Autumn',
      'Winter',
      'Timeless',
    ],
  },
  {
    id: 4,
    title: 'Weather',
    tags: [
      'Sunny',
      'Rainy',
      'Stormy',
      'Foggy',
      'Snowy',
      'Windy',
      'Calm',
      'Harsh',
      'Mild',
    ],
  },
  {
    id: 5,
    title: 'Magical Influence',
    tags: [
      'Cursed',
      'Enchanted',
      'Sacred',
      'Haunted',
      'Arcane',
      'Druidic',
      'Divine',
      'Elemental',
      'Illusionary',
      'Sealed',
    ],
  },
  {
    id: 6,
    title: 'Enemy Type',
    tags: [
      'Undead',
      'Beasts',
      'Bandits',
      'Monsters',
      'Cultists',
      'Dragons',
      'Elementals',
      'Fey',
      'Giants',
      'Constructs',
    ],
  },
  {
    id: 7,
    title: 'Combat Style',
    tags: [
      'Ambush',
      'Chase',
      'Siege',
      'Duel',
      'Skirmish',
      'Battle',
      'Stealth',
      'Magical',
      'Physical',
      'Ranged',
    ],
  },
  {
    id: 8,
    title: 'Terrain',
    tags: [
      'Open Field',
      'Narrow Passages',
      'Cliffs',
      'Urban',
      'Forest',
      'Caves',
      'Swamp',
      'River',
      'Airborne',
      'Magical Terrain',
      'Naval',
    ],
  },
  {
    id: 9,
    title: 'Challenge Level',
    tags: [
      'Easy',
      'Moderate',
      'Hard',
      'Deadly',
      'Puzzle',
      'Diplomatic',
      'Survival',
      'Chase',
      'Infiltration',
      'Escape',
    ],
  },
  {
    id: 10,
    title: 'Objective',
    tags: [
      'Defend',
      'Attack',
      'Survive',
      'Escape',
      'Capture',
      'Retrieve',
      'Discover',
      'Negotiate',
      'Explore',
      'Sabotage',
    ],
  },
  {
    id: 11,
    title: 'Special Conditions',
    tags: [
      'Time-Limited',
      'Environmental Hazard',
      'Moral Dilemma',
      'Trapped',
      'Undercover',
      'Illusory',
      'Infested',
      'Sacred Ground',
      'Resource-Scarce',
    ],
  },
];
