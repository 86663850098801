import React from 'react';

// Components
import { FiX, FiCornerLeftDown, FiCornerRightDown } from 'react-icons/fi';

// Redux
import { useSelector, useDispatch } from 'react-redux';
import {
  selectComparisonObjs,
  removeAnItem,
  elemChecker,
  resetCompareSlice,
  compareSelectedObjs,
} from '../../../redux/state-slices/compareSlice';

// Styles
import '../selected-content/selected.css';

export default function ComparisonHandler() {
  const comparingObjects = useSelector(selectComparisonObjs);

  const compareClass =
    comparingObjects && comparingObjects.length > 0
      ? 'mx-4 mt-3 border-2 border-zinc-600 rounded-lg'
      : 'mx-4 mt-3 border-2 border-zinc-600 rounded-lg invisible';

  return (
    <section className={compareClass}>
      <TopRow comparingObjects={comparingObjects} />

      <SelectedObjs comparingObjects={comparingObjects} />
    </section>
  );
}

const TopRow = ({ comparingObjects }) => {
  const dispatch = useDispatch();

  const clearAllObjs = () => {
    dispatch(resetCompareSlice());
  };

  const compareObjs = () => {
    dispatch(compareSelectedObjs(true));
  };

  return comparingObjects.length > 1 ? (
    <div className='mt-2 flex justify-center'>
      <button
        type='button'
        className='back-button mx-2 rounded-lg bg-gray-200 px-2 py-1 hover:scale-[1.03] active:scale-[0.98]'
        onClick={compareObjs}
      >
        <FiCornerLeftDown className='inline' /> Compare{' '}
        <FiCornerRightDown className='inline' />
      </button>
      <div className='v-seperator' />
      <button
        type='button'
        className='delete-content mx-2 flex items-center rounded-lg bg-gray-200 px-2 py-1'
        onClick={clearAllObjs}
      >
        <FiX className='mr-1 inline' />
        Reset
      </button>
    </div>
  ) : null;
};

const SelectedObjs = ({ comparingObjects }) => {
  const dispatch = useDispatch();

  const removeObj = (i, obj) => {
    dispatch(removeAnItem({ sender: 'comparison', idx: i }));

    const el = document.getElementById(`${obj.elId}`);

    if (el && el.checked) {
      el.checked = false;
    }
    dispatch(elemChecker({ elId: obj.elId, isChecked: false }));
  };
  return (
    <div className='selected-objs-cont mx-3 flex max-h-80 flex-col flex-wrap overflow-hidden'>
      {comparingObjects?.map((obj, i) => {
        return (
          // eslint-disable-next-line react/no-array-index-key
          <div key={i} className='my-[2px] flex items-center'>
            <button
              type='button'
              onClick={() => removeObj(i, obj)}
              className='clear-button mr-1 mt-1 inline items-center rounded-full bg-red-800 p-[2px]'
            >
              <FiX className='list-icon' />
            </button>{' '}
            {obj.title ? obj.title : obj.name}
          </div>
        );
      })}
    </div>
  );
};
