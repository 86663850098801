import dgnApi from './dgnAPI';

const abilitiesApi = dgnApi.injectEndpoints({
  endpoints: (build) => ({
    getAbilities: build.query({
      query: (plID) => ({
        url: `/api/abilities/${plID}`,
      }),
      providesTags: ['Abilities'],
    }),

    createAbility: build.mutation({
      query: (data) => {
        const { plID, ...body } = data;
        return {
          method: 'POST',
          url: `/api/ability/${plID}`,
          body,
        };
      },
      invalidatesTags: ['Abilities'],
    }),

    updateAbility: build.mutation({
      query: (data) => {
        const { id, ...body } = data;
        return {
          method: 'PUT',
          url: `/api/ability/${id}`,
          body,
        };
      },
      invalidatesTags: ['Abilities'],
    }),

    deleteAbility: build.mutation({
      query: (id) => ({
        method: 'DELETE',
        url: `/api/ability/${id}`,
      }),
      invalidatesTags: ['Abilities'],
    }),
  }),
});

export const {
  useGetAbilitiesQuery,
  useCreateAbilityMutation,
  useUpdateAbilityMutation,
  useDeleteAbilityMutation,
} = abilitiesApi;
