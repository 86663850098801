import React from 'react';
import { createRoot } from 'react-dom/client';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import * as reactRouterDom from 'react-router-dom';

// auth
import { getSuperTokensRoutesForReactRouterDom } from 'supertokens-auth-react';
import { SessionAuth } from 'supertokens-auth-react/recipe/session';

// redux
import { Provider } from 'react-redux';
import store from './redux/store';

// features
import App from './app/App';
import CreateGame from './features/game/CreateGame';
import LandingPage from './features/landing-page/LandingPage';
import PageNotFound404 from './app/PageNotFound404';
import Archive from './features/archive/Archive';
import Sessions from './features/sessions/Sessions';

// misc
import reportWebVitals from './reportWebVitals';
import './index.css';
import AssetCreator from './features/asset-creator/AssetCreator';
import CharacterDetail from './features/character-detail/CharacterDetail';
import Settings from './features/game/Settings';
import UserAccount from './features/user-account/UserAccount';

const container = document.getElementById('root');
const root = createRoot(container);
const domain = /https:\/\/[^/]+/;
const basename = process.env.PUBLIC_URL.replace(domain, '');

const router = createBrowserRouter(
  [
    ...getSuperTokensRoutesForReactRouterDom(reactRouterDom).map(
      (ru) => ru.props
    ),
    {
      element: <App />,
      errorElement: <PageNotFound404 />,
      children: [
        {
          path: '/',
          element: <LandingPage />,
        },
        {
          path: '/create-game',
          element: (
            <SessionAuth>
              <CreateGame />
            </SessionAuth>
          ),
        },
        {
          path: '/create-asset',
          element: (
            <SessionAuth>
              <AssetCreator />
            </SessionAuth>
          ),
        },
        {
          path: '/archive/:gameId',
          element: (
            <SessionAuth>
              <Archive />
            </SessionAuth>
          ),
        },
        {
          path: '/sessions/:gameId',
          element: (
            <SessionAuth>
              <Sessions />
            </SessionAuth>
          ),
        },
        // TODO: Update routing after initial scaffold
        {
          path: '/character',
          element: (
            <SessionAuth>
              <CharacterDetail />
            </SessionAuth>
          ),
        },
        {
          path: '/game/settings',
          element: (
            <SessionAuth>
              <Settings />
            </SessionAuth>
          ),
        },
        {
          path: '/my-account',
          element: (
            <SessionAuth>
              <UserAccount />
            </SessionAuth>
          ),
        },
      ],
    },
  ],
  {
    basename,
  }
);

root.render(
  <Provider store={store}>
    <RouterProvider router={router} />
  </Provider>
);

reportWebVitals();
