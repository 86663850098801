import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { signOut } from 'supertokens-auth-react/recipe/thirdpartyemailpassword';
import { redirectToAuth } from 'supertokens-auth-react';

import { GiCubes } from 'react-icons/gi';
import { MdHealthAndSafety } from 'react-icons/md';

import { useSelector, useDispatch } from 'react-redux';
import {
  setJWT,
  selectToken,
  selectCurrentUser,
  setLogout,
} from '../../../../redux/auth/authSlice';

import EmptyPlayerCard from './EmptyPlayerCard';
import { ReactComponent as DgnLogo } from '../../assets/dgnLogoInspo.svg';
import { ReactComponent as EmptyDgnLogo } from '../../assets/dgnLogoEmpty.svg';

import '../carousel.css';

function PlayerView({ playerData }) {
  const [openSlots, setOpenSlots] = useState(4);

  useEffect(() => {
    if (playerData && playerData.length) {
      setOpenSlots(4 - playerData.length);
    }
  }, [playerData]);

  return (
    <>
      {playerData?.map((char) => (
        <div
          className='embla__slide relative w-full max-w-md overflow-hidden rounded shadow-lg'
          key={char.id}
          elevation='5'
        >
          <div className='flex flex-row justify-between'>
            <Inspiration char={char} />
            <HP char={char} />
          </div>

          <div className='px-6 py-4'>
            {char.img_url && char.img_url !== 'No image assigned' ? (
              <CharImg char={char} />
            ) : (
              <NoCharImg char={char} />
            )}
            <CharDetails char={char} />
          </div>

          <EnterButton char={char} />
        </div>
      ))}
      <EmptyCards openSlots={openSlots} />
    </>
  );
}

const Inspiration = ({ char }) => {
  return (
    <div className='pb-4 pl-2 pt-1'>
      {char.inspiration ? (
        <DgnLogo className='inspo-icons animate-pulse' />
      ) : (
        <EmptyDgnLogo className='inspo-icons opacity-100' />
      )}
    </div>
  );
};

const HP = ({ char }) => {
  return (
    <div className='flex flex-row pr-2 pt-1'>
      <MdHealthAndSafety className='hp-logo' />
      <span className='pl-3 pt-2 text-lg text-[#420000]'>{char.hp}</span>
    </div>
  );
};

const CharImg = ({ char }) => {
  return (
    <div className='mx-auto h-[8rem] w-[16rem] overflow-hidden rounded-[50%]'>
      <img
        style={{
          width: '100%',
          height: '100%',
          objectFit: 'cover',
          objectPosition: 'top',
        }}
        id='test'
        src={char.img_url}
        alt=''
      />
    </div>
  );
};

const NoCharImg = () => {
  return (
    <div className='mx-auto h-[8rem] w-[16rem] rounded-[50%] bg-[#000] pb-4' />
  );
};

const CharDetails = ({ char }) => {
  return (
    <>
      <div className='mb-2 pt-8 text-center text-xl font-bold text-[#420000]'>
        {char.character_name}
      </div>

      <hr className='h-0.5 bg-[#420000]' />

      <div className='flex flex-row justify-between pt-2'>
        <p className='text-base text-[#672a2a]'>{char.classes}</p>
        <p className='text-base text-[#672a2a]'>{char.party_name}</p>
      </div>
    </>
  );
};

const EnterButton = ({ char }) => {
  const ref = useRef();
  const navigate = useNavigate();
  const t = useSelector(selectToken);
  const u = useSelector(selectCurrentUser);
  const dispatch = useDispatch();

  const joinGame = async (chID, gID, pID) => {
    if (ref.current) {
      ref.current.setAttribute('disabled', 'disabled');
    }

    const reqConfig = {
      method: 'POST',
      credentials: 'include',
      headers: {
        Authorization: `Bearer ${t}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        char: chID,
        party_id: pID,
        game: gID,
        user: u.username,
      }),
    };

    try {
      const res = await fetch(
        `${process.env.REACT_APP_USER_SERVICE}/enter/${gID}`,
        reqConfig
      );
      if (res.ok && res.status === 200) {
        const newToken = await res.json();
        dispatch(setJWT(newToken));
        navigate(`/archive/${gID}`);
      }
    } catch (err) {
      if (err instanceof TypeError) {
        await signOut();
        dispatch(setLogout());
        redirectToAuth({ show: 'signin' });
      }
    }
  };

  return (
    <button
      ref={ref}
      type='button'
      className='mx-auto mt-8 flex pb-2 text-center'
      onClick={() => joinGame(char.id, char.game_id, char.party_id)}
    >
      <span className='enter-button px-4 py-1'>Enter the DGN</span>
    </button>
  );
};

const EmptyCards = ({ openSlots }) => {
  const keys = [1, 2, 3, 4];

  return [...Array(openSlots)].map((e, i) => (
    <div
      className='embla__slide empty-slide relative w-full max-w-md overflow-hidden rounded shadow-lg'
      key={keys[i]}
      elevation='5'
    >
      <span className='blur-[4px]'>
        <EmptyPlayerCard />
      </span>

      <GiCubes className='slot-icon absolute right-0 top-0' stroke='#420000' />
    </div>
  ));
};

export default PlayerView;
