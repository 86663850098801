import React, { Fragment } from 'react';
import { useSessionContext } from 'supertokens-auth-react/recipe/session';
import { Disclosure, Menu, Transition } from '@headlessui/react';
import { NavLink, Link, useNavigate } from 'react-router-dom';
import { signOut } from 'supertokens-auth-react/recipe/thirdpartyemailpassword';
import { FiUser, FiX, FiMenu, FiChevronDown } from 'react-icons/fi';
import {
  GiArchiveResearch,
  GiGemPendant,
  GiHillConquest,
  GiCog,
  GiBookmark,
  GiLightningHelix,
  GiChecklist,
  GiMinions,
  GiFarmer,
  GiEvasion,
  GiScrollQuill,
} from 'react-icons/gi';
import { useDispatch, useSelector } from 'react-redux';
import { selectCurrentUser, setLogout } from '../../redux/auth/authSlice';
import { ReactComponent as DgnLogo } from '../../app/assets/dgnLogoWhite.svg';
import './navbar.css';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

function getNavTabs(location, isLoggedIn, sesh) {
  let items = [];
  let role;
  let gID;
  const inGame = /\/create-game|\/about|\/my-account|^\/$/;
  const isInGamePage = !inGame.test(location.pathname);
  if (isInGamePage) {
    role = sesh.loading === false && sesh.accessTokenPayload?.In_Game?.Role;
    gID = sesh.loading === false && sesh.accessTokenPayload?.In_Game?.Game;
  }

  if (!isLoggedIn) {
    items = [{ name: 'About', href: '/' }];
  } else if (isInGamePage && role === 'GM') {
    items = [
      { name: 'Archive', href: `/archive/${gID}`, key: 'GM' },
      { name: 'Sessions', href: `/sessions/${gID}` },
      { name: 'GameSettings', href: '/game/settings' },
      { name: 'Dropdown' },
    ];
  } else if (isInGamePage && role === 'Player') {
    items = [
      { name: 'Archive', href: `/archive/${gID}`, key: 'Player' },
      { name: 'Sessions', href: `/sessions/${gID}` },
      { name: 'Dropdown' },
    ];
  }

  return items;
}

export default function Navbar({ currentLocation }) {
  const sesh = useSessionContext();
  const isLoggedIn = sesh.doesSessionExist;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector(selectCurrentUser);

  const navigation = getNavTabs(currentLocation, isLoggedIn, sesh);

  const navWrapClass = isLoggedIn ? 'nav-wrapper relative z-10' : 'nav-wrapper';

  const handleLogout = async (e) => {
    e.preventDefault();
    dispatch(setLogout());
    navigate('/');
    await signOut();
  };

  return (
    <>
      <Disclosure as='nav' className={navWrapClass}>
        {({ open }) => (
          <div>
            <div className='test mx-auto max-w-[95rem] rounded-lg px-2 sm:px-6 lg:px-8'>
              <div className='relative flex h-16 items-center justify-between'>
                <div className='absolute inset-y-0 left-0 flex items-center sm:hidden'>
                  {/* Mobile menu button */}
                  <Disclosure.Button className='inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white'>
                    <span className='sr-only'>Open main menu</span>
                    <p className='flex h-6 w-6 text-white' aria-hidden='true'>
                      {open ? (
                        <FiX className='m-auto' />
                      ) : (
                        <FiMenu className='m-auto' />
                      )}
                    </p>
                  </Disclosure.Button>
                </div>
                <div className='flex flex-1 items-center justify-center sm:items-stretch sm:justify-start'>
                  <div className='flex shrink-0 items-center'>
                    <Link to='/'>
                      <DgnLogo className='h-10 w-full' />
                    </Link>
                  </div>
                  <div className='ml-4 hidden w-full sm:block'>
                    <div className='mx-auto flex space-x-4'>
                      {navigation.length > 0 &&
                        navigation.map((item) => (
                          <NavItems
                            screen='wide'
                            key={item.name}
                            item={item}
                            sesh={sesh}
                          />
                        ))}
                    </div>
                  </div>
                </div>

                <div className='absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0'>
                  {/* Profile dropdown */}
                  <Menu as='div' className='relative ml-3'>
                    <div>
                      <Menu.Button className='flex rounded-full bg-white text-sm focus:outline-1 '>
                        <span className='sr-only'>Open user menu</span>
                        {isLoggedIn ? (
                          <span className='profile-button'>
                            {user.username}
                          </span>
                        ) : (
                          <FiUser className='account-icon h-8 w-8' />
                        )}
                      </Menu.Button>
                    </div>
                    <Transition
                      as={Fragment}
                      enter='transition ease-out duration-100'
                      enterFrom='transform opacity-0 scale-95'
                      enterTo='transform opacity-100 scale-100'
                      leave='transition ease-in duration-75'
                      leaveFrom='transform opacity-100 scale-100'
                      leaveTo='transform opacity-0 scale-95'
                    >
                      <Menu.Items
                        className={`absolute right-0 z-10 mt-2 ${
                          isLoggedIn ? 'w-48' : 'w-max'
                        } origin-top-right rounded-md bg-white py-1 shadow-lg  focus:outline-none`}
                      >
                        {isLoggedIn ? (
                          <>
                            <p className='border-b border-b-gray-300 text-center'>
                              {user && user.username}
                            </p>
                            <Menu.Item>
                              {({ active }) => (
                                <button
                                  type='button'
                                  href='#'
                                  className={classNames(
                                    active ? 'bg-gray-100' : '',
                                    'mx-auto mt-1 block w-full px-4 py-2 text-sm text-gray-700'
                                  )}
                                  onClick={() => navigate('/my-account')}
                                >
                                  My account
                                </button>
                              )}
                            </Menu.Item>

                            <Menu.Item>
                              {({ active }) => (
                                <button
                                  type='button'
                                  onClick={handleLogout}
                                  className={classNames(
                                    active ? 'bg-gray-100' : '',
                                    'mx-auto block w-full px-4 py-2 text-sm text-gray-700'
                                  )}
                                >
                                  Sign out
                                </button>
                              )}
                            </Menu.Item>
                          </>
                        ) : (
                          <Menu.Item>
                            {({ active }) => (
                              <div
                                className={classNames(
                                  active ? 'bg-gray-100' : '',
                                  'mx-auto block w-full px-4 py-2 text-sm text-gray-700'
                                )}
                              >
                                <Link to='/auth'>Login</Link>
                              </div>
                            )}
                          </Menu.Item>
                        )}
                      </Menu.Items>
                    </Transition>
                  </Menu>
                </div>
              </div>
            </div>

            <Disclosure.Panel className='sm:hidden'>
              <div className='flex flex-col space-y-1 px-2 pb-3 pt-2'>
                {navigation.map((item) => (
                  <NavItems
                    screen='mobile'
                    key={item.name}
                    item={item}
                    sesh={sesh}
                  />
                ))}
              </div>
            </Disclosure.Panel>
          </div>
        )}
      </Disclosure>
      {isLoggedIn ? <NavWave /> : null}
    </>
  );
}

const NavItems = ({ item, screen, sesh }) => {
  const navClass = ({ isActive }) =>
    classNames(
      isActive ? 'nav-items border-b-white' : 'nav-items',
      'px-3 py-2 hover:border-b hover:border-b-white my-auto border-b border-b-transparent text-sm text-white font-medium'
    );

  const navComps = {
    Archive: (
      <NavLink to={item.href} className={navClass}>
        Archive <GiArchiveResearch className='mb-1 ml-1 inline scale-[1.25]' />
      </NavLink>
    ),
    About: (
      <NavLink to={item.href} className={navClass}>
        About/FAQ
      </NavLink>
    ),
    Sessions: (
      <NavLink
        to={item.href}
        className={navClass}
        state={{ sessionSelected: '' }}
      >
        Session logs <GiScrollQuill className='mb-1 ml-1 inline scale-[1.25]' />
      </NavLink>
    ),
    GameSettings: (
      <NavLink to={item.href} className={navClass}>
        Game/Party Settings <GiCog className='mb-1 ml-1 inline scale-[1.25]' />
      </NavLink>
    ),
    Dropdown: <NavDropdown screen={screen} sesh={sesh} />,
  };

  return navComps[item.name];
};

const NavDropdown = ({ screen, sesh }) => {
  const role = sesh.loading === false && sesh.accessTokenPayload?.In_Game.Role;
  const iconClass = 'inline scale-[1.25]';
  const navTitle = role === 'GM' ? 'Add lore' : 'My Character';
  const links =
    role === 'GM'
      ? [
          {
            href: '/create-asset',
            label: 'Location',
            icon: <GiHillConquest className={iconClass} />,
            state: { assetTypeFromNavButton: 'Location' },
          },
          {
            href: '/create-asset',
            label: 'Quest',
            icon: <GiChecklist className={iconClass} />,
            state: { assetTypeFromNavButton: 'Quest' },
          },
          {
            href: '/create-asset',
            label: 'NPC',
            icon: <GiFarmer className={iconClass} />,
            state: { assetTypeFromNavButton: 'NPC' },
          },
          {
            href: '/create-asset',
            label: 'Item',
            icon: <GiGemPendant className={iconClass} />,
            state: { assetTypeFromNavButton: 'Item' },
          },
          {
            href: '/create-asset',
            label: 'Group',
            icon: <GiMinions className={iconClass} />,
            state: { assetTypeFromNavButton: 'Group' },
          },
          {
            href: '/create-asset',
            label: 'Scene',
            icon: <GiEvasion className={iconClass} />,
            state: { assetTypeFromNavButton: 'Scene' },
          },
        ]
      : [
          {
            href: '/character',
            label: 'Character Sheet',
            icon: <GiCog className={iconClass} />,
            state: { pageTypeFromNavButton: 'Character Sheet' },
          },
          {
            href: '/character',
            label: 'Ability',
            icon: <GiLightningHelix className={iconClass} />,
            state: { pageTypeFromNavButton: 'Abilities' },
          },
          {
            href: '/character',
            label: 'Journal',
            icon: <GiBookmark className={iconClass} />,
            state: { pageTypeFromNavButton: 'Journal' },
          },
        ];

  return (
    <Menu as='div' className='relative'>
      <div>
        <Menu.Button className='m-0 border-b border-b-transparent px-3 py-2 text-sm font-medium text-white hover:border-b hover:border-b-white active:border-b-white'>
          {navTitle} <FiChevronDown className='mb-1 ml-1 inline scale-[1.25]' />
        </Menu.Button>
      </div>
      <Transition
        as={Fragment}
        enter='transition ease-out duration-100'
        enterFrom='transform opacity-0 scale-95'
        enterTo='transform opacity-100 scale-100'
        leave='transition ease-in duration-75'
        leaveFrom='transform opacity-100 scale-100'
        leaveTo='transform opacity-0 scale-95'
      >
        <Menu.Items
          className={`absolute ${
            screen === 'wide' ? 'left-[-30%]' : 'left-0'
          } z-10 mt-2 w-40 rounded-md border border-[#420000] bg-white py-1 shadow-lg focus:outline-none`}
        >
          {links.map((link) => {
            return (
              <Menu.Item key={link.label} className='w-full text-gray-900'>
                {({ active }) => (
                  <NavLink
                    to={link.href}
                    state={link?.state}
                    className={classNames(
                      active ? 'bg-gray-100' : '',
                      'flex justify-between px-4 py-2 text-sm'
                    )}
                  >
                    {link.label} {link.icon}
                  </NavLink>
                )}
              </Menu.Item>
            );
          })}
        </Menu.Items>
      </Transition>
    </Menu>
  );
};

const NavWave = () => {
  return (
    <div className='navwave'>
      <svg
        data-name='Layer 1'
        xmlns='http://www.w3.org/2000/svg'
        viewBox='0 0 1200 120'
        preserveAspectRatio='none'
      >
        <path
          d='M0,0V46.29c47.79,22.2,103.59,32.17,158,28,70.36-5.37,136.33-33.31,206.8-37.5C438.64,32.43,512.34,53.67,583,72.05c69.27,18,138.3,24.88,209.4,13.08,36.15-6,69.85-17.84,104.45-29.34C989.49,25,1113-14.29,1200,52.47V0Z'
          opacity='.25'
          className='shape-fill'
        ></path>
        <path
          d='M0,0V15.81C13,36.92,27.64,56.86,47.69,72.05,99.41,111.27,165,111,224.58,91.58c31.15-10.15,60.09-26.07,89.67-39.8,40.92-19,84.73-46,130.83-49.67,36.26-2.85,70.9,9.42,98.6,31.56,31.77,25.39,62.32,62,103.63,73,40.44,10.79,81.35-6.69,119.13-24.28s75.16-39,116.92-43.05c59.73-5.85,113.28,22.88,168.9,38.84,30.2,8.66,59,6.17,87.09-7.5,22.43-10.89,48-26.93,60.65-49.24V0Z'
          opacity='.5'
          className='shape-fill'
        ></path>
        <path
          d='M0,0V5.63C149.93,59,314.09,71.32,475.83,42.57c43-7.64,84.23-20.12,127.61-26.46,59-8.63,112.48,12.24,165.56,35.4C827.93,77.22,886,95.24,951.2,90c86.53-7,172.46-45.71,248.8-84.81V0Z'
          className='shape-fill'
        ></path>
      </svg>
    </div>
  );
};
