import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { FiCalendar, FiArrowRight } from 'react-icons/fi';
import {
  GiDiceTwentyFacesTwenty,
  GiD12,
  GiD10,
  GiDiceEightFacesEight,
  GiPerspectiveDiceSix,
  GiD4,
} from 'react-icons/gi';
import LoadingSpinner from '../loading-ani/LoadingSpinner';
import { useGetSessionsQuery } from '../../redux/api-slices/sessionAPI';
import { setSessions } from '../../redux/state-slices/sessionSlice';
import './archive.css';

export default function SessionTimeline({ gID }) {
  const [skip, setSkip] = useState(true);
  const { data: sessions, isLoading } = useGetSessionsQuery(gID, { skip });
  const dispatch = useDispatch();
  const [firstSesh, setFirstSesh] = useState(null);
  const [selected, setSelected] = useState(null);

  const calcDiff = (d1, d2) => {
    const diffSec = d1.getTime() - d2.getTime();
    const diffDays = diffSec / (1000 * 3600 * 24);
    return Math.abs(diffDays);
  };

  useEffect(() => {
    if (sessions) {
      const first = sessions[0];
      setFirstSesh(first);
      dispatch(setSessions(sessions));
    }

    if (gID) {
      setSkip(false);
    }
  }, [sessions]);

  const handleSelect = (e, sesh) => {
    setSelected(sesh);
  };

  return (
    <div className='sessions-wrapper mx-4 mb-4 flex h-[10rem] w-auto rounded-lg'>
      {isLoading ? (
        <div className='flex h-full w-full items-center justify-center'>
          <LoadingSpinner classStyle='loading-ani-white' />
        </div>
      ) : !sessions?.length ? (
        <NoSessions />
      ) : (
        <ol className='sessions-list-cont m-1 flex flex-row-reverse overflow-y-hidden overflow-x-scroll pb-4'>
          {sessions?.map((sesh, idx) => {
            let timedelta;
            if (idx !== 0) {
              const next = sessions[idx - 1];
              timedelta = calcDiff(new Date(next.date), new Date(sesh.date));
            }
            const distance = timedelta / 7 || '90%';
            const width = distance === '90%' ? '90%' : `${distance * 6}rem`;

            return (
              <li
                key={sesh.id}
                className='list-main mr-1 flex flex-col flex-nowrap'
              >
                <TimelineTop sesh={sesh} firstSesh={firstSesh} width={width} />

                <div
                  tabIndex={idx}
                  onFocus={(e) => handleSelect(e, sesh)}
                  className={
                    selected && selected.id === sesh.id
                      ? `session-item z-10 flex cursor-pointer flex-col bg-white p-2`
                      : 'session-item flex flex-col p-2'
                  }
                >
                  <SessionBlurb
                    sesh={sesh}
                    selected={selected}
                    width={width}
                    gID={gID}
                  />
                </div>
              </li>
            );
          })}
        </ol>
      )}
    </div>
  );
}

const TimelineTop = ({ sesh, firstSesh, width }) => {
  return (
    <div className='flex items-center'>
      <FiCalendar className='min-h-4 min-w-4 mb-1 mr-1 text-white' />
      {sesh?.id === firstSesh?.id ? null : (
        <div
          className='h-0.5 grow bg-white'
          style={{
            width: `${width}`,
          }}
        />
      )}
    </div>
  );
};

const SessionBlurb = ({ sesh, selected, width, gID }) => {
  return (
    <>
      <time className='session-date'>{sesh.date}</time>
      <h3
        className={
          selected && selected.id === sesh.id
            ? `session-title text-gray-900`
            : 'session-title text-white'
        }
        style={{ width: `${width}` }}
      >
        {sesh.title}
      </h3>
      <Link
        className={
          selected?.id === sesh.id
            ? 'view-session w-min rounded-lg bg-[#420000] px-4 py-1 hover:cursor-pointer'
            : 'hidden'
        }
        to={`/sessions/${gID}`}
        state={{ sessionSelected: sesh }}
      >
        View details
        <FiArrowRight className='ml-1 inline' />
      </Link>
    </>
  );
};

const NoSessions = () => {
  return (
    <div className='m-auto flex flex-col text-xl text-white'>
      <div className='mb-3 flex justify-between'>
        <GiDiceTwentyFacesTwenty /> <GiD12 /> <GiD10 />
      </div>
      <span>Thank you for playtesting our app!</span>
      <div className='h-2' />
      <span>Logged sessions will appear here.</span>
      <div className='mt-3 flex justify-between'>
        <GiDiceEightFacesEight /> <GiPerspectiveDiceSix /> <GiD4 />
      </div>
    </div>
  );
};
