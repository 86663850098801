import { createSlice } from '@reduxjs/toolkit';
import { resetAll } from './compareSlice';

const initialState = {
  allScenes: [],
  selectedScene: null,
};

const sceneSlice = createSlice({
  name: 'scene',
  initialState,
  extraReducers: (obj) => obj.addCase(resetAll, () => initialState),
  reducers: {
    setScenes: (state, action) => {
      state.allScenes = action.payload;
    },

    setOneScene: (state, action) => {
      state.selectedScene = action.payload;
    },
  },
});

export const { setScenes, setOneScene } = sceneSlice.actions;

export default sceneSlice.reducer;

export const selectAllScenes = (state) => state.scenes.allScenes;
export const selectOneScene = (state) => state.scenes.selectedScene;
