import React from 'react';

import './loading.css';

function LoadingSpinner({ classStyle }) {
  return (
    <div className={`loading-ani-base ${classStyle}`}>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
}

export default LoadingSpinner;
