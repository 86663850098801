import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

// Components
import { FiEye, FiEyeOff } from 'react-icons/fi';

import ItemDetails from './content-details/ItemDetails';
import CheckboxCompare from '../misc-components/CheckboxCompare';
import { spacesDelete } from '../../../digitalocean/spaces';

// Redux
import {
  selectAllItems,
  setItems,
  setOneItem,
} from '../../../redux/state-slices/itemSlice';
import { useDeleteItemMutation } from '../../../redux/api-slices/itemAPI';

// styles
import './selected.css';
import ViewButton, {
  CreateAndRefetch,
  FlowModal,
  GMButtons,
  NoAssets,
} from '../misc-components/MiscListComps';
import AssetEditorModal from '../../asset-editor-modal/AssetEditorModal';
import {
  displayAlert,
  setAlertInfo,
} from '../../../redux/state-slices/alertSlice';
import { DeleteFail } from '../../alerts/ResponseAlerts';
import SubmitLoader from '../../loading-ani/SubmitLoader';

export default function ItemContent({ role, username, refetcher }) {
  const items = useSelector(selectAllItems);

  const [itemDetails, setItemDetails] = useState(null);

  return itemDetails ? (
    <ItemDetails setItemDetails={setItemDetails} role={role} />
  ) : (
    <div className='list-content'>
      <CreateAndRefetch role={role} refetcher={refetcher} asset='Item' />

      {items?.length === 0 ? (
        <NoAssets asset='items' />
      ) : (
        <div className='content-body mt-3 flex columns-3 flex-col overflow-y-auto overflow-x-hidden p-4'>
          <ItemList
            items={items}
            role={role}
            setItemDetails={setItemDetails}
            username={username}
          />
        </div>
      )}
    </div>
  );
}

const ItemList = ({ items, role, setItemDetails, username }) => {
  const dispatch = useDispatch();
  const [openModal, setOpenModal] = useState(undefined);
  const [obj, setObj] = useState(null);
  const [itemDelete] = useDeleteItemMutation();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const selectOne = (item) => {
    dispatch(setOneItem(item));
    setItemDetails(true);
  };

  const failedDeleteAlert = () => {
    dispatch(setAlertInfo(DeleteFail));
    dispatch(displayAlert());
  };

  const deleteItem = async () => {
    setOpenModal(undefined);
    setIsSubmitting(true);
    const isLastDeleted = items.length === 1 ? true : null;
    const img = obj?.img_url;

    try {
      if (img) {
        await spacesDelete(img, username);
      }
      const itemDelRes = await itemDelete(obj.id);
      if ('data' in itemDelRes && itemDelRes.data) {
        dispatch(
          setAlertInfo({
            alertType: 'success',
            message: `${obj.name} deleted`,
          })
        );
        dispatch(displayAlert());
      } else if (
        ('data' in itemDelRes && itemDelRes.data === false) ||
        'detail' in itemDelRes ||
        'error' in itemDelRes
      ) {
        failedDeleteAlert();
      }
    } catch (err) {
      failedDeleteAlert();
    }
    setObj(null);

    if (isLastDeleted) {
      dispatch(setItems([]));
    }
    setIsSubmitting(false);
  };

  return (
    <>
      {isSubmitting && <SubmitLoader message='Deleting' />}
      {items?.map((item) => (
        <div
          className='content-item flex flex-row justify-between'
          key={item.id}
        >
          <span className='m-2 py-2'>
            <CheckboxCompare
              obj={item}
              category='item'
              elId={`checkbox-item-${item.id}`}
            />
            {item.name}
          </span>
          <div className='list-button-container flex'>
            <span className='m-2 flex items-center px-4 py-2'>
              {role && role === 'GM' ? (
                item.is_hidden ? (
                  <FiEyeOff className='mr-1 inline text-violet-600 opacity-100' />
                ) : (
                  <FiEye className='mr-1 inline text-green-600 opacity-100' />
                )
              ) : null}

              <span className='mx-1 text-violet-600'>{item?.rarity}</span>
            </span>

            <ViewButton obj={item} selectOne={selectOne} />

            {role && role === 'GM' ? (
              <>
                <GMButtons
                  obj={item}
                  setSelected={setObj}
                  setOpenModal={setOpenModal}
                  setOne={setOneItem}
                />

                <FlowModal
                  openModal={openModal}
                  setOpenModal={setOpenModal}
                  obj={obj}
                  deleteFunc={deleteItem}
                />
              </>
            ) : null}
          </div>
        </div>
      ))}

      {obj && (
        <AssetEditorModal
          openModal={openModal}
          setOpenModal={setOpenModal}
          oldFormData={obj}
          assetType='Item'
        />
      )}
    </>
  );
};
