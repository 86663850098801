import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSessionContext } from 'supertokens-auth-react/recipe/session';
import { Button, Modal } from 'flowbite-react';
import { FiAlertTriangle } from 'react-icons/fi';
import { signOut } from 'supertokens-auth-react/recipe/thirdpartyemailpassword';
import { useDispatch } from 'react-redux';
import {
  useDeleteAllGamesMutation,
  useGetGamesQuery,
} from '../../redux/api-slices/gameAPI';
import {
  useDeleteAllCharsMutation,
  useGetUsersCharactersQuery,
} from '../../redux/api-slices/characterAPI';
import {
  displayAlert,
  setAlertInfo,
} from '../../redux/state-slices/alertSlice';
import SubmitLoader from '../loading-ani/SubmitLoader';
import './userAccount.css';
import { setUser } from '../../redux/auth/authSlice';
import { gatherAssets, spacesDelete } from '../../digitalocean/spaces';

export default function UserAccount() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const sesh = useSessionContext();
  const { username } = sesh.accessTokenPayload;
  const { userId } = sesh;
  const [skip, setSkip] = useState(true);
  const { data: games } = useGetGamesQuery(username, { skip });
  const { data: chars } = useGetUsersCharactersQuery(username, { skip });
  const [deleteAllGames] = useDeleteAllGamesMutation();
  const [deleteAllChars] = useDeleteAllCharsMutation();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [openModal, setOpenModal] = useState(undefined);

  const failedDeleteAccountAlert = () => {
    dispatch(
      setAlertInfo({
        alertType: 'failure',
        message:
          'An error occured while attempting to delete your account, please email us and we will resolve this issue!',
      })
    );
    dispatch(displayAlert());
  };
  const deleteFromDB = async (url, config) => {
    try {
      const delAccountRes = await fetch(url, config);
      if (!delAccountRes.ok) {
        failedDeleteAccountAlert();
      }
    } catch (err) {
      failedDeleteAccountAlert();
    }
  };

  const deleteAllImgs = async () => {
    try {
      const images = await gatherAssets(username);
      images.forEach((img) =>
        spacesDelete(`${process.env.REACT_APP_BUCKET_URL}${img?.Key}`, username)
      );
    } catch (err) {
      dispatch(
        setAlertInfo({
          alertType: 'failure',
          message: 'There were issues deleting your images',
        })
      );
      dispatch(displayAlert());
    }
  };

  const handleDelete = async () => {
    setOpenModal(undefined);
    setIsSubmitting(true);
    const url = `${process.env.REACT_APP_USER_SERVICE}/delete/account/${userId}`;
    const reqConfig = {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
      },
    };
    await deleteAllImgs();

    await deleteFromDB(url, reqConfig);

    try {
      if (games.length > 0) {
        await deleteAllGames(username);
      }

      if (chars.length > 0) {
        await deleteAllChars(userId);
      }
    } catch (err) {
      failedDeleteAccountAlert();
    }

    setIsSubmitting(false);
    dispatch(setUser({ email: '', signedIn: false }));
    navigate('/');
    await signOut();
  };

  return (
    <div className='user-account-main-parent min-h-screen'>
      <div className='user-account-content-container relative flex min-h-screen flex-col items-center justify-center'>
        {isSubmitting && <SubmitLoader message='Deleting your account' />}
        <DeleteAccountModal
          openModal={openModal}
          setOpenModal={setOpenModal}
          deleteFunc={handleDelete}
        />
        <div className='absolute top-0 rounded-lg bg-[#074f57] p-4 text-center text-white'>
          there will be more customization options in the future
        </div>

        <h3 className='mt-8 rounded-md bg-[#420000] px-16 py-3 text-xl text-white'>
          {username && username}
        </h3>

        <div className='my-8 flex justify-center gap-4'>
          <button
            type='button'
            onClick={() => {
              setSkip(false);
              setOpenModal('pop-up');
            }}
            className='delete-precious-resource-button rounded-lg bg-red-700 px-4 py-2 text-white'
          >
            Delete account
          </button>
        </div>
      </div>
    </div>
  );
}

const ImplyDanger = ({ wordToEmphasize }) => {
  return <span className='font-semibold text-red-400'>{wordToEmphasize}</span>;
};

const DeleteAccountModal = ({ openModal, setOpenModal, deleteFunc }) => {
  const modalTheme = {
    root: {
      show: {
        on: 'flex bg-white bg-opacity-40',
      },
    },

    content: {
      inner: 'border-red-600 border-2 rounded-lg bg-white',
    },
  };

  return (
    <Modal
      theme={modalTheme}
      show={openModal === 'pop-up'}
      size='md'
      popup
      onClose={() => setOpenModal(undefined)}
    >
      <Modal.Header />
      <Modal.Body>
        <div className='text-center'>
          <FiAlertTriangle className='mx-auto mb-4 h-14 w-14 text-red-600' />
          <h3 className='mb-5 text-lg font-normal text-gray-500'>
            Are you sure you want to delete your account? This will{' '}
            <ImplyDanger wordToEmphasize='permanently' /> and{' '}
            <ImplyDanger wordToEmphasize='irreversibly' /> delete{' '}
            <ImplyDanger wordToEmphasize='all' /> games and characters. You will
            not be able to log in with this account anymore.
          </h3>
          <div className='flex justify-center gap-4'>
            <Button color='failure' onClick={deleteFunc}>
              Yes, I am sure
            </Button>
            <Button color='warning' onClick={() => setOpenModal(undefined)}>
              No, cancel
            </Button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};
