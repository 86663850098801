import React, { useState } from 'react';
import { useSelector } from 'react-redux';

// Components
import BackAndFullscreen from '../../misc-components/BackAndFullscreen';
import {
  GMDetailHeader,
  GMLocationHeader,
  LocationHeader,
  ObjDetailImg,
  RelatedInfo,
  DetailMD,
} from '../../misc-components/MiscDetailComps';

// Redux
import { selectOneFaction } from '../../../../redux/state-slices/factionSlice';

// Styles
import '../selected.css';

export default function FactionDetails({ setFactionDetails, role }) {
  const currentFaction = useSelector(selectOneFaction);

  return (
    <div className='selected-content-details-wrap'>
      <BackAndFullscreen setStateFunc={setFactionDetails} />

      <FactionInfo currentFaction={currentFaction} role={role} />
    </div>
  );
}

export const FactionInfo = ({ currentFaction, role }) => {
  return (
    <div className='selected-content-details'>
      <div className='border-image-container'>
        <DetailsInfo currentFaction={currentFaction} role={role} />
      </div>
    </div>
  );
};

const DetailsInfo = ({ currentFaction, role }) => {
  const [isActive, setIsActive] = useState(true);

  return (
    // h-[59.1rem]
    <div className='asset-info-main relative'>
      <TopRow currentFaction={currentFaction} />

      <div className='info-description'>
        <div className='mt-0 h-[40rem] w-full'>
          <ObjDetailImg currentObj={currentFaction} />
        </div>
        <hr className='details-seperating-bar mx-4 mt-2 h-0' />

        <div className='details-chunk'>
          <DetailMD details={currentFaction.public_details} />
        </div>

        {role === 'GM' ? (
          <>
            <GMDetailHeader isActive={isActive} setIsActive={setIsActive} />

            {currentFaction.hidden_details ? (
              <div
                className={`${
                  isActive ? 'max-h-full' : 'invisible max-h-0'
                } details-chunk overflow-hidden transition-all duration-500 ease-in-out`}
              >
                <DetailMD details={currentFaction?.hidden_details} />
              </div>
            ) : (
              <p
                className={`${
                  isActive ? 'max-h-full' : 'invisible max-h-0'
                } details-chunk overflow-hidden transition-all duration-500 ease-in-out`}
              >
                No hidden notes to show.
              </p>
            )}
          </>
        ) : null}
        <MiscInfo currentFaction={currentFaction} role={role} />
      </div>
    </div>
  );
};

const TopRow = ({ currentFaction }) => {
  const hasDataClass = currentFaction.location
    ? 'ml-1 mr-3 text-violet-600'
    : 'ml-1 m4-3';

  return (
    <>
      <div className='info-top-row mt-2 text-center'>
        <span className='asset-title'>{currentFaction.name}</span>
      </div>
      <div className='mt-3 flex justify-center'>
        Location:{' '}
        <span className={hasDataClass}>
          {currentFaction?.location || 'Unknown'}
        </span>
      </div>
      <hr className='details-seperating-bar mb-0 mt-1' />
    </>
  );
};

const MiscInfo = ({ currentFaction, role }) => {
  const [isActiveLoc, setIsActiveLoc] = useState(true);
  const [isActiveLocGM, setIsActiveLocGM] = useState(false);
  const factionName = (
    <span className='text-violet-600'>{currentFaction.name}</span>
  );

  return (
    <>
      <RelatedInfo />

      <LocationHeader isActive={isActiveLoc} setIsActive={setIsActiveLoc} />

      <div
        className={`${
          isActiveLoc ? 'max-h-full' : 'invisible max-h-0'
        } mx-4 overflow-hidden transition-all duration-500 ease-in-out`}
      >
        {currentFaction.location_details ? (
          <DetailMD details={currentFaction.location_details} />
        ) : (
          <span>{factionName}&apos;s location details are unknown.</span>
        )}
      </div>

      {role === 'GM' ? (
        <>
          <GMLocationHeader
            isActive={isActiveLocGM}
            setIsActive={setIsActiveLocGM}
          />

          <div
            className={`${
              isActiveLocGM ? 'max-h-full' : 'invisible max-h-0'
            } mx-4 overflow-hidden transition-all duration-500 ease-in-out`}
          >
            {currentFaction.location_hidden_details ? (
              <DetailMD details={currentFaction.location_hidden_details} />
            ) : (
              <>No hidden location notes to display.</>
            )}
          </div>
        </>
      ) : null}
    </>
  );
};
