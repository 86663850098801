import { createSlice } from '@reduxjs/toolkit';
import { resetAll } from './compareSlice';

const initialState = {
  allItems: [],
  selectedItem: null,
};

const itemSlice = createSlice({
  name: 'item',
  initialState,
  extraReducers: (obj) => obj.addCase(resetAll, () => initialState),
  reducers: {
    setItems: (state, action) => {
      state.allItems = action.payload;
    },

    setOneItem: (state, action) => {
      state.selectedItem = action.payload;
    },
  },
});

export const { setItems, setOneItem } = itemSlice.actions;

export default itemSlice.reducer;

export const selectAllItems = (state) => state.items.allItems;
export const selectOneItem = (state) => state.items.selectedItem;
