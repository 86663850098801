import React from 'react';
import { Link } from 'react-router-dom';
import { Modal, Button } from 'flowbite-react';
import {
  FiAlertTriangle,
  FiArrowUp,
  FiTrash,
  FiEdit,
  FiPlusCircle,
  FiRefreshCcw,
} from 'react-icons/fi';

import { useDispatch } from 'react-redux';
import '../selected-content/selected.css';

export const CreateAndRefetch = ({ role, refetcher, asset }) => {
  const RefetchButton = (
    <button
      type='button'
      className='refetch-content-button rounded-lg bg-gray-200 px-4 py-2'
      onClick={refetcher}
    >
      <FiRefreshCcw className='mr-1 inline' />
      Refetch
    </button>
  );

  const CreateButton = (
    <Link
      to='/create-asset'
      state={{ assetTypeFromNavButton: asset }}
      className='create-content-button rounded-lg bg-gray-200 px-4 py-2 text-green-800'
    >
      <FiPlusCircle className='mr-1 inline' />
      {`New ${asset}`}
    </Link>
  );

  return (
    <div className='content-top-row mt-2 flex justify-center gap-4'>
      {role && role === 'GM' ? (
        <>
          {CreateButton}
          {RefetchButton}
        </>
      ) : (
        RefetchButton
      )}
    </div>
  );
};

export default function ViewButton({ obj, selectOne }) {
  return (
    <button
      type='button'
      className='row-button view-content rounded-lg bg-gray-200'
      onClick={() => selectOne(obj)}
    >
      <FiArrowUp className='list-icon mr-1 inline' /> View
    </button>
  );
}

export const GMButtons = ({ obj, setSelected, setOpenModal, setOne }) => {
  const dispatch = useDispatch();
  return (
    <>
      <button
        type='button'
        className='row-button edit-content rounded-lg bg-gray-200'
        onClick={() => {
          setSelected(obj);
          setOpenModal('default');
          dispatch(setOne(obj));
        }}
      >
        <FiEdit className='list-icon mr-1 inline' /> Edit
      </button>
      <button
        type='button'
        className='row-button delete-content rounded-lg bg-gray-200'
        onClick={() => {
          setSelected(obj);
          setOpenModal('pop-up');
        }}
      >
        <FiTrash className='list-icon mr-1 inline' /> Delete
      </button>
    </>
  );
};

export const FlowModal = ({ openModal, setOpenModal, obj, deleteFunc }) => {
  const modalTheme = {
    root: {
      show: {
        on: 'flex bg-white bg-opacity-40',
      },
    },

    content: {
      inner: 'border-red-600 border-2 rounded-lg bg-white',
    },
  };

  return (
    <Modal
      theme={modalTheme}
      show={openModal === 'pop-up'}
      size='md'
      popup
      onClose={() => setOpenModal(undefined)}
    >
      <Modal.Header />
      <Modal.Body>
        <div className='text-center'>
          <FiAlertTriangle className='mx-auto mb-4 h-14 w-14 text-red-600' />
          <h3 className='mb-5 text-lg font-normal text-gray-500'>
            Are you sure you want to delete{' '}
            <span className='text-red-400'>{obj?.name || obj?.title}</span>?
            This action cannot be reversed.
          </h3>
          <div className='flex justify-center gap-4'>
            <Button color='failure' onClick={deleteFunc}>
              Yes, I am sure
            </Button>
            <Button color='warning' onClick={() => setOpenModal(undefined)}>
              No, cancel
            </Button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export const NoAssets = ({ asset }) => {
  return (
    <div className='relative h-full'>
      <div className='no-assets absolute left-1/2 top-1/4 -translate-x-1/2 rounded-lg px-8 py-4'>
        No {asset} have been created yet!
      </div>
    </div>
  );
};
