import { createSlice } from '@reduxjs/toolkit';
import { resetAll } from './compareSlice';

const initialState = {
  allQuests: [],
  selectedQuest: null,
};

const questSlice = createSlice({
  name: 'quest',
  initialState,
  extraReducers: (obj) => obj.addCase(resetAll, () => initialState),
  reducers: {
    setQuests: (state, action) => {
      state.allQuests = action.payload;
    },

    setOneQuest: (state, action) => {
      state.selectedQuest = action.payload;
    },
  },
});

export const { setQuests, setOneQuest } = questSlice.actions;

export default questSlice.reducer;

export const selectAllQuests = (state) => state.quests.allQuests;
export const selectOneQuest = (state) => state.quests.selectedQuest;
