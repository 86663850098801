import React from 'react';
import { Link } from 'react-router-dom';
import { HiMiniUserGroup } from 'react-icons/hi2';
import { GiRollingDices } from 'react-icons/gi';
import { ReactComponent as Divider } from '../assets/waves.svg';
import { ReactComponent as DividerBottom } from '../assets/wavesBottom.svg';
import { ReactComponent as WavesTop } from '../assets/wavesTop.svg';
import { ReactComponent as DgnLogo } from '../../../app/assets/dgnLogo.svg';

import HeroCarousel from './HeroCarousel';

import './content.css';

export default function LandingContent() {
  return (
    <>
      <WavesTop className='waves-top w-full' />
      <div className='relative w-full'>
        <HeroContent />

        <Divider className='divider' />

        <div className='bg-waves mb-0 flex flex-col gap-y-4'>
          <div className='hero-cards-cont mx-auto flex max-w-[95rem] flex-col items-center gap-y-8 py-4'>
            <div className='flex justify-center'>
              <Organize />
            </div>

            <div className='cards-bottom-row mx-2 flex justify-between'>
              <Gather />

              <Play />
            </div>
          </div>

          <div className='feature-showcase mx-auto flex max-w-[95rem] flex-col items-center rounded-lg bg-white p-2'>
            <div className='roadmap-img h-full w-full rounded-lg'></div>
          </div>
        </div>
        <DividerBottom className='divider-merger m-0 h-full w-full' />

        <JumboTron />
      </div>

      <WavesFooter />
    </>
  );
}

const HeroContent = () => {
  return (
    <div className='hero-cont mx-auto mt-2 max-w-[95rem]'>
      <div className='hero-main relative h-full w-full p-4'>
        <div className='main-header mt-16 flex w-full justify-center pt-1'>
          DGNKEEPER
        </div>
        <div className='sub-header flex flex-col items-center justify-center px-[5rem]'>
          <p className='text-gray-500'>Tabletop RPG Management Tool</p>
          <Link to='/auth' className='cta-button mb-8 mt-6 rounded-full'>
            Sign in
          </Link>
        </div>
        <HeroCarousel />
      </div>
    </div>
  );
};

const Organize = () => {
  return (
    <div className='promo-cards mx-2 w-full'>
      <div className='card-top mt-2 flex w-full justify-center'>
        <DgnLogo className='h-12 w-12' />
      </div>
      <div className='title my-4 flex justify-center font-bold'>Organize</div>
      <hr className='mx-2 h-0.5 bg-[#420000]' />
      <div className='card-details mx-2 my-3 text-gray-900'>
        <ul className='flex flex-col gap-y-2'>
          <li>
            <span className='font-semibold'>GMs in Control:</span> Game Masters
            can effortlessly organize and share campaign content, from vivid
            descriptions to images, in one convenient hub
          </li>

          <li>
            <span className='font-semibold'>Campaign Privacy:</span> Choose
            whether to reveal campaign assets to your players or keep them
            hidden for added intrigue
          </li>

          <li>
            <span className='font-semibold'>Player Empowerment:</span> Players
            can create and manage characters, explore campaign data, and keep
            session notes, all in one place
          </li>
        </ul>
      </div>
    </div>
  );
};

const Gather = () => {
  return (
    <div className='promo-cards promo-gather mr-2 w-full'>
      <div className='card-top mt-2 flex w-full justify-center'>
        <HiMiniUserGroup className='h-12 w-12' />
      </div>
      <div className='title my-4 flex justify-center font-bold'>Gather</div>
      <hr className='mx-2 h-0.5 bg-[#420000]' />
      <div className='card-details mx-2 my-3 text-gray-900'>
        <ul className='flex flex-col gap-y-2'>
          <li>
            <span className='font-semibold'>Digital Campaigns:</span> Players
            can join their GM&apos;s game with a passcode, making it easier than
            ever to connect with your gaming group
          </li>

          <li>
            <span className='font-semibold'>Celebrate Success:</span> Mark
            character achievements, victories, and milestones together, creating
            a sense of achievement as a group
          </li>

          <li>
            <span className='font-semibold'>Inclusivity:</span> Encourage
            inclusivity by welcoming new players and forging bonds in a shared
            love for storytelling
          </li>
        </ul>
      </div>
    </div>
  );
};

const Play = () => {
  return (
    <div className='promo-cards promo-play ml-2 w-full'>
      <div className='card-top mt-2 flex w-full justify-center'>
        <GiRollingDices className='h-12 w-12' />
      </div>
      <div className='title my-4 flex justify-center font-bold'>Play</div>
      <hr className='mx-2 h-0.5 bg-[#420000]' />
      <div className='card-details mx-2 my-3 text-gray-900'>
        <ul className='flex flex-col gap-y-2'>
          <li>
            <span className='font-semibold'>Session Summaries:</span> GMs can
            log session summaries to keep the story on track and ensure
            everyone&apos;s on the same page
          </li>

          <li>
            <span className='font-semibold'>Character Evolution:</span> Track
            character development with our character sheets, ensuring that every
            level-up and skill improvement is captured
          </li>

          <li>
            <span className='font-semibold'>Accessible Anywhere:</span> Access
            your campaign from any device with an internet connection, making it
            easy to dive into the action wherever you are
          </li>
        </ul>
      </div>
    </div>
  );
};

const JumboTron = () => {
  return (
    <section className='my-6 bg-white'>
      <div className='mx-auto max-w-screen-xl px-4 py-8 text-center lg:py-16'>
        <h1 className='mb-4 text-4xl font-extrabold leading-none tracking-tight text-gray-900 md:text-5xl lg:text-6xl'>
          Join the Adventure
        </h1>
        <p className='mb-8 text-lg font-normal text-gray-500 sm:px-16 lg:px-48 lg:text-xl'>
          With <span className='font-semibold text-[#420000]'>dgnkeeper</span>,
          dive into immersive role-playing worlds and craft captivating campaign
          lore with our user-friendly platform.
        </p>
        <div className='flex flex-col space-y-4 sm:flex-row sm:justify-center sm:space-x-4 sm:space-y-0'>
          <Link to='/auth' className='cta-button mb-8 mt-4 rounded-full'>
            Sign in
          </Link>
        </div>
      </div>
    </section>
  );
};

const WavesFooter = () => {
  return (
    <div className='waves-bottom'>
      <svg
        data-name='Layer 1'
        xmlns='http://www.w3.org/2000/svg'
        viewBox='0 0 1200 120'
        preserveAspectRatio='none'
      >
        <path
          d='M0,0V46.29c47.79,22.2,103.59,32.17,158,28,70.36-5.37,136.33-33.31,206.8-37.5C438.64,32.43,512.34,53.67,583,72.05c69.27,18,138.3,24.88,209.4,13.08,36.15-6,69.85-17.84,104.45-29.34C989.49,25,1113-14.29,1200,52.47V0Z'
          opacity='.25'
          className='shape-fill'
        ></path>
        <path
          d='M0,0V15.81C13,36.92,27.64,56.86,47.69,72.05,99.41,111.27,165,111,224.58,91.58c31.15-10.15,60.09-26.07,89.67-39.8,40.92-19,84.73-46,130.83-49.67,36.26-2.85,70.9,9.42,98.6,31.56,31.77,25.39,62.32,62,103.63,73,40.44,10.79,81.35-6.69,119.13-24.28s75.16-39,116.92-43.05c59.73-5.85,113.28,22.88,168.9,38.84,30.2,8.66,59,6.17,87.09-7.5,22.43-10.89,48-26.93,60.65-49.24V0Z'
          opacity='.5'
          className='shape-fill'
        ></path>
        <path
          d='M0,0V5.63C149.93,59,314.09,71.32,475.83,42.57c43-7.64,84.23-20.12,127.61-26.46,59-8.63,112.48,12.24,165.56,35.4C827.93,77.22,886,95.24,951.2,90c86.53-7,172.46-45.71,248.8-84.81V0Z'
          className='shape-fill'
        ></path>
      </svg>
    </div>
  );
};
