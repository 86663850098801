import React, { useState } from 'react';
import { useSelector } from 'react-redux';

// Components
import { FiBookmark, FiCheckCircle, FiClock } from 'react-icons/fi';

import BackAndFullscreen from '../../misc-components/BackAndFullscreen';

// Redux
import { selectOneQuest } from '../../../../redux/state-slices/questSlice';

// Styles
import '../selected.css';
import {
  GMDetailHeader,
  GMLocationHeader,
  LocationHeader,
  RelatedInfo,
  DetailMD,
} from '../../misc-components/MiscDetailComps';

export default function QuestDetails({ setQuestDetails, role }) {
  const currentQuest = useSelector(selectOneQuest);

  return (
    <div className='selected-content-details-wrap'>
      <BackAndFullscreen setStateFunc={setQuestDetails} />

      <QuestInfo currentQuest={currentQuest} role={role} />
    </div>
  );
}

export const QuestInfo = ({ currentQuest, role }) => {
  return (
    <div className='selected-content-details'>
      <div className='border-image-container'>
        <DetailsInfo currentQuest={currentQuest} role={role} />
      </div>
    </div>
  );
};

const DetailsInfo = ({ currentQuest, role }) => {
  const [isActive, setIsActive] = useState(true);

  return (
    // h-[57.5rem]
    <div className='asset-info-main relative'>
      <TopRow currentQuest={currentQuest} />

      <div className='info-description'>
        <hr className='details-seperating-bar mx-4 my-2 h-0' />

        <div className='details-chunk'>
          <DetailMD details={currentQuest.details} />
        </div>

        <div className='mx-4 mt-4 text-xl'>
          <span className='related-details-title px-3 py-1'>Reward</span>
        </div>
        <hr className='details-seperating-bar mx-4 mb-2 h-0' />

        <div className='mx-4'>
          {currentQuest?.reward ? (
            <span className='text-violet-600'>{currentQuest.reward}</span>
          ) : (
            <span>No reward to display.</span>
          )}
        </div>

        {role === 'GM' ? (
          <>
            <GMDetailHeader isActive={isActive} setIsActive={setIsActive} />

            {currentQuest.hidden_details ? (
              <div
                className={`${
                  isActive ? 'max-h-full' : 'invisible max-h-0'
                } details-chunk overflow-hidden transition-all duration-500 ease-in-out`}
              >
                <DetailMD details={currentQuest.hidden_details} />
              </div>
            ) : (
              <p className='details-chunk'>No hidden notes to show.</p>
            )}
          </>
        ) : null}

        <MiscInfo currentQuest={currentQuest} role={role} />
      </div>
    </div>
  );
};

const TopRow = ({ currentQuest }) => {
  const hasDataClass = {
    loc: currentQuest.location ? 'ml-1 mr-3 text-violet-600' : 'ml-1 mr-3',
    sesh: currentQuest.session ? 'ml-1 mr-3 text-violet-600' : 'ml-1 mr-3',
  };

  return (
    <>
      <div className='info-top-row relative mt-2 text-center'>
        {currentQuest.is_priority && (
          <FiBookmark className='absolute right-0 mx-2 fill-[#ff7a0e] text-[#ff7a0e]' />
        )}

        <span className='asset-title'>{currentQuest.title}</span>
      </div>
      <span className='my-2 flex justify-center text-violet-600'>
        {currentQuest.is_complete ? (
          <span className='flex items-center text-green-600'>
            <FiCheckCircle className='mx-1 inline text-green-600' />
            Completed
          </span>
        ) : (
          <span className='flex items-center text-violet-600'>
            <FiClock className='mx-1 inline text-violet-600' />
            Incomplete
          </span>
        )}
      </span>
      <div className='mt-3 flex flex-wrap justify-center'>
        Location:{' '}
        <span className={hasDataClass.loc}>
          {currentQuest.location || 'Unknown'}
        </span>
        Session assigned:{' '}
        <span className={hasDataClass.sesh}>
          {currentQuest.session || 'Not recorded'}
        </span>
      </div>
      <hr className='details-seperating-bar mb-0 mt-1' />
    </>
  );
};

const MiscInfo = ({ currentQuest, role }) => {
  const [isActiveLoc, setIsActiveLoc] = useState(true);
  const [isActiveLocGM, setIsActiveLocGM] = useState(false);

  return (
    <>
      <RelatedInfo />

      <LocationHeader isActive={isActiveLoc} setIsActive={setIsActiveLoc} />

      <div
        className={`${
          isActiveLoc ? 'max-h-full' : 'invisible max-h-0'
        } mx-4 overflow-hidden transition-all duration-500 ease-in-out`}
      >
        {currentQuest?.location_details ? (
          <DetailMD details={currentQuest.location_details} />
        ) : (
          <span>No available details regarding the location.</span>
        )}
      </div>

      {role === 'GM' ? (
        <>
          <GMLocationHeader
            isActive={isActiveLocGM}
            setIsActive={setIsActiveLocGM}
          />

          <div
            className={`${
              isActiveLocGM ? 'max-h-full' : 'invisible max-h-0'
            } mx-4 overflow-hidden transition-all duration-500 ease-in-out`}
          >
            {currentQuest.location_hidden_details ? (
              <DetailMD details={currentQuest.location_hidden_details} />
            ) : (
              <>No hidden location notes to display.</>
            )}
          </div>
        </>
      ) : null}
    </>
  );
};
