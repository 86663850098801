import React from 'react';
import { FileInput } from 'flowbite-react';

export default function DgnFileInput({ imageChangeHandler }) {
  const fileTheme = {
    root: {
      base: 'flex',
    },
    field: {
      base: 'relative items-center flex w-full',
      input: {
        base: 'flowbite-file-input overflow-hidden block',
        sizes: {
          sm: 'sm:text-xs',
          md: 'text-sm',
          lg: 'sm:text-md',
        },
      },
    },
  };

  return (
    <FileInput
      theme={fileTheme}
      name='img_url'
      id='img_url'
      type='file'
      accept='image/jpg, image/png, image/jpeg, image/webp'
      onChange={imageChangeHandler}
    />
  );
}
