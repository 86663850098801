import { s3Client } from './s3Client';

// TODO: Change Cors settings in spaces!!! Allow ONLY main site to send requests.
// Another bucket for dev stuff??

export const spacesUpload = async (img, folderName, username) => {
  try {
    const sendImg = await s3Client
      .upload({
        Bucket: process.env.REACT_APP_BUCKET_NAME,
        Key: `${username}/${folderName}/${img.name}`,
        Body: img,
        ACL: 'public-read',
      })
      .promise();

    return sendImg.Location;
  } catch (err) {
    throw new Error(err);
  }
};

export const spacesDelete = async (imgURL, username) => {
  if (!imgURL.includes(username)) {
    const error = {
      status: 403,
      detail:
        'This resource did not appear to be yours, therefore it was not deleted. If you think this was a mistake, please email us below.',
    };
    error.code = 403;
    throw error;
  }
  const imgKey = imgURL.replace(process.env.REACT_APP_BUCKET_URL, '');

  try {
    const deleteImg = await s3Client
      .deleteObject({
        Bucket: process.env.REACT_APP_BUCKET_NAME,
        Key: imgKey,
      })
      .promise();
    return deleteImg.$response;
  } catch (err) {
    throw new Error(err);
  }
};

export const gatherAssets = async (username, assetType = null) => {
  const prefix =
    assetType === null ? `${username}` : `${username}/${assetType}`;

  const params = {
    Bucket: process.env.REACT_APP_BUCKET_NAME,
    Prefix: prefix,
  };
  try {
    const keys = await s3Client.listObjectsV2(params).promise();
    return keys?.Contents;
  } catch (e) {
    throw new Error(e);
  }
};
