import React, { useEffect, useState } from 'react';

import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useSessionContext } from 'supertokens-auth-react/recipe/session';
import { Tooltip } from 'flowbite-react';
import { useCreateFactionMutation } from '../../redux/api-slices/factionAPI';
import { useCreateItemMutation } from '../../redux/api-slices/itemAPI';
import { useCreateLocationMutation } from '../../redux/api-slices/locationAPI';
import { useCreateNpcMutation } from '../../redux/api-slices/npcAPI';
import { useCreateQuestMutation } from '../../redux/api-slices/questAPI';
import { useCreateSceneMutation } from '../../redux/api-slices/sceneAPI';

import { spacesDelete, spacesUpload } from '../../digitalocean/spaces';
import { EditorMD } from '../archive/misc-components/MiscDetailComps';

import './AssetCreator.css';
import '../archive/selected-content/selected.css';

import { selectCurrentUser } from '../../redux/auth/authSlice';
import {
  displayAlert,
  setAlertInfo,
} from '../../redux/state-slices/alertSlice';
import FactionForm from './FactionForm';
import ItemForm from './ItemForm';
import LocationForm from './LocationForm';
import NPCForm from './NPCForm';
import QuestForm from './QuestForm';
import SceneForm from './SceneForm';
import SubmitLoader from '../loading-ani/SubmitLoader';
import { CreateFail } from '../alerts/ResponseAlerts';
import AiForm from './AiForm';

const AssetCreator = () => {
  const [activeOption, setActiveOption] = useState(null);
  const [publicDetails, setPublicDetails] = useState('');
  const [hiddenDetails, setHiddenDetails] = useState('');
  const [formData, setFormData] = useState({});
  const [imgFile, setImgFile] = useState();
  const [imgSize, setImgSize] = useState();
  // rerenderKey is used to force the component to rerender, clearing
  // all input fields that are unaffected by the setFormData({}) call.
  const [rerenderKey, setRerenderKey] = useState(new Date());
  const [isUpdating, setIsUpdating] = useState(false);
  const [isUsingAI, setIsUsingAI] = useState(false);

  const sesh = useSessionContext();
  const gameID = sesh.accessTokenPayload.In_Game.Game;

  const location = useLocation();
  const dispatch = useDispatch();
  const { assetTypeFromNavButton } = location.state;
  const user = useSelector(selectCurrentUser);
  const [factionPost] = useCreateFactionMutation();
  const [itemPost] = useCreateItemMutation();
  const [locationPost] = useCreateLocationMutation();
  const [npcPost] = useCreateNpcMutation();
  const [questPost] = useCreateQuestMutation();
  const [scenePost] = useCreateSceneMutation();

  useEffect(() => {
    const localStoragePublicDetails = localStorage.getItem('publicDetails');
    const localStorageHiddenDetails = localStorage.getItem('hiddenDetails');

    if (localStoragePublicDetails) {
      setPublicDetails(localStoragePublicDetails);
    }
    if (localStorageHiddenDetails) {
      setHiddenDetails(localStorageHiddenDetails);
    }
    // Clear any old form data that might cause the submit button to disable itself
    setImgFile(null);
    setImgSize(null);
    setFormData({
      ...formData,
      img_url: '',
    });
  }, []);

  useEffect(() => {
    if (assetTypeFromNavButton) {
      setActiveOption(assetTypeFromNavButton);
      location.state.assetTypeFromNavButton = undefined;
    }
  });

  useEffect(() => {
    if (publicDetails) {
      localStorage.setItem('publicDetails', publicDetails);
    }
  }, [publicDetails]);

  useEffect(() => {
    if (hiddenDetails) {
      localStorage.setItem('hiddenDetails', hiddenDetails);
    }
  }, [hiddenDetails]);

  const clearForm = () => {
    setFormData({});
    localStorage.removeItem('publicDetails');
    localStorage.removeItem('hiddenDetails');
    setRerenderKey(new Date());
  };

  const handleClick = (e) => {
    setActiveOption(e.target.id);

    // Clear any form data between forms that might cause the submit button to disable itself
    setImgFile(null);
    setImgSize(null);
    setFormData({
      ...formData,
      img_url: '',
    });
  };

  const handleInputChange = async (e) => {
    // Handle file changes
    if (e.target.type === 'file') {
      if (e.target.files.length === 0) {
        setFormData({
          ...formData,
          img_url: '',
        });
      } else {
        setFormData({
          ...formData,
          img_url: URL.createObjectURL(e.target.files[0]),
        });
        setImgFile(e.target.files[0]);
        const mb = e.target.files[0].size / 1024 / 1024;
        setImgSize(mb.toFixed(2));
      }
    }

    // Handle dropdown changes
    else if (e.target.nodeName === 'SELECT') {
      // This 'false' to null conversion is necessary because the unselected
      // option for the form dropdown can't have a value of null (e.target.value would
      // be the dropdown text e.g. '--- No Location ---').
      setFormData({
        ...formData,
        [e.target.name]: e.target.value === 'false' ? null : e.target.value,
      });
    }
    // Handle text inputs and checkboxes
    else {
      setFormData({
        ...formData,
        [e.target.name]:
          e.target.type === 'checkbox' ? e.target.checked : e.target.value,
      });
    }
  };

  const failedPostAlert = () => {
    dispatch(setAlertInfo(CreateFail));
    dispatch(displayAlert());
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsUpdating(true);
    let res;
    // Swap state obj to block scoped variable instead.
    // React updates state async, which doesn't work well with async functions.
    let data = formData;

    if ('img_url' in formData && formData.img_url !== '') {
      try {
        const imgResponse = await spacesUpload(
          imgFile,
          `GameAssets/${activeOption}Images`,
          user.username
        );
        data.img_url = imgResponse;
      } catch (err) {
        dispatch(
          setAlertInfo({
            alertType: 'failure',
            message: 'There was a problem uploading your image',
          })
        );
        dispatch(displayAlert());
      }
    }

    const detailType = `${
      activeOption === 'Group' ? 'public_details' : 'details'
    }`;

    data = {
      ...data,
      game_id: gameID,
      [detailType]: publicDetails,
      hidden_details: hiddenDetails,
    };

    localStorage.removeItem('publicDetails');
    localStorage.removeItem('hiddenDetails');

    switch (activeOption) {
      case 'Group':
        res = await factionPost(data);
        if (res.data) {
          clearForm();
          dispatch(
            setAlertInfo({
              alertType: 'success',
              message: `${data.name} successfully created`,
            })
          );
          dispatch(displayAlert());
        } else if (
          ('data' in res && res.data === false) ||
          'detail' in res ||
          'error' in res
        ) {
          if (data?.img_url) {
            await spacesDelete(data.img_url, user.username);
          }
          failedPostAlert();
        }
        setIsUpdating(false);
        break;
      case 'Item':
        res = await itemPost(data);
        if (res.data) {
          clearForm();
          dispatch(
            setAlertInfo({
              alertType: 'success',
              message: `${data.name} successfully created`,
            })
          );
          dispatch(displayAlert());
        } else if (
          ('data' in res && res.data === false) ||
          'detail' in res ||
          'error' in res
        ) {
          if (data?.img_url) {
            await spacesDelete(data.img_url, user.username);
          }
          failedPostAlert();
        }
        setIsUpdating(false);
        break;
      case 'Location':
        res = await locationPost(data);
        if (res.data) {
          clearForm();
          dispatch(
            setAlertInfo({
              alertType: 'success',
              message: `${data.name} successfully created`,
            })
          );
          dispatch(displayAlert());
        } else if (
          ('data' in res && res.data === false) ||
          'detail' in res ||
          'error' in res
        ) {
          if (data?.img_url) {
            await spacesDelete(data.img_url, user.username);
          }
          failedPostAlert();
        }
        setIsUpdating(false);
        break;
      case 'NPC':
        res = await npcPost(data);
        if (res.data) {
          clearForm();
          dispatch(
            setAlertInfo({
              alertType: 'success',
              message: `${data.name} successfully created`,
            })
          );
          dispatch(displayAlert());
        } else if (
          ('data' in res && res.data === false) ||
          'detail' in res ||
          'error' in res
        ) {
          if (data?.img_url) {
            await spacesDelete(data.img_url, user.username);
          }
          failedPostAlert();
        }
        setIsUpdating(false);
        break;
      case 'Quest':
        res = await questPost(data);
        if (res.data) {
          clearForm();
          dispatch(
            setAlertInfo({
              alertType: 'success',
              message: `${data.title} successfully created`,
            })
          );
          dispatch(displayAlert());
        } else if (
          ('data' in res && res.data === false) ||
          'detail' in res ||
          'error' in res
        ) {
          failedPostAlert();
        }
        setIsUpdating(false);
        break;
      case 'Scene':
        res = await scenePost(data);
        if (res.data) {
          clearForm();
          dispatch(
            setAlertInfo({
              alertType: 'success',
              message: `${data.title} successfully created`,
            })
          );
          dispatch(displayAlert());
        } else if (
          ('data' in res && res.data === false) ||
          'detail' in res ||
          'error' in res
        ) {
          if (data?.img_url) {
            await spacesDelete(data.img_url, user.username);
          }
          failedPostAlert();
        }
        setIsUpdating(false);
        break;
      default:
        break;
    }
  };

  const assetTypes = ['Location', 'Quest', 'NPC', 'Item', 'Group', 'Scene'];
  const assetHelperText = {
    Group:
      "Groups are distinct societies offering characters a unique allegiance. With specific goals, quests, and benefits, groups deepen role-playing, create alliances or rivalries, and allow characters to shape the world's balance of power.",
    Item: "Items are essential tools and treasures for adventurers. They encompass weapons, magical artifacts, and equipment that enhance abilities, grant powers, and shape your character's journey.",
    Location:
      'Locations are vital settings where your adventures unfold. From ancient ruins to bustling cities, each site offers unique challenges, stories, and opportunities for characters to explore and interact with their world.',
    NPC: 'NPCs, or non-player characters, are the diverse individuals populating your adventures. They range from allies to adversaries, each with their own personalities, motives, and roles in shaping the narrative. Interacting with NPCs adds depth and dynamism to the game world.',
    Quest:
      "Quests are the thrilling missions and challenges that drive adventures forward. They propel characters into exciting journeys, prompting exploration, combat, diplomacy, and puzzle-solving. Completing quests earns rewards, shapes character growth, and weaves the tapestry of the game's story.",
    Scene:
      'Scenes are dynamic interactions that bring the game world to life and propel the narrative forward. They encompass battles, conversations, and unexpected events, adding excitement, tension, and opportunities for characters to demonstrate their skills and choices. Scenes shape the flow of the adventure and create memorable moments.',
  };
  return (
    <div className='bg-wrap min-h-screen'>
      {isUpdating && <SubmitLoader message='Creating' />}
      <div className='main-container'>
        <div id='type-selector' className='flex justify-around'>
          <div className='border-b border-gray-200 text-center text-sm font-medium text-gray-500 '>
            <ul className='-mb-px flex flex-wrap'>
              {assetTypes.map((asset) => (
                <li className='mr-2' key={asset}>
                  <button
                    id={asset}
                    type='button'
                    className={`inline-block rounded-t-lg border-b-2 border-transparent p-4 text-lg hover:border-gray-300 hover:text-gray-600 ${
                      activeOption === asset && 'active'
                    }`}
                    onClick={handleClick}
                  >
                    {asset}
                  </button>
                </li>
              ))}
            </ul>
          </div>
        </div>
        <p className='m-auto my-4 w-[35rem] rounded-lg bg-[#074f57] p-4 text-center text-white shadow-[0_3px_8px_rgba(0,0,0,.24)]'>
          {assetHelperText[activeOption]}
        </p>
        {!activeOption ? (
          <div className='flex min-h-screen justify-center'>
            <p className=''>Select an Asset Type to get started</p>
          </div>
        ) : (
          <form
            onSubmit={handleSubmit}
            className='selected-content-details m-auto h-full max-w-[95rem] p-3'
          >
            <div className='border-image-container p-4'>
              {activeOption === 'Group' && (
                <FactionForm
                  key={rerenderKey}
                  formData={formData}
                  gameID={gameID}
                  handleInputChange={handleInputChange}
                  imgSize={imgSize}
                />
              )}
              {activeOption === 'Item' && (
                <ItemForm
                  key={rerenderKey}
                  formData={formData}
                  gameID={gameID}
                  handleInputChange={handleInputChange}
                  imgSize={imgSize}
                />
              )}
              {activeOption === 'Location' && (
                <LocationForm
                  key={rerenderKey}
                  formData={formData}
                  gameID={gameID}
                  handleInputChange={handleInputChange}
                  imgSize={imgSize}
                />
              )}
              {activeOption === 'NPC' && (
                <NPCForm
                  key={rerenderKey}
                  formData={formData}
                  gameID={gameID}
                  handleInputChange={handleInputChange}
                  imgSize={imgSize}
                />
              )}
              {activeOption === 'Quest' && (
                <QuestForm
                  key={rerenderKey}
                  formData={formData}
                  gameID={gameID}
                  handleInputChange={handleInputChange}
                />
              )}
              {activeOption === 'Scene' && (
                <SceneForm
                  key={rerenderKey}
                  formData={formData}
                  gameID={gameID}
                  handleInputChange={handleInputChange}
                  imgSize={imgSize}
                />
              )}
              <div className='mt-3 flex items-center justify-center gap-x-2'>
                <p className='text-lg text-[#420000]'>
                  Generate Details With AI:
                </p>
                <div className='flex gap-x-2'>
                  <button
                    type='button'
                    className={` ${
                      isUsingAI ? 'bg-green-700' : 'bg-green-700/60'
                    } rounded-lg  px-2 py-1 text-white`}
                    onClick={() => setIsUsingAI(true)}
                  >
                    Yes
                  </button>
                  <button
                    type='button'
                    className={` ${
                      !isUsingAI ? 'bg-red-700' : 'bg-red-300'
                    } rounded-lg px-2 py-1 text-white`}
                    onClick={() => setIsUsingAI(false)}
                  >
                    No
                  </button>
                </div>
              </div>
              <div
                className={`${
                  isUsingAI ? 'max-h-full' : 'invisible max-h-0'
                } mx-4 overflow-hidden transition-all duration-500 ease-in-out`}
              >
                <AiForm
                  setHiddenDetails={setHiddenDetails}
                  setPublicDetails={setPublicDetails}
                  activeOption={activeOption}
                  publicDetails={publicDetails}
                  hiddenDetails={hiddenDetails}
                />
              </div>
              <div className='info-top-row-sm mt-8'>Details</div>
              <EditorMD
                height='65vh'
                mdText={publicDetails}
                setMdText={setPublicDetails}
                key={`details${rerenderKey}`}
              />
              <div className='info-top-row-sm mt-8'>GM-only Notes</div>
              <EditorMD
                height='65vh'
                mdText={hiddenDetails}
                setMdText={setHiddenDetails}
                key={`hiddenDetails${rerenderKey}`}
              />
              <div className='flex justify-end pt-3'>
                <div className=' mt-2 flex justify-center'>
                  {imgSize === null || imgSize <= 2 ? (
                    <button
                      type='submit'
                      className='submit-button mr-2 rounded-lg bg-green-700 px-4 py-2 text-white'
                    >
                      Create {activeOption}
                    </button>
                  ) : (
                    <Tooltip
                      // eslint-disable-next-line react/style-prop-object
                      style='light'
                      content='Your image is too large, consider compressing it'
                    >
                      <button
                        type='submit'
                        disabled
                        className='submit-button mr-2 rounded-lg bg-green-700 px-4 py-2 text-white'
                      >
                        Create {activeOption}
                      </button>
                    </Tooltip>
                  )}
                  <button
                    type='button'
                    onClick={clearForm}
                    className='form-clear-button rounded-lg bg-red-800 px-4 py-2 text-white '
                  >
                    Clear Form
                  </button>
                </div>
              </div>
            </div>
          </form>
        )}
      </div>
    </div>
  );
};

export default AssetCreator;
