/* eslint-disable no-alert */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { FaArrowLeft } from 'react-icons/fa6';
import { Tooltip } from 'flowbite-react';

import { useCreateCharacterMutation } from '../../../redux/api-slices/characterAPI';
import { selectCurrentUser } from '../../../redux/auth/authSlice';
import {
  displayAlert,
  setAlertInfo,
} from '../../../redux/state-slices/alertSlice';
import { spacesDelete, spacesUpload } from '../../../digitalocean/spaces';
import '../../game/createGame.css';
// import '../../archive/selected-content/selected.css';
import FloatingLabelInput from '../../archive/misc-components/FloatingLabelInput';
import DgnFileInput from '../../archive/misc-components/DgnFileInput';
import SizeChecker from '../../archive/misc-components/SizeChecker';
import SubmitLoader from '../../loading-ani/SubmitLoader';
import { CreateFail } from '../../alerts/ResponseAlerts';

export default function CharacterCreateForm({ partyID, setActiveStep }) {
  const [classValue, setClassValue] = useState('');
  // const [factionValue, setFactionValue] = useState(null);
  const [charNameValue, setCharNameValue] = useState('');
  const [hpValue, setHpValue] = useState(0);
  const [levelValue, setLevelValue] = useState(1);
  const [wealthValue, setWealthValue] = useState(0);

  const [tmpImgURL, setTmpImgURL] = useState(''); // used to preview img
  const [imgObject, setImgObject] = useState(null); // actual img sent to back end
  const [localImgSize, setLocalImgSize] = useState(null);
  const [isUpdating, setIsUpdating] = useState(false);

  const [characterPost] = useCreateCharacterMutation();

  const dispatch = useDispatch();
  const user = useSelector(selectCurrentUser);

  useEffect(() => {
    if (imgObject === null) {
      setLocalImgSize(null);
    } else {
      const mb = imgObject.size / 1024 / 1024;
      setLocalImgSize(mb.toFixed(2));
    }
  }, [imgObject]);

  const handleSubmit = async (e) => {
    setIsUpdating(true);
    e.preventDefault();
    const data = {
      party_id: partyID,
      classes: classValue,
      // faction_id: factionValue,
      faction_id: null,
      username: user.username,
      character_name: charNameValue,
      hp: hpValue,
      level: levelValue,
      inspiration: false,
      wealth: wealthValue,
      img_url: null,
      backstory: '',
    };

    if (imgObject !== null) {
      try {
        const imgResponse = await spacesUpload(
          imgObject,
          'CharacterImages',
          user.username
        );
        data.img_url = imgResponse;
      } catch (err) {
        setIsUpdating(false);
        alert(
          `There was an issue uploading this image to the bucket, however the rest of your character has been saved. You can try uploading the image again in the character settings page.`
        );
      }
    }
    const res = await characterPost(data);
    if (res.data) {
      window.location.reload();
    } else if (res.error.status === 507) {
      await spacesDelete(data?.img_url, user.username);
      setIsUpdating(false);
      dispatch(
        setAlertInfo({
          alertType: 'storage',
          message:
            'We are only allowing 1 character per user right now to save on cost. This limit will increase in the future!',
        })
      );
      dispatch(displayAlert());
    } else {
      setIsUpdating(false);
      dispatch(setAlertInfo(CreateFail));
      dispatch(displayAlert());
    }
  };

  const onCharImageChange = (e) => {
    const { files } = e.target;
    if (files && files.length) {
      const imgFile = files[0];
      setTmpImgURL(URL.createObjectURL(imgFile));
      setImgObject(imgFile);
    }
  };

  return (
    <div className='game-wrapper'>
      {isUpdating && <SubmitLoader message='Submitting' />}
      {/* TODO: Set small screen media queries */}
      <div className='game-content mx-auto max-w-[95rem]'>
        <form className='create-form h-full w-full'>
          <div className='flex flex-col items-center'>
            <FloatingLabelInput
              onChange={(e) => setCharNameValue(e.target.value)}
              value={charNameValue}
              labelText='Name'
              nameAndID='name'
              isRequired
              maxLength={70}
              additionalInputClassNames='asset-title'
            />
            <div
              className='img-select border-image-container mt-8 w-96'
              style={{
                padding: '0.9rem',
                marginTop: '15px',
                marginLeft: 'auto',
                marginRight: 'auto',
                borderImageOutset: '3px 4px',
              }}
            >
              <div>
                <img
                  height={640}
                  width={540}
                  src={
                    tmpImgURL ||
                    'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png'
                  }
                  alt='character upload portrait'
                />
              </div>
            </div>
            <div className='py-4'>
              <DgnFileInput imageChangeHandler={onCharImageChange} />
              {localImgSize && <SizeChecker imgSize={localImgSize} />}
            </div>
            <div className='grid grid-cols-2 gap-4' id='info'>
              <FloatingLabelInput
                onChange={(e) => setClassValue(e.target.value)}
                value={classValue}
                labelText='Class'
                nameAndID='class'
                maxLength={100}
                isRequired
                additionalInputClassNames='asset-title'
              />
              <FloatingLabelInput
                labelText='HP'
                onChange={(e) => setHpValue(e.target.value)}
                value={hpValue}
                nameAndID='hp'
                type='number'
                isRequired
                additionalInputClassNames='asset-title'
              />
              <FloatingLabelInput
                labelText='Level'
                onChange={(e) => setLevelValue(e.target.value)}
                value={levelValue}
                nameAndID='level'
                type='number'
                isRequired
                additionalInputClassNames='asset-title'
              />
              <FloatingLabelInput
                labelText='Wealth'
                onChange={(e) => setWealthValue(e.target.value)}
                value={wealthValue}
                nameAndID='wealth'
                type='number'
                isRequired
                additionalInputClassNames='asset-title'
              />
            </div>
          </div>

          <div className=' mt-4 flex justify-center'>
            <button
              type='button'
              className='submit-button mr-2 rounded-lg bg-[#420000] px-4 py-2'
              onClick={() => setActiveStep(3)}
            >
              <FaArrowLeft className='self-center pr-1' />
              Back
            </button>
            {localImgSize === null || localImgSize <= 2 ? (
              <button
                type='button'
                className='submit-button mr-2 rounded-lg bg-green-700 px-4 py-2 text-white'
                onClick={handleSubmit}
              >
                Create!
              </button>
            ) : (
              <Tooltip
                // eslint-disable-next-line react/style-prop-object
                style='light'
                content='Your image is too large, consider compressing it'
              >
                <button
                  type='submit'
                  disabled
                  className='submit-button mr-2 rounded-lg bg-green-700 px-4 py-2 text-white'
                >
                  Create!
                </button>
              </Tooltip>
            )}
          </div>
        </form>
      </div>
    </div>
  );
}
