import React, { useEffect, useState } from 'react';
import { Label, Select } from 'flowbite-react';

import { useSelector } from 'react-redux';
import {
  FiBookmark,
  FiCheckCircle,
  FiClock,
  FiEye,
  FiEyeOff,
} from 'react-icons/fi';
import { useGetLocationsQuery } from '../../redux/api-slices/locationAPI';
import { useGetSessionsQuery } from '../../redux/api-slices/sessionAPI';
import { selectOneQuest } from '../../redux/state-slices/questSlice';

import LoadingSpinner from '../loading-ani/LoadingSpinner';
import { EditorMD } from '../archive/misc-components/MiscDetailComps';
import '../archive/selected-content/selected.css';
import FloatingLabelInput from '../archive/misc-components/FloatingLabelInput';
import './AssetCreator.css';

const QuestForm = ({
  handleInputChange,
  formData,
  gameID,
  isUpdate,
  handleUpdateSubmit,
}) => {
  const { data: locationData, isLoading: locationLoading } =
    useGetLocationsQuery(gameID);
  const { data: sessionsData, isLoading: sessionsLoading } =
    useGetSessionsQuery(gameID);

  // Start section used when form is updating asset rather than creating from scratch  vvvvv

  // Set initial values for checkboxes to prevent "uncontrolled input to controlled" error
  // Initial value not important, is immediately set to correct value when formData is received
  const [localUpdateState, setLocalUpdateState] = useState({
    is_hidden: false,
    is_complete: false,
    is_priority: false,
  });
  const [publicDetails, setPublicDetails] = useState();
  const [hiddenDetails, setHiddenDetails] = useState();

  const questObjFromRedux = useSelector(selectOneQuest);

  useEffect(() => {
    if (isUpdate) {
      setLocalUpdateState({ ...formData });
      setPublicDetails(formData.details);
      setHiddenDetails(formData.hidden_details);
    }
  }, [formData]);

  useEffect(() => {
    return () => {
      setLocalUpdateState({});
    };
  }, []);

  const handleLocalInputChange = async (e) => {
    if (e.target.name === 'party_id' && e.target.value === 'false') {
      setLocalUpdateState({
        ...localUpdateState,
        party_id: null,
        session_id: null,
      });
    } else {
      setLocalUpdateState({
        ...localUpdateState,
        [e.target.name]:
          e.target.type === 'checkbox' ? e.target.checked : e.target.value,
      });
    }
  };
  // Finish section  ^^^^^

  return (
    <>
      <div className='m-auto flex w-[35rem] flex-col items-center'>
        <FloatingLabelInput
          onChange={isUpdate ? handleLocalInputChange : handleInputChange}
          value={
            isUpdate ? localUpdateState?.title || '' : formData.title || ''
          }
          labelText='Title'
          nameAndID='title'
          isRequired
          maxLength={80}
          additionalInputClassNames='asset-title'
        />
        <div id='toggles' className='mt-4 flex'>
          <label htmlFor='is_complete' className='flex'>
            {(
              isUpdate ? localUpdateState?.is_complete : formData.is_complete
            ) ? (
              <span className='flex items-center text-green-600'>
                <FiCheckCircle className='mx-1 inline text-green-600' />
                Completed
              </span>
            ) : (
              <span className='flex items-center text-violet-600'>
                <FiClock className='mx-1 inline text-violet-600' />
                Incomplete
              </span>
            )}
            <input
              className='ml-3'
              onChange={isUpdate ? handleLocalInputChange : handleInputChange}
              checked={
                isUpdate
                  ? localUpdateState?.is_complete
                  : formData.is_complete || false
              }
              value={
                isUpdate
                  ? localUpdateState?.is_complete
                  : formData.is_complete || false
              }
              type='checkbox'
              name='is_complete'
              id='is_complete'
            />
          </label>

          <div className='mx-3'>|</div>

          <label htmlFor='is_hidden' className='flex'>
            {(
              isUpdate
                ? localUpdateState?.is_hidden
                : 'is_hidden' in formData
                ? formData.is_hidden
                : true
            ) ? (
              <span className='flex items-center text-violet-600'>
                <FiEyeOff className='mr-2 inline text-violet-600 opacity-100' />
                Hide From Players
              </span>
            ) : (
              <span className='flex items-center text-green-600'>
                <FiEye className='mr-2 inline text-green-600 opacity-100' />
                Show to Players
              </span>
            )}
            <input
              className='ml-3'
              onChange={isUpdate ? handleLocalInputChange : handleInputChange}
              checked={
                isUpdate
                  ? localUpdateState?.is_hidden
                  : 'is_hidden' in formData
                  ? formData.is_hidden
                  : true
              }
              value={
                isUpdate
                  ? localUpdateState?.is_hidden
                  : 'is_hidden' in formData
                  ? formData.is_hidden
                  : true
              }
              type='checkbox'
              name='is_hidden'
              id='is_hidden'
            />
          </label>

          <div className='mx-3'>|</div>
          <label htmlFor='is_priority' className='flex'>
            {(
              isUpdate ? localUpdateState?.is_priority : formData.is_priority
            ) ? (
              <span className='flex items-center text-[#ff7a0e]'>
                <FiBookmark className='mr-2 fill-[#ff7a0e] text-[#ff7a0e]' />
                Priority
              </span>
            ) : (
              <span className='flex items-center text-violet-600'>
                <FiBookmark className='mr-2 inline text-violet-600 opacity-100' />
                Standard
              </span>
            )}
            <input
              className='ml-3'
              onChange={isUpdate ? handleLocalInputChange : handleInputChange}
              checked={
                isUpdate
                  ? localUpdateState?.is_priority
                  : formData.is_priority || false
              }
              value={
                isUpdate
                  ? localUpdateState?.is_priority
                  : formData.is_priority || false
              }
              type='checkbox'
              name='is_priority'
              id='is_priority'
            />
          </label>
        </div>
        <div className='my-4 flex'>
          {locationLoading ? (
            <div className='min-h-4'>
              <LoadingSpinner classStyle='loading-ani-red' />
            </div>
          ) : (
            <Label color='black' className='mr-4' htmlFor='location_id'>
              Location
              <Select
                name='location_id'
                id='location_id'
                className='flowbite-select'
                onChange={isUpdate ? handleLocalInputChange : handleInputChange}
                defaultValue={
                  isUpdate && questObjFromRedux.location_id
                    ? parseInt(questObjFromRedux?.location_id, 10)
                    : null
                }
              >
                <option value={false}>--- No Location ---</option>
                {locationData?.map((loc) => (
                  <option key={loc.name} value={loc.id}>
                    {loc.name}
                  </option>
                ))}
              </Select>
            </Label>
          )}
          {sessionsLoading ? (
            <div className='min-h-4'>
              <LoadingSpinner classStyle='loading-ani-red' />
            </div>
          ) : (
            <Label color='black' htmlFor='session_id'>
              Session
              <Select
                name='session_id'
                id='session_id'
                className='flowbite-select'
                onChange={isUpdate ? handleLocalInputChange : handleInputChange}
                defaultValue={
                  isUpdate && questObjFromRedux.session_id
                    ? parseInt(questObjFromRedux.session_id, 10)
                    : null
                }
              >
                <option value={false}>--- No Session ---</option>
                {sessionsData?.map((session) => (
                  <option key={session.id} value={session.id}>
                    {session.title}
                  </option>
                ))}
              </Select>
            </Label>
          )}
        </div>

        {/* Removed party selector. Leaving in case we want it back in the future? */}
        {/* <Label htmlFor='party_id'>
          Party
          {partiesLoading ? (
            <div className='h-1'>
              <LoadingSpinner />
            </div>
          ) : (
            <Select
              name='party_id'
              id='party_id'
              onChange={handlePartySelectChange}
              defaultValue={null}
            >
              <option value={false}>--- No Party ---</option>
              {partyData?.map((party) => (
                <option key={party.party_name} value={party.id}>
                  {party.party_name}
                </option>
              ))}
            </Select>
          )}
        </Label> */}
      </div>
      <div className='flex justify-center'>
        <FloatingLabelInput
          onChange={isUpdate ? handleLocalInputChange : handleInputChange}
          value={
            isUpdate ? localUpdateState.reward || '' : formData.reward || ''
          }
          nameAndID='reward'
          labelText='Reward'
          additionalInputClassNames='asset-title p-0 text-center'
        />
      </div>
      {isUpdate && (
        <div className='px-4'>
          Details:
          <EditorMD mdText={publicDetails} setMdText={setPublicDetails} />
          Hidden Details:
          <EditorMD mdText={hiddenDetails} setMdText={setHiddenDetails} />
          <div className=' mt-2 flex justify-center'>
            <button
              type='button'
              className='submit-button mr-2 rounded-lg bg-green-700 px-4 py-2 text-white'
              onClick={(e) => {
                handleUpdateSubmit(
                  e,
                  localUpdateState,
                  publicDetails,
                  hiddenDetails
                );
              }}
            >
              Update
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default QuestForm;
