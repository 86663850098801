import React from 'react';

import { FiArrowLeft, FiMaximize2 } from 'react-icons/fi';

export default function BackAndFullscreen({ setStateFunc }) {
  return (
    // TODO: bg-[rgba(255,255,255,.7)]
    <div className='absolute left-0 top-0 z-10 flex justify-between rounded-lg'>
      <button
        type='button'
        className='row-button back-button rounded-lg bg-gray-200'
        onClick={() => setStateFunc(false)}
      >
        <FiArrowLeft className='list-icon mr-1 inline' /> Back
      </button>
      <button
        type='button'
        className='row-button fullscreen hidden rounded-lg bg-gray-200'
      >
        <FiMaximize2 className='list-icon mr-1 inline' /> Fullscreen
      </button>
    </div>
  );
}
