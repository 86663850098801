import { createSlice } from '@reduxjs/toolkit';
import { resetAll } from './compareSlice';

const initialState = {
  allFactions: [],
  selectedFaction: null,
};

const factionSlice = createSlice({
  name: 'faction',
  initialState,
  extraReducers: (obj) => obj.addCase(resetAll, () => initialState),
  reducers: {
    setFactions: (state, action) => {
      state.allFactions = action.payload;
    },

    setOneFaction: (state, action) => {
      state.selectedFaction = action.payload;
    },
  },
});

export const { setFactions, setOneFaction } = factionSlice.actions;

export default factionSlice.reducer;

export const selectAllFactions = (state) => state.factions.allFactions;
export const selectOneFaction = (state) => state.factions.selectedFaction;
