import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/dist/query';

import authSliceReducer from './auth/authSlice';
import gameSliceReducer from './state-slices/gameSlice';
import locationSliceReducer from './state-slices/locationSlice';
import factionSliceReducer from './state-slices/factionSlice';
import npcSliceReducer from './state-slices/npcSlice';
import itemSliceReducer from './state-slices/itemSlice';
import questSliceReducer from './state-slices/questSlice';
import sceneSliceReducer from './state-slices/sceneSlice';
import sessionSliceReducer from './state-slices/sessionSlice';
import partySliceReducer from './state-slices/partySlice';
import compareSliceReducer from './state-slices/compareSlice';
import alertSliceReducer from './state-slices/alertSlice';
import characterSliceReducer from './state-slices/characterSlice';
import dgnApi from './api-slices/dgnAPI';

const store = configureStore({
  reducer: {
    auth: authSliceReducer,
    games: gameSliceReducer,
    locations: locationSliceReducer,
    factions: factionSliceReducer,
    npcs: npcSliceReducer,
    items: itemSliceReducer,
    quests: questSliceReducer,
    scenes: sceneSliceReducer,
    sessions: sessionSliceReducer,
    parties: partySliceReducer,
    compare: compareSliceReducer,
    alerts: alertSliceReducer,
    character: characterSliceReducer,
    [dgnApi.reducerPath]: dgnApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(dgnApi.middleware),
  devTools: process.env.NODE_ENV !== 'production',
});

setupListeners(store.dispatch);
export default store;
