import { createAction, createSlice } from '@reduxjs/toolkit';

export const resetAll = createAction('RESET');

const initialState = {
  compareContainer: [],

  checkedElem: {},

  isComparingAll: null,
};

const compareSlice = createSlice({
  name: 'compare',
  initialState,
  extraReducers: (obj) => obj.addCase(resetAll, () => initialState),
  reducers: {
    resetCompareSlice: (state) => {
      state.compareContainer = [];
      state.checkedElem = {};
      state.isComparingAll = null;
    },

    addToCompare: (state, action) => {
      const gameAsset = action.payload;
      state.compareContainer.push(gameAsset);
    },

    removeAnItem: (state, action) => {
      const instructions = action.payload;

      const remover = (i) => {
        state.compareContainer.splice(i, 1);
      };

      if (instructions.sender === 'comparison') {
        remover(instructions.idx);
      } else if (instructions.sender === 'content') {
        const contentIdx = state.compareContainer.findIndex(
          (item) =>
            item.id === instructions.content.id &&
            item.name === instructions.content.name
        );
        remover(contentIdx);
      }
    },

    elemChecker: (state, action) => {
      const { elId, isChecked } = action.payload;
      const checkedElemCopy = { ...state.checkedElem };
      checkedElemCopy[elId] = isChecked;
      state.checkedElem = checkedElemCopy;
    },

    compareSelectedObjs: (state, action) => {
      const isComparing = action.payload;
      state.isComparingAll = isComparing;
    },
  },
});

export const {
  addToCompare,
  removeAnItem,
  resetCompareSlice,
  elemChecker,
  compareSelectedObjs,
} = compareSlice.actions;

export default compareSlice.reducer;

export const selectComparisonObjs = (state) => state.compare.compareContainer;
export const selectCheckedElems = (state) => state.compare.checkedElem;
export const selectIsComparing = (state) => state.compare.isComparingAll;
