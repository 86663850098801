export const sessionObjTemplate = {
  id: null,
  title: 'Example session',
  date: '2023-08-22',
  summary: `# Post-Session Notes - Session [Session Number]

## Overview

Begin by providing a brief summary of the key events and developments that occurred during the session. This gives you and your players a quick reminder of what happened.

## Key Events

List out the important events that took place during the session. These could include encounters, discoveries, interactions with NPCs, and any other noteworthy moments.

## Player Actions

This is where you describe any significant actions or decisions made by the players that influenced the session's outcome. It helps keep track of how player choices impact the story.

## Rewards

Detail any rewards or items that the players earned during the session. This could be treasure, magical items, or other valuables they acquired.

---
`,
  gm_notes: `## GM-Only Notes

Here's where you can jot down important information that only you, as the Game Master, need to know for future sessions. These notes could include plot developments, hidden clues, or upcoming events that will affect the storyline.

- **[Note 1 Title]:** Provide a brief note about a plot development, hidden clue, or upcoming event relevant to the campaign's ongoing story.

- **[Note 2 Title]:** Add another GM-only note if needed. These notes help you stay organized and maintain consistency in the narrative.

- **[Note 3 Title]:** Continue to provide additional notes as necessary for future sessions. This is your personal toolkit to guide the campaign forward.

---
**Note:** Include any general notes or reminders for yourself as the GM. These could be about campaign continuity, character motivations, or overarching plot threads you want to remember.
`,
};

export const gameMDTemplate = `
**In this example, we'll create a layout to introduce you to Markdown capabilities. The toolbar provides a shortcut to commonly used styles.**
------
# Campaign: Realms of Eldoria

Welcome to the world of **Realms of Eldoria**, a fantastical campaign setting filled with adventure, mystery, and magic.



## Campaign Overview

"Realms of Eldoria" is a high-fantasy campaign set in a sprawling universe where multiple realms intersect. Ancient prophecies, powerful artifacts, and diverse races define the world's landscape.

## Key Realms

Explore the diverse regions of Eldoria, each with its own unique traits and inhabitants:

| Realm            | Description                                                                                  |
|------------------|----------------------------------------------------------------------------------------------|
| **Aurora Isles** | Lush, enchanted islands shrouded in a perpetual sunrise, home to merfolk, druids, and more. |
| **Empire of Solara** | A vast empire ruled by an immortal sorcerer-queen, where magic and technology blend. |
| **Sylvan Glades** | Ancient, mystical forest inhabited by secretive elves and guarded by nature spirits. |

## Central Conflict

An ancient prophecy foretells the rise of a dark sorcerer seeking to unleash chaos across the realms. Adventurers must unite the fractured races and recover lost relics to thwart the sorcerer's plans and restore balance.

## Factions and Organizations

Dive into the world of Eldoria by encountering various factions and organizations:

| Faction                    | Description                                                                                          |
|---------------------------|------------------------------------------------------------------------------------------------------|
| **Order of the Silver Shield** | Chivalrous knightly order protecting the innocent and upholding justice. |
| **Coven of the Whispering Shadows** | Enigmatic spellcasters delving into forbidden magic for hidden truths. |
| **Guild of Explorers** | Daring treasure hunters and archaeologists uncovering forgotten artifacts. |

## Key NPCs

Meet some of the individuals who shape the fate of Eldoria:

| NPC                    | Role                      | Description                                                                      |
|------------------------|---------------------------|----------------------------------------------------------------------------------|
| **Elara Moonshadow** | Elven Sage            | Holder of ancient prophecies and knowledge of lost artifacts.               |
| **Lord Valerian Darkbane** | Sorcerer-queen | Ruler of the Empire of Solara, harboring enigmatic secrets.                  |
| **Captain Maris Stormrider** | Merfolk Captain | Protector of the Aurora Isles, facing dark forces threatening her realm. |

## Themes and Tone

"Realms of Eldoria" blends epic heroism with personal growth tales. The campaign explores unity, sacrifice, and the consequences of wielding immense power.

---
`;
