import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, Button } from 'flowbite-react';
import { FiAlertCircle } from 'react-icons/fi';
import { selectAllParties } from '../../redux/state-slices/partySlice';
import { useCreateSessionMutation } from '../../redux/api-slices/sessionAPI';
import './archive.css';
import {
  displayAlert,
  setAlertInfo,
} from '../../redux/state-slices/alertSlice';

export default function StartSession({ gID }) {
  const dispatch = useDispatch();
  const [openModal, setOpenModal] = useState(undefined);
  const [postSession] = useCreateSessionMutation();
  const parties = useSelector(selectAllParties);

  return (
    <div className='m-4 text-center'>
      <button
        type='button'
        className='start-session rounded-lg bg-[#420000] px-8 py-2 text-white'
        onClick={() => setOpenModal('pop-up')}
      >
        Start a session
      </button>

      <SessionModal
        openModal={openModal}
        setOpenModal={setOpenModal}
        postSession={postSession}
        gid={gID}
        parties={parties}
        dispatch={dispatch}
      />
    </div>
  );
}

const SessionModal = ({
  openModal,
  setOpenModal,
  postSession,
  gid,
  parties,
  dispatch,
}) => {
  const party = parties[0];
  const modalTheme = {
    root: {
      show: {
        on: 'flex bg-white bg-opacity-40',
      },
    },

    content: {
      inner: 'border-blue-600 border-2 rounded-lg bg-white',
    },
  };

  const handleSubmit = async () => {
    const today = new Date();
    const month = today.getMonth() + 1;
    const year = today.getFullYear();
    const day = today.getDate();
    const dayt = `${year}-${month}-${day}`;

    const body = {
      party_id: party.id,
      title: 'Default session title',
      summary: `# Post-Session Notes - Session [Session Number]

## Overview

[Provide a brief summary of the key events and developments that occurred during the session.]

## Key Events
[List the important events that took place during the session, such as encounters, discoveries, and interactions.]

1. Event 1

2. Event 2

3. Event 3

etc.


## Player Actions
[Describe any significant actions or decisions made by the players that influenced the session's outcome.]

1. Action 1

2. Action 2

3. Action 3

etc.



## Rewards
[Detail any rewards or items that the players earned during the session.]
`,
      date: dayt,
      gm_notes: `## GM-Only Notes

- **[Note 1 Title]:** [Provide a note about a plot development, hidden clue, or upcoming event relevant to the campaign's ongoing story.]

- **[Note 2 Title]:** [Add another GM-only note if needed.]

- **[Note 3 Title]:** [Continue to provide additional notes as necessary for future sessions.]
`,
      game_id: gid,
    };

    try {
      const postRes = await postSession({ gid, ...body });

      if ('data' in postRes && postRes.data) {
        dispatch(
          setAlertInfo({
            alertType: 'success',
            message: 'Session successfully created',
          })
        );
        dispatch(displayAlert());
      }
    } catch (err) {
      dispatch(
        setAlertInfo({
          alertType: 'failure',
          message:
            'An error occured during this request. If this issue continues, please submit a support ticket and we will look into it ASAP!',
        })
      );
      dispatch(displayAlert());
    }
    setOpenModal(undefined);
  };

  return (
    <Modal
      theme={modalTheme}
      show={openModal === 'pop-up'}
      size='md'
      popup
      onClose={() => setOpenModal(undefined)}
    >
      <Modal.Header />
      <Modal.Body>
        <div className='text-center'>
          <FiAlertCircle className='mx-auto mb-4 h-14 w-14 text-blue-600' />
          <h3 className='mb-2 text-lg font-normal text-gray-500'>
            Starting a session will add a new record to your session logs. You
            can add details at any time.
          </h3>

          <div className='flex justify-center gap-4'>
            <button
              type='button'
              className='rounded-lg border bg-green-700 px-4 py-2 text-white active:scale-[.98]'
              onClick={handleSubmit}
            >
              Start session
            </button>
            <Button color='failure' onClick={() => setOpenModal(undefined)}>
              No, cancel
            </Button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};
