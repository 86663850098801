import dgnApi from './dgnAPI';

const npcApi = dgnApi.injectEndpoints({
  endpoints: (build) => ({
    getNpcs: build.query({
      query: (gid) => ({
        url: `/api/npcs/${gid}`,
      }),
      providesTags: ['NPCs'],
    }),

    createNpc: build.mutation({
      query: (data) => ({
        method: 'POST',
        url: '/api/npc',
        body: data,
      }),
      invalidatesTags: ['NPCs'],
    }),

    getOneNpc: build.query({
      query: (id) => ({
        url: `/api/npc/${id}`,
      }),
    }),

    updateNpc: build.mutation({
      query: (data) => {
        const { id, ...body } = data;
        return {
          method: 'PUT',
          url: `/api/npc/${id}`,
          body,
        };
      },
      invalidatesTags: ['NPCs'],
    }),

    deleteNpc: build.mutation({
      query: (id) => ({
        method: 'DELETE',
        url: `/api/npc/${id}`,
      }),
      invalidatesTags: ['NPCs'],
    }),
  }),
});

export const {
  useCreateNpcMutation,
  useDeleteNpcMutation,
  useGetNpcsQuery,
  useGetOneNpcQuery,
  useUpdateNpcMutation,
} = npcApi;
