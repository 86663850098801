import React, { useEffect, useState } from 'react';

import { useSelector, useDispatch } from 'react-redux';
// import { useSessionContext } from 'supertokens-auth-react/recipe/session';
import { Modal } from 'flowbite-react';
import { useSessionContext } from 'supertokens-auth-react/recipe/session';
import {
  displayAlert,
  setAlertInfo,
} from '../../redux/state-slices/alertSlice';
import { useUpdateFactionMutation } from '../../redux/api-slices/factionAPI';
import { useUpdateItemMutation } from '../../redux/api-slices/itemAPI';
import { useUpdateLocationMutation } from '../../redux/api-slices/locationAPI';
import { useUpdateNpcMutation } from '../../redux/api-slices/npcAPI';
import { useUpdateQuestMutation } from '../../redux/api-slices/questAPI';
import { useUpdateSceneMutation } from '../../redux/api-slices/sceneAPI';

import { spacesDelete, spacesUpload } from '../../digitalocean/spaces';

import { selectCurrentUser } from '../../redux/auth/authSlice';

import FactionForm from '../asset-creator/FactionForm';
import ItemForm from '../asset-creator/ItemForm';
import LocationForm from '../asset-creator/LocationForm';
import NPCForm from '../asset-creator/NPCForm';
import QuestForm from '../asset-creator/QuestForm';
import SceneForm from '../asset-creator/SceneForm';

import './AssetEditor.css';
import SubmitLoader from '../loading-ani/SubmitLoader';

const AssetEditorModal = ({
  openModal,
  setOpenModal,
  oldFormData,
  assetType,
}) => {
  const [formData, setFormData] = useState();
  const [isUpdating, setIsUpdating] = useState(false);

  const sesh = useSessionContext();
  const gameID = sesh.accessTokenPayload.In_Game.Game;

  const dispatch = useDispatch();
  const user = useSelector(selectCurrentUser);
  const [factionUpdate] = useUpdateFactionMutation();
  const [itemUpdate] = useUpdateItemMutation();
  const [locationUpdate] = useUpdateLocationMutation();
  const [npcUpdate] = useUpdateNpcMutation();
  const [questUpdate] = useUpdateQuestMutation();
  const [sceneUpdate] = useUpdateSceneMutation();

  useEffect(() => {
    if (!formData) {
      setFormData(oldFormData);
    }
  }, [oldFormData]);

  const handleSubmit = async (
    e,
    updateData,
    publicDetails,
    hiddenDetails,
    imgFile = null
  ) => {
    e.preventDefault();
    setIsUpdating(true);
    let res;
    // Swap state obj to block scoped variable instead.
    // React updates state async, which doesn't work well with async functions.
    let data = updateData;

    try {
      if (data.img_url === null) {
        if (oldFormData.img_url !== null) {
          await spacesDelete(oldFormData.img_url, user.username);
        }
      } else if (data.img_url && data.img_url !== oldFormData.img_url) {
        const imgResponse = await spacesUpload(
          imgFile,
          `GameAssets/${assetType}Images`,
          user.username
        );
        data.img_url = imgResponse;
        if (imgResponse && oldFormData.img_url) {
          await spacesDelete(oldFormData.img_url, user.username);
        }
      }
    } catch (err) {
      dispatch(
        setAlertInfo({
          alertType: 'failure',
          message: 'There was a problem uploading your image',
        })
      );
      dispatch(displayAlert());
    }

    const detailType = `${
      assetType === 'Faction' ? 'public_details' : 'details'
    }`;

    data = {
      ...data,
      game_id: gameID,
      [detailType]: publicDetails,
      hidden_details: hiddenDetails,
    };

    switch (assetType) {
      case 'Faction':
        if (data.location_id === 'false') {
          data.location_id = null;
        }
        res = await factionUpdate(data);
        setOpenModal(undefined);
        if (res.data) {
          dispatch(
            setAlertInfo({
              alertType: 'success',
              message: `${data.name} successfully updated`,
            })
          );
          setIsUpdating(false);
          dispatch(displayAlert());
        }
        break;
      case 'Item':
        if (data.location_id === 'false') {
          data.location_id = null;
        }
        setOpenModal(undefined);
        res = await itemUpdate(data);
        if (res.data) {
          dispatch(
            setAlertInfo({
              alertType: 'success',
              message: `${data.name} successfully updated`,
            })
          );
          setIsUpdating(false);
          dispatch(displayAlert());
        }
        break;
      case 'Location':
        setOpenModal(undefined);
        res = await locationUpdate(data);
        if (res.data) {
          dispatch(
            setAlertInfo({
              alertType: 'success',
              message: `${data.name} successfully updated`,
            })
          );
          setIsUpdating(false);
          dispatch(displayAlert());
        }
        break;
      case 'NPC':
        if (data.location_id === 'false') {
          data.location_id = null;
        }
        if (data.faction_id === 'false') {
          data.faction_id = null;
        }
        setOpenModal(undefined);
        res = await npcUpdate(data);
        if (res.data) {
          dispatch(
            setAlertInfo({
              alertType: 'success',
              message: `${data.name} successfully updated`,
            })
          );
          setIsUpdating(false);
          dispatch(displayAlert());
        }
        break;
      case 'Quest':
        if (data.location_id === 'false') {
          data.location_id = null;
        }
        if (data.session_id === 'false') {
          data.session_id = null;
        }
        setOpenModal(undefined);
        res = await questUpdate(data);
        if (res.data) {
          dispatch(
            setAlertInfo({
              alertType: 'success',
              message: `${data.title} successfully updated`,
            })
          );
          setIsUpdating(false);
          dispatch(displayAlert());
        }
        break;
      case 'Scene':
        if (data.location_id === 'false') {
          data.location_id = null;
        }
        setOpenModal(undefined);
        res = await sceneUpdate(data);
        if (res.data) {
          dispatch(
            setAlertInfo({
              alertType: 'success',
              message: `${data.title} successfully updated`,
            })
          );
          setIsUpdating(false);
          dispatch(displayAlert());
        }
        break;
      default:
        break;
    }
  };

  const modalTheme = {
    root: {
      show: {
        on: 'flex bg-white bg-opacity-40 flowbite-modal-root',
      },
    },

    content: {
      inner:
        'flowbite-modal-header-parent border-[#bdac91] border rounded-lg bg-[#fcf5e5]',
    },

    header: {
      base: 'bg-red-800',
      title: 'text-xl font-medium',
    },
  };

  return (
    <>
      {isUpdating && <SubmitLoader />}
      <Modal
        show={openModal === 'default'}
        size='5xl'
        theme={modalTheme}
        onClose={() => {
          setOpenModal(undefined);
        }}
        id='modal-body'
      >
        <Modal.Header>
          Edit {assetType === 'Faction' ? 'Group' : assetType}
        </Modal.Header>
        {assetType && (
          <Modal.Body>
            {assetType && openModal === 'default' && (
              <form
                className='border-image-container p-4'
                onSubmit={(e) => e.preventDefault()}
                key='updateForm'
              >
                {assetType === 'Faction' && (
                  <FactionForm
                    formData={oldFormData}
                    gameID={gameID}
                    isUpdate
                    handleUpdateSubmit={handleSubmit}
                  />
                )}
                {assetType === 'Item' && (
                  <ItemForm
                    formData={oldFormData}
                    gameID={gameID}
                    isUpdate
                    handleUpdateSubmit={handleSubmit}
                  />
                )}
                {assetType === 'Location' && (
                  // TODO: For each form follow this pattern (add isupdate and handleupdatesubmit)
                  <LocationForm
                    formData={oldFormData}
                    gameID={gameID}
                    isUpdate
                    handleUpdateSubmit={handleSubmit}
                    setFormData={setFormData}
                  />
                )}
                {assetType === 'NPC' && (
                  <NPCForm
                    formData={oldFormData}
                    gameID={gameID}
                    isUpdate
                    handleUpdateSubmit={handleSubmit}
                  />
                )}
                {assetType === 'Quest' && (
                  <QuestForm
                    formData={oldFormData}
                    gameID={gameID}
                    isUpdate
                    handleUpdateSubmit={handleSubmit}
                  />
                )}
                {assetType === 'Scene' && (
                  <SceneForm
                    formData={oldFormData}
                    gameID={gameID}
                    isUpdate
                    handleUpdateSubmit={handleSubmit}
                  />
                )}
              </form>
            )}
          </Modal.Body>
        )}
      </Modal>
    </>
  );
};

export default AssetEditorModal;
