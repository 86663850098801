import React from 'react';
import { FiCheckCircle, FiAlertCircle, FiAlertTriangle } from 'react-icons/fi';
import { useDispatch } from 'react-redux';
import { closeAlert } from '../../redux/state-slices/alertSlice';

const flashAlert = (time) => {
  const dispatch = useDispatch();

  setTimeout(() => {
    dispatch(closeAlert());
  }, time);
};

export const SuccessAlert = ({ message, dispatch }) => {
  flashAlert(3500);

  return (
    <div
      className='animate__fadeIn animate__animated fixed left-1/2 top-0 z-10 mx-auto flex -translate-x-1/2 translate-y-1/2 justify-between rounded-md border-2 border-teal-500 bg-teal-100 px-4 py-3 text-teal-900 opacity-100 shadow-md'
      role='alert'
    >
      <FiCheckCircle className='mr-4 h-6 w-6 text-teal-500' />
      <p className='mr-2 font-bold'>{message}</p>
      <button
        type='button'
        className='ml-auto box-content rounded-none border-none opacity-50 hover:no-underline hover:opacity-75 focus:opacity-100 focus:shadow-none focus:outline-none'
        aria-label='Close'
        onClick={() => dispatch(closeAlert())}
      >
        <span className='focus:opacity-100 disabled:pointer-events-none disabled:select-none disabled:opacity-25 [&.disabled]:pointer-events-none [&.disabled]:select-none [&.disabled]:opacity-25'>
          <svg
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 24 24'
            fill='currentColor'
            className='h-4 w-4'
          >
            <path
              fillRule='evenodd'
              d='M5.47 5.47a.75.75 0 011.06 0L12 10.94l5.47-5.47a.75.75 0 111.06 1.06L13.06 12l5.47 5.47a.75.75 0 11-1.06 1.06L12 13.06l-5.47 5.47a.75.75 0 01-1.06-1.06L10.94 12 5.47 6.53a.75.75 0 010-1.06z'
              clipRule='evenodd'
            />
          </svg>
        </span>
      </button>
    </div>
  );
};

export const FailureAlert = ({ message, dispatch }) => {
  flashAlert(6000);

  return (
    <div
      className='animate__fadeIn animate__animated fixed left-1/2 top-0 z-10 mx-auto flex -translate-x-1/2 translate-y-1/2 justify-between rounded-md border-2 border-red-500 bg-red-100 px-4 py-3 text-red-900 opacity-100 shadow-md'
      role='alert'
    >
      <FiAlertTriangle className='mr-4 h-6 w-6 text-red-500' />
      <p className='mr-2 font-bold'>{message}</p>
      <button
        type='button'
        className='ml-auto box-content rounded-none border-none opacity-50 hover:no-underline hover:opacity-75 focus:opacity-100 focus:shadow-none focus:outline-none'
        aria-label='Close'
        onClick={() => dispatch(closeAlert())}
      >
        <span className='focus:opacity-100 disabled:pointer-events-none disabled:select-none disabled:opacity-25 [&.disabled]:pointer-events-none [&.disabled]:select-none [&.disabled]:opacity-25'>
          <svg
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 24 24'
            fill='currentColor'
            className='h-4 w-4'
          >
            <path
              fillRule='evenodd'
              d='M5.47 5.47a.75.75 0 011.06 0L12 10.94l5.47-5.47a.75.75 0 111.06 1.06L13.06 12l5.47 5.47a.75.75 0 11-1.06 1.06L12 13.06l-5.47 5.47a.75.75 0 01-1.06-1.06L10.94 12 5.47 6.53a.75.75 0 010-1.06z'
              clipRule='evenodd'
            />
          </svg>
        </span>
      </button>
    </div>
  );
};

export const WarningAlert = ({ message, dispatch }) => {
  flashAlert(5000);

  return (
    <div
      className='animate__fadeIn animate__animated fixed left-1/2 top-0 z-10 mx-auto flex -translate-x-1/2 translate-y-1/2 justify-between rounded-md border-2 border-orange-500 bg-orange-100 px-4 py-3 text-orange-900 opacity-100 shadow-md'
      role='alert'
    >
      <FiAlertCircle className='mr-4 h-6 w-6 text-orange-500' />
      <p className='mr-2 font-bold'>{message}</p>
      <button
        type='button'
        className='ml-auto box-content rounded-none border-none opacity-50 hover:no-underline hover:opacity-75 focus:opacity-100 focus:shadow-none focus:outline-none'
        aria-label='Close'
        onClick={() => dispatch(closeAlert())}
      >
        <span className='focus:opacity-100 disabled:pointer-events-none disabled:select-none disabled:opacity-25 [&.disabled]:pointer-events-none [&.disabled]:select-none [&.disabled]:opacity-25'>
          <svg
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 24 24'
            fill='currentColor'
            className='h-4 w-4'
          >
            <path
              fillRule='evenodd'
              d='M5.47 5.47a.75.75 0 011.06 0L12 10.94l5.47-5.47a.75.75 0 111.06 1.06L13.06 12l5.47 5.47a.75.75 0 11-1.06 1.06L12 13.06l-5.47 5.47a.75.75 0 01-1.06-1.06L10.94 12 5.47 6.53a.75.75 0 010-1.06z'
              clipRule='evenodd'
            />
          </svg>
        </span>
      </button>
    </div>
  );
};

export const ShowAlert = ({ message, alertType }) => {
  const dispatch = useDispatch();

  const alertMap = {
    success: <SuccessAlert dispatch={dispatch} message={message} />,
    failure: <FailureAlert dispatch={dispatch} message={message} />,
    storage: <WarningAlert dispatch={dispatch} message={message} />,
    warning: <WarningAlert dispatch={dispatch} message={message} />,
  };

  return alertMap[alertType];
};

export const DeleteFail = {
  alertType: 'failure',
  message:
    'An error occured while deleting. If this issue persists, please submit a support ticket and we will look into this ASAP!',
};

export const UpdateFail = {
  alertType: 'failure',
  message:
    'An error occured during this update. If this issue persists, please submit a support ticket and we will look into it ASAP!',
};

export const CreateFail = {
  alertType: 'failure',
  message:
    'An error occured while creating this resource. If this issue persists, please submit a support ticket and we will look into it ASAP!',
};

export const GenerationFail = {
  alertType: 'failure',
  message:
    'An error occured while generating. Try to be as concise as you can. If this issue persists, please submit a support ticket and we will look into it ASAP!',
};
