import dgnApi from './dgnAPI';

const locationApi = dgnApi.injectEndpoints({
  endpoints: (build) => ({
    getLocations: build.query({
      query: (gid) => ({
        url: `/api/locations/${gid}`,
      }),
      providesTags: ['Locations'],
    }),

    createLocation: build.mutation({
      query: (data) => ({
        method: 'POST',
        url: '/api/location',
        body: data,
      }),
      invalidatesTags: ['Locations'],
    }),

    getOneLocation: build.query({
      query: (id) => ({
        url: `/api/location/${id}`,
      }),
    }),

    updateLocation: build.mutation({
      query: (data) => {
        const { id, ...body } = data;
        return {
          method: 'PUT',
          url: `/api/location/${id}`,
          body,
        };
      },
      invalidatesTags: ['Locations'],
    }),

    deleteLocation: build.mutation({
      query: (id) => ({
        method: 'DELETE',
        url: `/api/location/${id}`,
      }),
      invalidatesTags: ['Locations'],
    }),
  }),
});

export const {
  useGetLocationsQuery,
  useCreateLocationMutation,
  useDeleteLocationMutation,
  useGetOneLocationQuery,
  useUpdateLocationMutation,
} = locationApi;
