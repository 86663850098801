import React, { useEffect } from 'react';

import MDEditor, { commands } from '@uiw/react-md-editor';
import rehypeSanitize from 'rehype-sanitize';
import { FiMinus, FiPlus } from 'react-icons/fi';

import { FaTable } from 'react-icons/fa6';
import '../selected-content/selected.css';

export const ObjDetailImg = ({ currentObj }) => (
  <img
    src={currentObj.img_url}
    alt='...'
    className='h-full w-full object-contain object-center'
  />
);

export const RelatedInfo = () => (
  <div className='info-top-row-sm mx-2'>Related Info</div>
);

export const FactionHeader = ({ isActive, setIsActive }) => {
  const icon = isActive ? (
    <FiMinus className='ml-4 inline' />
  ) : (
    <FiPlus className='ml-4 inline' />
  );

  return (
    <>
      <div className='mx-4 mt-4 text-xl'>
        <span
          role='presentation'
          onClick={() => setIsActive(!isActive)}
          onKeyDown={() => setIsActive(!isActive)}
          className='related-details-title px-3 py-1 hover:cursor-pointer'
        >
          Group details{icon}
        </span>
      </div>
      <hr className='details-seperating-bar mx-4 mb-2 h-0' />
    </>
  );
};

export const LocationHeader = ({ isActive, setIsActive }) => {
  const icon = isActive ? (
    <FiMinus className='ml-4 inline' />
  ) : (
    <FiPlus className='ml-4 inline' />
  );

  return (
    <>
      <div className='mx-4 mt-4 text-xl'>
        <span
          role='presentation'
          onClick={() => setIsActive(!isActive)}
          onKeyDown={() => setIsActive(!isActive)}
          className='related-details-title px-3 py-1 hover:cursor-pointer'
        >
          Location details{icon}
        </span>
      </div>
      <hr className='details-seperating-bar mx-4 mb-2 h-0' />
    </>
  );
};

export const GMDetailHeader = ({ isActive, setIsActive }) => {
  const icon = isActive ? (
    <FiMinus className='mx-6 inline' />
  ) : (
    <FiPlus className='mx-6 inline' />
  );

  return (
    <div
      role='presentation'
      onClick={() => setIsActive(!isActive)}
      onKeyDown={() => setIsActive(!isActive)}
      className='info-top-row-sm mx-2 hover:cursor-pointer'
    >
      {icon}GM-only notes{icon}
    </div>
  );
};

export const GMFactionHeader = ({ isActive, setIsActive }) => {
  const icon = isActive ? (
    <FiMinus className='ml-4 inline' />
  ) : (
    <FiPlus className='ml-4 inline' />
  );

  return (
    <>
      <div
        role='presentation'
        onClick={() => setIsActive(!isActive)}
        onKeyDown={() => setIsActive(!isActive)}
        className='mx-4 mt-4 text-xl'
      >
        <span className='related-details-title px-3 py-1 hover:cursor-pointer'>
          GM-only group notes{icon}
        </span>
      </div>
      <hr className='details-seperating-bar mx-4 mb-2 h-0' />
    </>
  );
};

export const GMLocationHeader = ({ isActive, setIsActive }) => {
  const icon = isActive ? (
    <FiMinus className='ml-4 inline' />
  ) : (
    <FiPlus className='ml-4 inline' />
  );

  return (
    <>
      <div className='mx-4 mt-4 text-xl'>
        <span
          role='presentation'
          onClick={() => setIsActive(!isActive)}
          onKeyDown={() => setIsActive(!isActive)}
          className='related-details-title px-3 py-1 hover:cursor-pointer'
        >
          GM-only location notes{icon}
        </span>
      </div>
      <hr className='details-seperating-bar mx-4 mb-2 h-0' />
    </>
  );
};

export const DetailMD = ({ details }) => {
  return (
    <div data-color-mode='light' className='markdown-cont'>
      <MDEditor.Markdown source={details} height='300px' />
    </div>
  );
};

export const EditorMD = ({ mdText, setMdText, height }) => {
  useEffect(
    () => () => {
      setMdText('');
    },
    []
  );
  const tableCommand = {
    name: 'insertTable',
    keyCommand: 'insertTable',
    buttonProps: {
      'aria-label': 'Insert insertTable command',
      title: 'Insert Table (ctrl + shift + f)',
    },
    icon: <FaTable />,
    shortcuts: 'ctrl+shift+f',
    execute: (state, api) => {
      const tableTemplate =
        '\n\n | Column 1 | Column 2 | Column 3 |\n | -------- | -------- | -------- |\n | Text | Text | Text |\n\n';
      api.replaceSelection(tableTemplate);
    },
  };

  const help = {
    name: 'help',
    groupName: 'help',
    keyCommand: 'help',
    buttonProps: { 'aria-label': 'Insert help', title: 'View Markdown help' },
    icon: (
      <svg viewBox='0 0 16 16' width='12px' height='12px'>
        <path
          d='M8 0C3.6 0 0 3.6 0 8s3.6 8 8 8 8-3.6 8-8-3.6-8-8-8Zm.9 13H7v-1.8h1.9V13Zm-.1-3.6v.5H7.1v-.6c.2-2.1 2-1.9 1.9-3.2.1-.7-.3-1.1-1-1.1-.8 0-1.2.7-1.2 1.6H5c0-1.7 1.2-3 2.9-3 2.3 0 3 1.4 3 2.3.1 2.3-1.9 2-2.1 3.5Z'
          fill='currentColor'
        />
      </svg>
    ),
    execute: (state, api) => {
      window.open('https://www.markdownguide.org/basic-syntax/', '_blank');
    },
  };

  return (
    <div data-color-mode='light'>
      <MDEditor
        draggable={false}
        className='m-auto'
        height={height}
        style={{ backgroundColor: 'transparent' }}
        onChange={(value) => setMdText(value)}
        value={mdText}
        previewOptions={{
          rehypePlugins: [[rehypeSanitize]],
        }}
        commands={[
          tableCommand,
          ...commands.getCommands(),
          commands.divider,
          help,
        ]}
        commandsFilter={(cmd) =>
          cmd && /(code|codeBlock|comment)/.test(cmd.name) ? false : cmd
        }
        // Known issue with Tailwind as of react-md-editor version 3.23.5.
        // Higlighting text and cursor position is off if highlightEnable is
        // true. No functionality is lost with this as false
        highlightEnable={false}
      />
    </div>
  );
};
