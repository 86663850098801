import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  allParties: [],
  selectedParty: null,
};

const partySlice = createSlice({
  name: 'party',
  initialState,
  reducers: {
    setParties: (state, action) => {
      state.allParties = action.payload;
    },

    setOneParty: (state, action) => {
      state.selectedParty = action.payload;
    },
  },
});

export const { setParties, setOneParty } = partySlice.actions;

export default partySlice.reducer;

export const selectAllParties = (state) => state.parties.allParties;
export const selectOneParty = (state) => state.parties.selectedParty;
