import React from 'react';

import { Carousel } from 'flowbite-react';
import './content.css';

export default function HeroCarousel() {
  // const carouselItems = {
  //   first: '../../../app/assets/lillith.png',
  // };

  const carouselTheme = {
    indicators: {
      active: {
        off: 'bg-[#A8524D] hover:bg-blue',
        on: 'bg-[#420000]',
      },
    },

    root: {
      base: 'relative h-full w-full',
      leftControl:
        'absolute top-0 left-0 flex h-full items-center justify-center px-4 focus:outline-none',
      rightControl:
        'absolute top-0 right-0 flex h-full items-center justify-center px-4 focus:outline-none',
    },

    control: {
      base: 'inline-flex h-8 w-8 items-center justify-center rounded-full group-hover:bg-[rgba(168, 82, 77, 0.5)] group-focus:outline-none group-focus:ring-4 group-focus:ring-white  sm:h-10 sm:w-10',
      icon: 'h-5 w-5 sm:h-6 sm:w-6',
    },

    scrollContainer: {
      base: 'flex h-full snap-mandatory overflow-y-hidden overflow-x-scroll scroll-smooth rounded-xl sm:rounded-2xl',
      snap: 'snap-x',
    },
  };

  return (
    <div className='carousel-wrapper max-h-[65%] w-full'>
      <Carousel theme={carouselTheme} className='test-c z-1'>
        <img
          alt='...'
          src='https://flowbite.com/docs/images/carousel/carousel-1.svg'
        />
        <img
          alt='...'
          src='https://flowbite.com/docs/images/carousel/carousel-2.svg'
        />
        <img
          alt='...'
          src='https://flowbite.com/docs/images/carousel/carousel-3.svg'
        />
        <img
          alt='...'
          src='https://flowbite.com/docs/images/carousel/carousel-4.svg'
        />
        <img
          alt='...'
          src='https://flowbite.com/docs/images/carousel/carousel-5.svg'
        />
      </Carousel>
    </div>
  );
}
