import React, { useEffect, useState } from 'react';
import { FiEye, FiEyeOff } from 'react-icons/fi';
import { Tooltip } from 'flowbite-react';
import { EditorMD } from '../archive/misc-components/MiscDetailComps';
import FloatingLabelInput from '../archive/misc-components/FloatingLabelInput';
import DgnFileInput from '../archive/misc-components/DgnFileInput';
import SizeChecker from '../archive/misc-components/SizeChecker';

const LocationForm = ({
  handleInputChange = null,
  formData,
  handleUpdateSubmit,
  isUpdate,
  imgSize,
}) => {
  // Start section used when form is updating asset rather than creating from scratch  vvvvv

  // Set initial values for checkboxes to prevent "uncontrolled input to controlled" error
  // Initial value not important, is immediately set to correct value when formData is received
  const [localUpdateState, setLocalUpdateState] = useState({
    is_known: false,
  });
  const [publicDetails, setPublicDetails] = useState();
  const [hiddenDetails, setHiddenDetails] = useState();
  const [imgFile, setImgFile] = useState();
  const [localImgSize, setLocalImgSize] = useState(null);
  const [willEditImg, setWillEditImg] = useState(!isUpdate);
  const [imgFileInputKey, setImgFileInputKey] = useState(new Date());

  useEffect(() => {
    if (isUpdate) {
      setLocalUpdateState({ ...formData });
      setPublicDetails(formData.details);
      setHiddenDetails(formData.hidden_details);
    }
  }, [formData]);

  useEffect(() => {
    return () => {
      setLocalUpdateState({});
    };
  }, []);
  const handleLocalInputChange = async (e) => {
    if (e.target.type === 'file') {
      if (e.target.files.length === 0) {
        setLocalImgSize(null);
        setLocalUpdateState({
          ...localUpdateState,
          img_url: '',
        });
      } else {
        setLocalUpdateState({
          ...localUpdateState,
          img_url: URL.createObjectURL(e.target.files[0]),
        });
        setImgFile(e.target.files[0]);
        const mb = e.target.files[0].size / 1024 / 1024;
        setLocalImgSize(mb.toFixed(2));
      }
    } else if (e.target.nodeName === 'SELECT') {
      setLocalUpdateState({
        ...localUpdateState,
        [e.target.name]: e.target.value === 'false' ? null : e.target.value,
      });
    } else {
      setLocalUpdateState({
        ...localUpdateState,
        [e.target.name]:
          e.target.type === 'checkbox' ? e.target.checked : e.target.value,
      });
    }
  };

  // Finish section  ^^^^^

  return (
    <>
      <div className='m-auto flex w-[35rem] flex-col items-center'>
        <FloatingLabelInput
          onChange={isUpdate ? handleLocalInputChange : handleInputChange}
          value={isUpdate ? localUpdateState?.name || '' : formData.name || ''}
          maxLength={60}
          isRequired
          labelText='Name'
          nameAndID='name'
          additionalInputClassNames='asset-title'
        />
        <div id='toggles' className='my-4 flex'>
          <label htmlFor='is_known' className='flex'>
            {(
              isUpdate ? localUpdateState?.is_known : formData.is_known || false
            ) ? (
              <span className='flex items-center text-green-600'>
                <FiEye className='mr-2 inline text-green-600 opacity-100' />
                Known by Party
              </span>
            ) : (
              <span className='flex items-center text-violet-600'>
                <FiEyeOff className='mr-2 inline text-violet-600 opacity-100' />
                Unknown by Party
              </span>
            )}
            <input
              className='ml-3'
              onChange={isUpdate ? handleLocalInputChange : handleInputChange}
              checked={
                isUpdate
                  ? localUpdateState?.is_known
                  : formData.is_known || false
              }
              value={
                isUpdate
                  ? localUpdateState?.is_known
                  : formData.is_known || false
              }
              type='checkbox'
              name='is_known'
              id='is_known'
            />
          </label>
        </div>
        <div className='flex flex-col items-center'>
          <div className='flex'>
            {willEditImg ? (
              <DgnFileInput
                key={imgFileInputKey}
                imageChangeHandler={
                  isUpdate ? handleLocalInputChange : handleInputChange
                }
              />
            ) : (
              <button
                onClick={() => setWillEditImg(true)}
                type='button'
                className='row-button edit-content rounded-lg bg-gray-200 text-xs'
              >
                Update Image
              </button>
            )}
            {localUpdateState.img_url && (
              <button
                type='button'
                className='row-button delete-content rounded-lg bg-gray-200 text-xs'
                onClick={() => {
                  setImgFileInputKey(new Date());
                  setImgFile(null);
                  setLocalImgSize(null);
                  setLocalUpdateState({
                    ...localUpdateState,
                    img_url: null,
                  });
                }}
              >
                Clear Image
              </button>
            )}
          </div>
          {(isUpdate ? localUpdateState?.img_url : formData.img_url) && (
            <>
              {willEditImg && (imgSize || localImgSize) && (
                <SizeChecker imgSize={imgSize || localImgSize} />
              )}
              <img
                className='pt-4'
                src={isUpdate ? localUpdateState?.img_url : formData.img_url}
                alt='group placeholder'
              />
            </>
          )}
        </div>
      </div>
      {isUpdate && (
        <div className='px-4'>
          Details:
          <EditorMD mdText={publicDetails} setMdText={setPublicDetails} />
          Hidden Details:
          <EditorMD mdText={hiddenDetails} setMdText={setHiddenDetails} />
          <div className=' mt-2 flex justify-center'>
            {localImgSize === null || localImgSize <= 2 ? (
              <button
                type='button'
                className='submit-button mr-2 rounded-lg bg-green-700 px-4 py-2 text-white'
                onClick={(e) => {
                  handleUpdateSubmit(
                    e,
                    localUpdateState,
                    publicDetails,
                    hiddenDetails,
                    imgFile
                  );
                }}
              >
                Update
              </button>
            ) : (
              <Tooltip
                // eslint-disable-next-line react/style-prop-object
                style='light'
                content='Your image is too large, consider compressing it'
              >
                <button
                  type='submit'
                  disabled
                  className='submit-button mr-2 rounded-lg bg-green-700 px-4 py-2 text-white'
                >
                  Update
                </button>
              </Tooltip>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default LocationForm;
