import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

// Components
import { FiEyeOff, FiEye } from 'react-icons/fi';

import LocationDetails from './content-details/LocationDetails';
import CheckboxCompare from '../misc-components/CheckboxCompare';
import { spacesDelete } from '../../../digitalocean/spaces';

// Redux
import {
  selectAllLocations,
  setOneLocation,
  setLocations,
} from '../../../redux/state-slices/locationSlice';
import { useDeleteLocationMutation } from '../../../redux/api-slices/locationAPI';

// styles
import './selected.css';
import ViewButton, {
  CreateAndRefetch,
  FlowModal,
  GMButtons,
  NoAssets,
} from '../misc-components/MiscListComps';
import AssetEditorModal from '../../asset-editor-modal/AssetEditorModal';
import SubmitLoader from '../../loading-ani/SubmitLoader';
import {
  displayAlert,
  setAlertInfo,
} from '../../../redux/state-slices/alertSlice';
import { DeleteFail } from '../../alerts/ResponseAlerts';

export default function LocationContent({ role, username, refetcher }) {
  const locations = useSelector(selectAllLocations);

  const [locationDetails, setLocationDetails] = useState(null);

  return locationDetails ? (
    <LocationDetails setLocationDetails={setLocationDetails} role={role} />
  ) : (
    <div className='list-content'>
      <CreateAndRefetch role={role} refetcher={refetcher} asset='Location' />

      {locations?.length === 0 ? (
        <NoAssets asset='locations' />
      ) : (
        <div className='content-body mt-3 flex columns-3 flex-col overflow-y-auto overflow-x-hidden p-4'>
          <LocationList
            locations={locations}
            role={role}
            setLocationDetails={setLocationDetails}
            username={username}
          />
        </div>
      )}
    </div>
  );
}

const LocationList = ({ locations, role, setLocationDetails, username }) => {
  const dispatch = useDispatch();
  const [openModal, setOpenModal] = useState(undefined);
  const [item, setItem] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [locationDelete] = useDeleteLocationMutation();

  const selectOne = (loc) => {
    dispatch(setOneLocation(loc));
    setLocationDetails(true);
  };

  const failedDeleteAlert = () => {
    dispatch(setAlertInfo(DeleteFail));
    dispatch(displayAlert());
  };

  const deleteLocation = async () => {
    setOpenModal(undefined);
    setIsSubmitting(true);
    const isLastDeleted = locations.length === 1 ? true : null;
    const img = item?.img_url;

    try {
      if (img) {
        await spacesDelete(img, username);
      }
      const locDelRes = await locationDelete(item.id);
      if ('data' in locDelRes && locDelRes.data) {
        dispatch(
          setAlertInfo({
            alertType: 'success',
            message: `${item.name} deleted`,
          })
        );
        dispatch(displayAlert());
      } else if (
        ('data' in locDelRes && locDelRes.data === false) ||
        'detail' in locDelRes ||
        'error' in locDelRes
      ) {
        failedDeleteAlert();
      }
    } catch (e) {
      failedDeleteAlert();
    }
    setItem(null);

    if (isLastDeleted) {
      dispatch(setLocations([]));
    }
    setIsSubmitting(false);
  };

  return (
    <>
      {isSubmitting && <SubmitLoader message='Deleting' />}
      {locations?.map((loc) => (
        <div
          className='content-item flex flex-row justify-between'
          key={loc.id}
        >
          <span className='m-2 py-2'>
            <CheckboxCompare
              obj={loc}
              category='location'
              elId={`checkbox-location-${loc.id}`}
            />
            {loc.name}
          </span>
          <div className='list-button-container flex'>
            <span className='m-2 px-4 py-2'>
              {role && role === 'GM' ? (
                loc.is_known ? (
                  <FiEye className='mr-1 inline text-green-600 opacity-100' />
                ) : (
                  <FiEyeOff className='mr-1 inline text-violet-600 opacity-100' />
                )
              ) : null}
            </span>
            <ViewButton obj={loc} selectOne={selectOne} />

            {role && role === 'GM' ? (
              <>
                <GMButtons
                  obj={loc}
                  setSelected={setItem}
                  setOpenModal={setOpenModal}
                  setOne={setOneLocation}
                />

                <FlowModal
                  openModal={openModal}
                  setOpenModal={setOpenModal}
                  obj={item}
                  deleteFunc={deleteLocation}
                />
              </>
            ) : null}
          </div>
        </div>
      ))}
      {item && (
        <AssetEditorModal
          openModal={openModal}
          setOpenModal={setOpenModal}
          oldFormData={item}
          assetType='Location'
        />
      )}
    </>
  );
};
