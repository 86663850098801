import dgnApi from './dgnAPI';

const sessionApi = dgnApi.injectEndpoints({
  endpoints: (build) => ({
    getSessions: build.query({
      query: (gid) => ({
        url: `/api/sessions/${gid}`,
      }),
      providesTags: ['Sessions'],
    }),

    createSession: build.mutation({
      query: (data) => {
        const { gid, ...body } = data;
        return {
          method: 'POST',
          url: `/api/session/${gid}`,
          body,
        };
      },
      invalidatesTags: ['Sessions'],
    }),

    getOneSession: build.query({
      query: (id) => ({
        url: `/api/session/${id}`,
      }),
    }),

    updateSession: build.mutation({
      query: (data) => {
        const { id, ...body } = data;
        return {
          method: 'PUT',
          url: `/api/session/${id}`,
          body,
        };
      },
      invalidatesTags: ['Sessions'],
    }),

    deleteSession: build.mutation({
      query: (id) => ({
        method: 'DELETE',
        url: `/api/session/${id}`,
      }),
      invalidatesTags: ['Sessions'],
    }),
  }),
});

export const {
  useCreateSessionMutation,
  useDeleteSessionMutation,
  useGetOneSessionQuery,
  useGetSessionsQuery,
  useUpdateSessionMutation,
} = sessionApi;
